import React, { Fragment } from 'react'

const Apropos = () => {
  return (
    <Fragment>
        <div className="content">
        <div className="container-fluid">
        <div className="row">
        <div className=" account-content">
        <div className="col-lg-10" style={{'textAlign': 'justify', 'lineHeight':1.8, 'margin':'auto'}}>
        <h1>A PROPOS</h1>

                Syidocs est né de la volonté de pouvoir offrir aux écoles, aux structures (publiques et privés) et même 
                aux particuliers produisant du contenue documentaire, un outil pour améliorer leur présence sur internet, et de leur 
                proposer un moyen de développer une version numérique de leurs publications (Livre, résultats de recherche, rapports études...).
                <br/><br/>
                Notre objectif est de permettre à ces structures et aussi à certains particuliers de disposer de leur propre bibliothèque numérique pour
                leurs bésoins spécifiques.
                <br/><br/>
                <h2>Nos activités</h2>
                L’ambition de Syidocs, est d’aider les maisons d’édition, organismes ou associations 
                ayant des publications à gérer la coexistence des formats papier et numérique. 
                
                Le déploiement de la plateforme se fait à la demande. La plateforme peut être hébergée directement sur
                vos serveurs au sain même de votre structure en mode SELF SERVICE.
                <br/><br/>
                Nous offrons aussi une option AUTO-Hébergée, c'est à dire que nous pouvons héberger une instance
                de syidocs sur nos propres serveurs sécurisés, afin de vous décharger des contraintes de gestion et de 
                maintenance des serveurs. <br/><br/><br/><br/>


        </div>
        </div>
        </div>
        </div>
        </div>
    </Fragment>
  )
}

export default Apropos