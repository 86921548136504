import React, { Fragment } from 'react'

const TabListeCats = (props) => {
    const {classifications, DeleteToggle, UpdateToggle} = props;
  return (
    <Fragment>
         <table className="table table-hover table-center mb-0">
            <thead>
                <tr className="table_tr_header">
                <th>N°</th>
                <th> Classification</th>
                <th> Description</th>
                <th></th>
                </tr>
            </thead>
            <tbody>
               
                {classifications.map((Cat, index) =>{
                return(
                <tr key={Cat.id} className="table_tr_content">
                    <td> {index+1} </td>
                    <td> {Cat.nom} </td>
                    <td> {Cat.description} </td>
                    <td className="text-right">
                    <div className="table-action">
                    <button className="btn btn-sm bg-info-light mr-0" onClick={()=> UpdateToggle(Cat.id, Cat.nom, Cat.description)}>
                    <i className="far fa-eye" /> Modifier
                    </button>
                    <button className="btn btn-sm bg-danger-light" onClick={()=>DeleteToggle(Cat.id)}>
                    <i className="fas fa-times" /> Supprimer
                    </button>
                    </div>
                    </td>
                </tr>
                )
            })}
            
            </tbody>
        </table>
        
    </Fragment>
  )
}

export default TabListeCats