import React from 'react';
import { Link } from 'react-router-dom';

const ListeBtn = () => {
    //const Navigate = useNavigate();
  return (
    <div className='listeTags'>
        
        <Link className='tagbtn' to="/search/entitys/1/null/null/document" style={{'padding':'8px 15px 8px 15px'}}>
                Résultats de recherches
        </Link>
        <Link className='tagbtn' to="/search/entitys/1/null/null/document" style={{'padding':'8px 15px 8px 15px'}}>
                Rapports
        </Link>
        <Link className='tagbtn' to="/search/entitys/1/null/null/document" style={{'padding':'8px 15px 8px 15px'}}>
                Revue de presse
        </Link>
        <Link className='tagbtn' to="/search/entitys/1/null/null/document" style={{'padding':'8px 15px 8px 15px'}}>
                Revue documentaire
        </Link>
        <Link className='tagbtn' to="/search/entitys/1/null/null/document" style={{'padding':'8px 15px 8px 15px'}}>
                Publications
        </Link>


    </div>
  )
}

export default ListeBtn;