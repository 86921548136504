import React, { useRef, useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo-blanc.png';
import './header.css';

const Header = () => {

  const mbMenu = useRef(null);
  const custmeStyle = {
        display:'none',  
        backgroundColor:'#ea0f4d',
       }
 
  const [linkStyle, setLinkStyle] = useState(custmeStyle);

  const LeftSidbar = ()=>{
    document.documentElement.setAttribute('class', 'menu-opened');
    mbMenu.current.classList.add("opened");
    setLinkStyle({
      ...linkStyle,
      display:'block'
    })
  }
  const menuClose = ()=>{
    document.documentElement.removeAttribute('class', 'menu-opened');
    mbMenu.current.classList.remove("opened");
    setLinkStyle({
      ...linkStyle,
      display:'none'
    })
  }


 
  return (
    <Fragment>
    <header className=" header">
      <div ref={mbMenu} onClick={menuClose} className="sidebar-overlay"></div>
        <nav className="navbar navbar-expand-lg header-nav">
          <div className="navbar-header">
            <button className='btn-sidebar-menu' id="mobile_btn" onClick={LeftSidbar}>
              <span className="bar-icon">
                <span />
                <span />
                <span />
              </span>
            </button>
            <a href ="/" className="navbar-brand logo">
              <img src={logo} className="img-fluid" alt="Logo" /> 
            </a>
          </div>
          <div className="main-menu-wrapper" style={{paddingRight:'20px'}}>
            <div className="menu-header">
              <a href ="/" className="menu-logo">
                <img src={logo} className="img-fluid" alt="Logo" />
              </a>
              <button className='btn-sidebar-menu menu-close' 
              id="menu_close" onClick={menuClose}>
                <i className="fas fa-times" />
              </button>
            </div>
            <ul className="main-nav bg-trans">
              <li className='class'>
                <a href ="/">ACCUEIL</a>
              </li>
              <li className='class'>
                <a href ="/">BIBLIOTHEQUE NUMERIQUE</a>
              </li>
              <li className='class'><Link to="/apropos">A PROPOS</Link></li>
                <li className='class'><Link to="/contacts">CONTACTS</Link></li>
              <li className="loginlink" style={linkStyle}>
                <a href="/login">COMPTE</a>
              </li>
            </ul>
          </div>
          <ul className="nav header-navbar-rht align-items-center">
            
            {/* Flag */}
            <li className="nav-item dropdown flag-nav">
              <a className="nav-link dropdown-toggle align-items-center text-center" data-toggle="dropdown" href="/" role="button">
                FR
              </a>
            
            </li>
            {/* /Flag */} 
  
            {/* Login */}
            <li className="nav-item contact-item">
              <i className="far fa-user mr-2" />
              <a href="/login">SE CONNECTER</a>
            </li> 
            <li className="nav-item contact-item">
              <a href="/signup">S'INSCRIRE</a>
            </li> 
            {/* /Login */}
            
          </ul>
        </nav>
      </header>
      
      </Fragment>
  )
}

export default Header