import React, { Fragment } from 'react'
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
// Import Images
import { SERVER_FILES } from '../../auth/Configs';

const HomeSideBar = (props) => {

    const state = useSelector(state => state.sessionAuth)
    const settings = useSelector(state => state.userSettings)
    
  return (
    <Fragment>
        
        <div className="profile-sidebar">
                  <div className="widget-profile pro-widget-content">
                    <div className="profile-info-widget">
                      <a href="/apps/home/user" className="booking-doc-img">

                      {settings.fileUrl == null ?(
                          <div className="avatar avatar-xxl">
                          <span className="avatar-title rounded-circle border border-white">
                          {settings.nom.charAt(0)+settings.prenoms.charAt(0)}

                          </span>
                          </div>
                        ):(<img src={SERVER_FILES+settings.fileUrl} alt='profile'/>)}
                        
                      </a>
                      <div className="profile-det-info">
                        <h3>{settings.prenoms+ ' '+settings.nom}</h3>
                        <div className="receiver-details">
                          <h5 className="mb-0">
                          { state.roles.includes('ROLE_ADMIN') ? 'Administrateur': 
                          state.roles.includes('ROLE_EDITED') ? 'Editeur' :
                          state.roles.includes('ROLE_ASSIST') ? 'Assistant' 
                          : 'Lecteur'}

                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dashboard-widget">
                    <nav className="dashboard-menu">
                      <ul>
                        <li>
                          <Link to="/apps/home/user">
                            <i className="fas fa-user-cog" />
                            <span>Profile</span>
                          </Link>
                        </li>
                        
                        <li>
                          <Link to={`/apps/reset/sessionId=${state.sessionId}/reset-pwd/${state.token}`}>
                            <i className="fas fa-lock" />
                            <span>Changer le mot de passe</span>
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>      
    
    </Fragment>
  )
}

export default HomeSideBar