import { Api } from "./Configs";

/*** 
    Export url 
***/

//ACCOOUNT
export const USERS_SESSION = ()=> `${Api}/user/datas`;
export const GET_USERS_ACCOUNT = (user)=> `${Api}/user-account/${user}`;
//PROFILE
export const USER_PROFILE_FETCH = (id, sessionId)=> `${Api}/user/${id}/${sessionId}`;
export const POST_PROFILE = (id, sessionId) => `${Api}/user/${id}/${sessionId}`;

//GESTION DE COMPTE USER
export const UPDATE_USER_ACCOUNT = (user, sessionId) => `${Api}/user/update/${user}/${sessionId}`;
export const DELETE_USER_ACCOUNT = (user)=> `${Api}/user/remove/${user.id}/${user.useruuid}`;
export const GET_ALL_USERS = (page, search)=> `${Api}/users?page=${page}&s=${search}`;

//DASHBOARD
export const COUNT_ENUM_DOCS = () => `${Api}/enum/entity`;
export const COUNT_COLLECTIONS = ()=> `${Api}/enum/collections`;
export const COUNT_USERS = ()=> `${Api}/enum/users`;

//COLLECTIONS
export const GET_PUBLIC_COLLECTIONS=()=> `${Api}/search/collections`;
export const GET_ALL_COLLECTIONS=()=> `${Api}/collections`;
export const COLLECTIONS_ADDS=()=> `${Api}/collections/adds`;
export const COLLECTIONS_DELETE=(id)=> `${Api}/collections/del/${id}`;
export const COLLECTIONS_UPDATE=(id)=> `${Api}/collections/update/${id}`;
//GENRES
export const GET_ALL_GENRES=()=> `${Api}/genres-litteraires`;
export const GENRES_ADDS=()=> `${Api}/genres/adds`;
export const GENRES_DELETE=(id)=> `${Api}/genres/del/${id}`;
export const GENRES_UPDATE=(id)=> `${Api}/genres/update/${id}`;
export const GET_PUBLIC_GENRE=()=> `${Api}/search/genres`;

//CLASSIFICATIONS
export const GET_PUBLIC_CATS=()=> `${Api}/search/classifications`;
export const GET_ALL_CAT=()=> `${Api}/classifications`;
export const CAT_ADDS=()=> `${Api}/classifications/adds`;
export const CAT_DELETE=(id)=> `${Api}/classifications/del/${id}`;
export const CAT_UPDATE=(id)=> `${Api}/classifications/update/${id}`;

//ENTITYS
export const GET_ALL_ENTITYS = (page, date, langue, type, collection, classification, genre)=> `${Api}/entitys?page=${page}&date=${date}&langue=${langue}&type=${type}&col=${collection}&cat=${classification}&g=${genre}`
export const CREATE_BIBLIO_ENTITYS = () => `${Api}/entitys/createsitems`;
export const ENTITY_DELETE=(uuid, id, fichier, type)=> `${Api}/entitys/del/${uuid}/${id}/${fichier}/${type}`;
export const GET_ENTITYS_ITEMS = (uuid, id) => `${Api}/entitys/${uuid}/${id}`;
export const ENTITYS_UPDATE_ITEMS = (uuid, id) => `${Api}/entitys/update/${uuid}/${id}`;
export const DELETE_TAGS_CATS=(id, entity)=> `${Api}/entitys/deltags/${id}/${entity}`;

//SEARCH
export const APP_SEARCH = (page, search, coll, type, cat, dt)=> `${Api}/search?page=${page}&search=${search}&collection=${coll}&type=${type}&cat=${cat}&date=${dt}`;
export const APP_HOME_SEARCH = (page, search, coll, type, cat, dt)=> `${Api}/search/home?page=${page}&search=${search}&collection=${coll}&type=${type}&cat=${cat}&date=${dt}`;
export const APP_GET_SEARCH_RESS = entity => `${Api}/search/ressources/${entity}`;
export const APP_GET_BIBLIO_ENTITY = (id, uuid) => `${Api}/search/g/e/${id}/${uuid}`;
export const APP_READ_ENTITY = (uuid, id, fichier) => `${Api}/ressources/g/r/${uuid}/${id}/${fichier}`;
