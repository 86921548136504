
import { useState, useEffect } from "react";
import axios from "axios";
import {SERVER_HOST} from './Configs';

import {
    UserLogin,
    Account_Invalid_Token,
    Refresh_User_Session
} from './../store/Accounts/Actions'

import { useDispatch, useSelector} from "react-redux";


function useUserAuth(){
    const Api = SERVER_HOST
    const dispatch = useDispatch();

    const state = useSelector(state => state.sessionAuth)
    const AuthSession = sessionStorage.getItem('currentUser');
    const stateSession = state.isAuthenticated
    const [auth, setAuth] = useState(false);
    
    useEffect(()=>{
       
        if(AuthSession != null) {
            const currentSession = JSON.parse(AuthSession)
            dispatch(Refresh_User_Session(currentSession))
            setAuth(true)
        }
        else if(localStorage.getItem('UserAuth')){

            const Auth  = JSON.parse(localStorage.getItem('UserAuth'))
             //get options
                let options = {
                    methods: 'GET',
                    url: Api+'/user/datas',
                    headers:{
                        'Authorization': 'Bearer '+Auth.token
                    }
                }

                axios(options).then(response =>{
                    
                    if(response.status === 200 && response.data.useruuid === Auth?.sessionId){
                        let currentValue = {token: Auth.token, res:response.data}
                        dispatch(UserLogin(currentValue))
                        setAuth(true)
                    }
                    else{
                        localStorage.removeItem('UserAuth');
                        
                    }
                    
                })
                .catch(er =>{
                    //console.log(er.response.status)
                    if(er.response.status === 401 ) {
                        dispatch(Account_Invalid_Token())
                        
                    }
                    localStorage.removeItem('UserAuth');
                    
                })
            }//fin getStorage
        else{
            setAuth(false)
            }
        // eslint-disable-next-line
    },[stateSession, AuthSession])
  
    return auth;
}

export default useUserAuth ;

