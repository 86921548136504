import React from 'react'
import { Outlet} from 'react-router-dom'
import { useSelector} from 'react-redux'
import { Alerte_Restriction } from '../../ui/AlertesUi'

const PrivateAdmin = () => {
const state = useSelector(state => state.sessionAuth)
const useAuthPermission =()=>{
const roles = state.roles
if( roles.includes('ROLE_ADMIN') || roles.includes('ROLE_EDITED') || roles.includes('ROLE_ASSIST') ){ return true }
else{return false}
}

const AdminAuth = useAuthPermission()
return AdminAuth ? <Outlet/> : (Alerte_Restriction('Vous n\'êtes pas autorisés à accéder à cette section. Contactez votre administrateur'))

}

export default PrivateAdmin
