import{
ADD_Genre_LOADING,
ADD_Genre_ERROR, 
ADD_Genre_SUCCESS,
UPDATE_ERROR_Genre,
UPDATE_SUCCESS_Genre,
DELETE_Genre,
CLEAR_NOTIF_STATE
    } from './Constants'

    const initialState = {
        isGenre_Loading: false,
        add_G_error: false,
        add_G_success: false,
        update_G_error: false,
        update_G_success: false,
        genre_err_msg: '',
        genre_success_msg: '',
        delete_genre: false
    }

    export const genresReducers = (state = initialState, action)=>{
    switch (action.type) {
        case ADD_Genre_LOADING:
            return {
                ...state,
                isGenre_Loading: true
            }
        case ADD_Genre_SUCCESS:
            return {
                ...state,
                isGenre_Loading: false,
                add_G_success: true,
                delete_genre: false,
                genre_success_msg: action.payload
            }
        case ADD_Genre_ERROR:
            return {
                ...state,
                isGenre_Loading: false,
                add_G_error: true,
                genre_err_msg: action.payload
            }
        case DELETE_Genre:
            return {
                ...state,
                delete_genre: true,
                add_G_success: false
            }
        case UPDATE_SUCCESS_Genre:
            return{
                ...state,
                update_G_success:true,
                update_G_error:false,
                delete_genre: false,
                add_G_success: false
            }
        case UPDATE_ERROR_Genre:
            return{
                ...state,
                update_G_error:true
            }
        case CLEAR_NOTIF_STATE:
            return {
                ...state,
                update_G_success:false,
                update_G_error:false,
                delete_genre: false,
                add_G_error: false,
                add_G_success: false
            }
        default:
            return state;
    }
}

