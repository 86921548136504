import{
ADD_Cat_LOADING,
ADD_Cat_ERROR, 
ADD_Cat_SUCCESS,
UPDATE_ERROR_Cat,
UPDATE_SUCCESS_Cat,
DELETE_Cat,
CLEAR_NOTIF_STATE
} from './Constants'

export const Add_Cat_Loading = ()=>{
    return{
        type: ADD_Cat_LOADING
    }
}
export const delete_Cat = ()=>{
    return{
        type: DELETE_Cat
    }
}

export const Add_Cat_Error = error=>{
    return{
        type: ADD_Cat_ERROR,
        payload: error
    }
}
export const Update_Cat_Error = error=>{
    return{
        type: UPDATE_ERROR_Cat,
        payload: error
    }
}
export const Add_Cat_Success = (success)=>{
    return{
        type: ADD_Cat_SUCCESS,
        payload: success
    }
}
export const Update_Cat_Success = ()=>{
    return{
        type: UPDATE_SUCCESS_Cat
    }
}
export const Clear_Cat_States = ()=>{
    return{
        type: CLEAR_NOTIF_STATE,
        //payload: state
    }
}