import React, {Fragment, useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {Accountvector} from '../imagepath';
import { BtnLoading } from '../ui/BtnUi';
import { Alerte_Error, Alerte_Warning } from '../ui/AlertesUi';
import { v4 as uuidv4 } from 'uuid';

import { useSelector, useDispatch } from 'react-redux';
import { User_Account_Register } from '../backend/store/Accounts/Dispatchs';

const Signup = () => {

document.title = 'Account - Signup'

const state = useSelector(state=> state.accountRegister);
const dispatch = useDispatch();

const [disabled, setDisabled] = useState(false)
const [pwdNonIdentique, setPwdNonIdentique] = useState(false)

//donnees de comptes
const dataSignup = {
            nom:'',
            prenoms:'',
            email:'',
            password:'',
            useruuid: uuidv4(),
            confirmPassword:''
    }

const [account, setAccount] = useState(dataSignup);
const Navigate = useNavigate();

const HandleInputChange = e =>{
setAccount({
...account,
[e.target.name]: e.target.value
})
}

useEffect(()=>{
   
if(account.confirmPassword !== account.password){
setDisabled(true);
setPwdNonIdentique(true)
}else if(account.password.length < 8 ){
setDisabled(true);
setPwdNonIdentique(false)
}
else if(account.nom === '' || account.prenoms === '' || account.nom.length < 2 || account.prenoms.length < 2){
    setDisabled(true);
}
else{
setDisabled(false)
setPwdNonIdentique(false)
}

// eslint-disable-next-line 

}, [account.prenoms, account.nom, account.confirmPassword, account.password, pwdNonIdentique, disabled])

//function submit user datas
const SignupUser = e =>{
e.preventDefault()
//enregistrement user
dispatch(User_Account_Register(account, Navigate))
setAccount(dataSignup)
}


return (
<Fragment>
<div className="content">

<div className="container-fluid">
<div className="row">
<div className="account-content">

<div className="successMsg">
{  state.signupSuccess && (<div className="alert alert-success alert-dismissible fade show" role="alert">
<strong> BRAVO ! </strong> 
Votre compte a été créé avec succès. Vous serez redirigé dans quelques instants
<button type="button" className="close" data-dismiss="alert" aria-label="Close">
<span aria-hidden="true">×</span>
</button>
</div>)
}
</div>

<div className="row align-items-center justify-content-center account_block">
{/** block login*/}

<div className="col-md-12 col-lg-5 login-right">
<div className="login-header text-center">
<h3>Créer un compte pour accéder aux ressources disponibles </h3>
</div>
<div>

<form name='signupForm' onSubmit={SignupUser}>

<div className="form-group form-focus">
<input type="text" className="form-control"
name='nom'
value={account.nom}
onChange={HandleInputChange} 
required/>
<label className="focus-label">Nom</label>
</div>

<div className="form-group form-focus">
<input type="text" className="form-control"
name='prenoms'
value={account.prenoms}
onChange={HandleInputChange} required/>
<label className="focus-label">Prenoms</label>
</div>
<div className="form-group form-focus">
<input type="email" className="form-control"
name='email'
value={account.email}
onChange={HandleInputChange} required/>
<label className="focus-label">Votre email</label>
</div>
<div className="form-group form-focus">
<input type="password" className="form-control"
name='password'
value={account.password}
onChange={HandleInputChange}
required/>
<label className="focus-label">Mot de passe (8 caractères requis)</label>
</div>
<div className="form-group form-focus">
<input type="password" className="form-control"
name='confirmPassword'
value={account.confirmPassword}
onChange={HandleInputChange}
required/>
<label className="focus-label">Confimer le mot de passe</label>
</div>

{ /** afficahe du button connexion et chargement */
!state.isLoadingSignup ? (<button className="btn btn-primary btn-block btn-lg login-btn"
type="submit" disabled={disabled}>
Créer le compte
</button>):(BtnLoading('Demande en cours...'))

}


</form>
<div className='loginError'>
{/** messages erreur login */}

{state.signupError && Alerte_Error(' Vérifiez les informations saisies ! ')}
{state.isExists && Alerte_Error(state.signupErrorMessage)}
{pwdNonIdentique && Alerte_Warning('Les deux mots de passe ne sont pas identiques')}


{/** //messages erreur login */}
</div>
{/**les options de login */}
<div className="login-or">
<span className="or-line" />
<span className="span-or">ou</span>
</div>

<div className="text-center dont-have">
J'ai déjà un compte syidocs :
<a style={{color:'#ea0f4d'}} href="/login"> Se connecter</a>
</div>
</div>
</div>
{/** imaage droite */}
<div className="col-md-7 col-lg-6 login">
<img src={Accountvector} className="img-fluid" alt="Login" />
</div>


</div>
</div>
</div>
</div>
</div>
</Fragment>
)
}

export default Signup
