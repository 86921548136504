import React, { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GroupeItems from './collections/GroupeItems';
import ListeBtn from './accesRapid/ListeBtn';
import DocumentsList from './categorie_documents/DocumentsList';
import TopBar from './header/TopBar';
import { Banner } from './header/banner';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { Vector,Img_C1,Img_C2,Img_C3,Img_C4,Img_C5 } from './imagepath';


const Accueil = () => {

document.title='Votre bibliothèque numérique interne'
const [textFilter, setTextFilter] = useState('')
const navigate = useNavigate()

  //Handlechange filtre
  const HandleChangeFilter = (e) => {
    setTextFilter(e.target.value);
  }

  const SearchFct = (e)=>{
   e.preventDefault()
   if(textFilter !== ''){
       navigate(`/search/entitys/1/null/null/${textFilter}`, {replace: false})
   }
   
  }


return (
<Fragment>
<Banner/>
<TopBar HandleChangeFilter={HandleChangeFilter} SearchFct={SearchFct}/>
<GroupeItems Vector={Vector} 
Img_C1={Img_C1}
Img_C2={Img_C2}
Img_C3={Img_C3} 
Img_C4={Img_C4} 
Img_C5={Img_C5}/>


{/* titre accès rapide */}

<div className="section-heading text-center" style={{marginTop:'0px', marginBottom:'50px'}}>
  <h2> ACCES RAPIDE AUX RESSOUCRES </h2> 
  <ListeBtn/> 
</div>

{/* Books Section */}
<div className="col" style={{padding:'0px 30px 0px 30px', marginBottom:'50px'}}>
<DocumentsList/>
</div>

{/* /fin top */}

</Fragment>
)
}

export default Accueil