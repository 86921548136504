import{
ADD_Genre_LOADING,
ADD_Genre_ERROR, 
ADD_Genre_SUCCESS,
UPDATE_ERROR_Genre,
UPDATE_SUCCESS_Genre,
DELETE_Genre,
CLEAR_NOTIF_STATE
} from './Constants'

export const Add_Genre_Loading = ()=>{
    return{
        type: ADD_Genre_LOADING
    }
}
export const delete_Genre = ()=>{
    return{
        type: DELETE_Genre
    }
}

export const Add_Genre_Error = error=>{
    return{
        type: ADD_Genre_ERROR,
        payload: error
    }
}
export const Update_Genre_Error = error=>{
    return{
        type: UPDATE_ERROR_Genre,
        payload: error
    }
}
export const Add_Genre_Success = (success)=>{
    return{
        type: ADD_Genre_SUCCESS,
        payload: success
    }
}
export const Update_Genre_Success = ()=>{
    return{
        type: UPDATE_SUCCESS_Genre
    }
}
export const Clear_Genre_States = ()=>{
    return{
        type: CLEAR_NOTIF_STATE,
        //payload: state
    }
}