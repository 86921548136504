import { toast } from "react-toastify"
//error alerte

export const Alerte_Error = (error) =>{
    return(
    <div className="alert alert-danger alert-dismissible fade show" role="alert">
    <strong>Erreur... ! </strong> 
    {error}
    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">×</span>
    </button>
  </div>
    )
}

export const Alerte_Warning = warning =>{
  return(
  <div className="alert alert-warning alert-dismissible fade show" role="alert">
  <strong>Alerte... ! </strong> 
  {warning}
  <button type="button" className="close" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true">×</span>
  </button>
</div>
  )
}

export const Alerte_Succes = succes =>{
    return(
    <div className="alert alert-success alert-dismissible fade show" role="alert">
    <strong>SUCCES : </strong> 
    {succes}
    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">×</span>
    </button>
  </div>
    )
  }

  export const Alerte_Restriction = restriction =>{
    return (
        <div style={{'marginTop': '5px', 'marginBottom': '30%'}} className="row justify-content-center">
         <div className="col-lg-12">
         <div className="alert alert-danger d-flex align-items-center" role="alert">
         <i className="ri-close-circle-fill"></i>
            <div><strong> &nbsp;  &nbsp; Restriction d'accès ! </strong> 
                {restriction}
            </div>
         </div>
     </div>
     </div>
    )
  }

  export const ToastError = error => {
    return(<div style={{ 'marginTop': '5px', 'marginBottom': '30%'}} className="row justify-content-center">
    <div className="col-lg-12" style={{'display': 'none'}}>
    {toast.error(error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        })}
        </div>
        </div>
    )
  }

  export const ToastConfirm = confirm => {
    return(<div style={{ 'marginTop': '5px', 'marginBottom': '30%'}} className="row justify-content-center">
    <div className="col-lg-12" style={{'display': 'none'}}>
    {toast.success(confirm, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        })}
        </div>
        </div>
    )
  }

  export const ToastFixResponse = response => {
    return(<div style={{ 'marginTop': '5px', 'marginBottom': '30%'}} className="row justify-content-center">
    <div className="col-lg-12" style={{'display': 'none'}}>
    {toast.warn(response, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 1,
        theme: "dark",
        })}
        </div>
        </div>
    )
  }
