import {
    RESET_LOADING,
    CHECK_EMAIL_ERROR,
    CHECK_VALIDE,
    CONFIRM_RESET_FAILED,
    CONFIRM_RESET_LOADING,
    CONFIRM_RESET_SUCCESS
} from './Constantes'

export const ResetLoading = ()=>{
   return{
    type: RESET_LOADING
   } 
}

export const CheckValideMail = (datas) =>{
    return{
        type: CHECK_VALIDE,
        payload: datas
    }
}

export const CheckError = (error)=>{
    return{
     type: CHECK_EMAIL_ERROR,
     payload: error
    } 
 }

 export const ConfirmResetLoading = ()=>{
    return{
     type: CONFIRM_RESET_LOADING
    } 
 }

 export const ConfirmResetFailed = (error)=>{
    return{
     type: CONFIRM_RESET_FAILED,
     payload: error
    } 
 }
 export const ConfirmResetSuccess = (success)=>{
    return{
     type: CONFIRM_RESET_SUCCESS,
     payload: success
    } 
 }



