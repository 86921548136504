import React from 'react'
import { Outlet } from 'react-router-dom'

const ResetPassword = () => {
    
  return (
        
        <Outlet/>
  )
}

export default ResetPassword
