import{
    Add_Collection_Loading, 
    Add_Collection_Error,
    Add_Collection_Success,
    delete_Collection,
    Update_Collection_Error, 
    Update_Collection_Success
} from './Actions'
import axios from "axios";
import { AxiosPostDatas } from '../../fetchDatasAxios/FetchPostDatas';
import { AxiosDeleteDatas } from '../../fetchDatasAxios/FetchDeleteDatas';
import { AxiosPutDatas } from '../../fetchDatasAxios/FetchPutDatas';

export const AppsCollections_Add = (url, token, collection)=>{
    const post = AxiosPostDatas(url, token, collection);

    return dispatch =>{
        dispatch(Add_Collection_Loading())
        axios(post).then(response =>{
            if(response.status === 201){
                dispatch(Add_Collection_Success(response.data.message))
            }
            
        }).catch(err =>{
            dispatch(Add_Collection_Error(err.response.data.message))
        })
    }
}

export const AppsCollections_Delete = (url, token)=>{
    const sup = AxiosDeleteDatas(url, token);

return dispatch =>{
    axios(sup).then(response =>{
        dispatch(delete_Collection())
    }).catch(err=>{console.log(err)})
}
}

//Update Collections
export const AppsCollections_Update = (url, token, collection)=>{
    const update = AxiosPutDatas(url, token, collection);

return dispatch =>{
    axios(update).then(response =>{
       dispatch(Update_Collection_Success())
    }).catch(err=>{console.log(err) 
        dispatch(Update_Collection_Error(err.response.data.message))})
}
}