import React, { useEffect, useState } from 'react'


import { DELETE_TAGS_CATS } from '../../auth/urlParameters'
import { deleteTagsCatsItem } from '../../store/BiblioEntity/Dispatchs'
import { useDispatch } from 'react-redux'


const DeleteItemsTagsCats = (props) => {
    const {classifications, entity, token, setConfirmAlerteTags} = props
    const [disable, setDisable] = useState(true)
    const dispatch = useDispatch()

    function limit (string = '', limit = 0) {  
        return string.substring(0, limit)
      }

      const clk = (Cat, entity) => {
        if(disable){
            return  deleteTagsSubmit(Cat.id, entity)
        }
        else return notDelete()
    }

    const notDelete = ()=>{
        console.log('null')
    }

    useEffect(()=>{
        if(classifications){
            if(classifications.length === 1){setDisable(false)}
        }
        
    }, [classifications])

      const deleteTagsSubmit = (id, entity) =>{
        dispatch(deleteTagsCatsItem(DELETE_TAGS_CATS(id, entity), token))
        setConfirmAlerteTags(false)
      }
      
  return classifications ? (
    
     classifications.map( (Cat) =>{
       
                return( 
                    <span key={Cat.id} id ="tags" className="badge badge-dark">
                    
                    <a className='btn' onClick={() => clk(Cat, entity)} >
                    <span className="spinner-border-sm"> <i className="ri-delete-bin-line"></i> </span>
                    {limit(Cat.nom, 30)}
                    </a>
                </span>
                    
                )
            })
            

  ): ''
}

export default DeleteItemsTagsCats