import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { SERVER_FILES } from '../auth/Configs'
//config Menus users
import { MenuAdmin, MenuEdit, MenuAssist } from '../auth/Menu'

const Sidebar = () => {
  const {account, profile} = useSelector(state => ({
    account: state.sessionAuth,
    profile: state.userSettings
  }));


  //gestion des menus par type d'utilisateur
  const Menus = () =>{
    if(account.roles.includes('ROLE_ADMIN') || account.roles.includes('ROLE_SUPER_ADMIN')){
      return MenuAdmin();
    }
    else if (!account.roles.includes('ROLE_ADMIN') && account.roles.includes('ROLE_EDITED')){
      return MenuEdit();
    }
    else{
      return MenuAssist();
    }
  }

  return (
    <Fragment>
      <div className="profile-sidebar">

      <div className="widget-profile pro-widget-content">
      <div className="profile-info-widget">
      <Link to="/apps/home/user" className="booking-doc-img"> 
      
      {profile.fileUrl == null ?(
        <div className="avatar avatar-xxl">
        <span className="avatar-title rounded-circle border border-white">
        {profile.nom.charAt(0)+profile.prenoms.charAt(0)}

        </span>
        </div>
      ):(<img src={SERVER_FILES+profile.fileUrl} alt='profile'/>)}
      
      </Link>
      <div className="profile-det-info">
      <h3>{profile.nom +' '+profile.prenoms}</h3>
      <div className="receiver-details"><h5 className="mb-0">
      { account.roles.includes('ROLE_ADMIN') ? 'Administrateur': 
        account.roles.includes('ROLE_EDITED') ? 'Editeur' :
        account.roles.includes('ROLE_ASSIST') ? 'Assistant' 
        : 'Lecteur'}
      </h5>
      </div>
      </div>
      </div>
      </div>
      <div className="dashboard-widget">
        <nav className="dashboard-menu">
        <ul>
        {Menus()}
        </ul>
        </nav>

      </div>


      </div>
    </Fragment>
  )
}

export default React.memo(Sidebar)
