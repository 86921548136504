import { createEntityError, createEntitySuccess, clearEntityStates,
 deleteSuccess, deleteError, updateSuccess, updateError, deleteTagsCats  } from "./Actions";

 import { AxiosDeleteDatas } from "../../fetchDatasAxios/FetchDeleteDatas";
import axios from "axios";

export const AppsBiblioEntity_Add = (url, token, entity, setModalShow,  ModalHide)=>{
    
    return dispatch =>{
        //clear state
        setModalShow(true)
        dispatch(clearEntityStates())
        //post
        let post = {
            method: 'POST',
            url: url,
            headers: { 
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer '+token
            },
            data: entity
        }

        axios(post).then(response =>{
            //console.log(response)
            if(response.status === 201){
                dispatch(createEntitySuccess(response.data.message))
                ModalHide();
            }
            else{
                ModalHide();
                dispatch(createEntityError(response.data.message))
            }
            
        }).catch(err =>{
            //console.log(err)
            ModalHide();
            dispatch(createEntityError(err.response.data.message))
        })

        /*setTimeout(()=>{
           
            ModalHide();
            
        },[500])*/

    }
}

export const AppsEntitys_Delete = (url, token)=>{
    const del = AxiosDeleteDatas(url, token);

return dispatch =>{

    dispatch(clearEntityStates())

    axios(del).then(response =>{
        //console.log(response);
        dispatch(deleteSuccess(response.data.message))
    }).catch(err=>{
        //console.log(err)
        dispatch(deleteError (err.response.data.message))
    })
}
}

//Update entitys items

export const UpdateEntitysItem = (url, token, entity) =>{
    
    return dispatch =>{
        //clear state
        dispatch(clearEntityStates())
        //post
        let post = {
            method: 'POST',
            url: url,
            headers: { 
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer '+token
            },
            data: entity
        }

        axios(post).then(response =>{
            //console.log(response)
            if(response.status === 200){
                dispatch(updateSuccess(response.data.message))
            }
            else{
                dispatch(updateError(response.data.message))
            }
            
        }).catch(err =>{
            //console.log(err)
            dispatch(updateError(err.response.data.message))
        })
    }
}

//DELET TAGS CAT
export const deleteTagsCatsItem = (url, token) => {
    const sup = AxiosDeleteDatas(url, token);
    
return dispatch =>{
    dispatch(clearEntityStates())
    axios(sup).then(response =>{
        dispatch(deleteTagsCats())
    }).catch(err=>{console.log(err)})
}
}

