import{
    Add_Genre_Loading, 
    Add_Genre_Error,
    Add_Genre_Success,
    delete_Genre,
    Update_Genre_Error, 
    Update_Genre_Success
} from './Actions'
import axios from "axios";
import { AxiosPostDatas } from '../../fetchDatasAxios/FetchPostDatas';
import { AxiosDeleteDatas } from '../../fetchDatasAxios/FetchDeleteDatas';
import { AxiosPutDatas } from '../../fetchDatasAxios/FetchPutDatas';

export const AppsGenres_Add = (url, token, Genre)=>{
    const post = AxiosPostDatas(url, token, Genre);

    return dispatch =>{
        dispatch(Add_Genre_Loading())
        axios(post).then(response =>{
            if(response.status === 201){
                dispatch(Add_Genre_Success(response.data.message))
            }
            
        }).catch(err =>{
            dispatch(Add_Genre_Error(err.response.data.message))
        })
    }
}
export const AppsGenres_Delete = (url, token)=>{
    const sup = AxiosDeleteDatas(url, token);

return dispatch =>{
    axios(sup).then(response =>{
        dispatch(delete_Genre())
    }).catch(err=>{console.log(err)})
}
}

//Update Genres
export const AppsGenres_Update = (url, token, Genre)=>{
    const update = AxiosPutDatas(url, token, Genre);

return dispatch =>{
    axios(update).then(response =>{
       dispatch(Update_Genre_Success())
    }).catch(err=>{console.log(err) 
        dispatch(Update_Genre_Error(err.response.data.message))})
}
}