export const AxiosPutDatas = (Url, Token, Item) => {
    const config = {
    method: 'PUT',
    url: Url,
    headers: { 
    'Authorization': 'Bearer '+Token
    },
    data: Item
    }
    return config;
            
}