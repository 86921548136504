import React, { Fragment, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import StickyBox from 'react-sticky-box'
import Breadcrumb from '../Breadcrumb'
import Sidebar from '../Sidebar'
import { Alerte_Error } from '../../../ui/AlertesUi'
import { BtnLoading } from '../../../ui/BtnUi'
import { ToastError, ToastConfirm } from '../../../ui/AlertesUi'
import { USERS_SESSION, GET_USERS_ACCOUNT, UPDATE_USER_ACCOUNT } from '../../auth/urlParameters'
import useAxiosToGet from '../../fetchDatasAxios/FetchGet'
import { AxiosGetDatas } from '../../fetchDatasAxios/FetchGetDatas'
import axios from 'axios'
import { UpdateAccountFromAdmin } from '../../store/Users/Dispatchs'

const UpdateUserAccount = () => {
  const userAccount = {
    email: '',
    roles: null,
    etat: ''
  }
  const state = useSelector(state => state.sessionAuth)
  const dispatch = useDispatch()
  const[nullAuth, setNullAuth] = useState(false)
  const[user, setUser] = useState(userAccount)
  const[subLoading, setSubLoading]= useState(false)
  const[result, setResult]= useState(null)
  
  const params = useParams()
 
  const {isAppLoading, fetchDatas} = useAxiosToGet(USERS_SESSION(), state.token);
  //fonction get update compte
  const GetUpdateUser = () =>{
    const config = AxiosGetDatas(GET_USERS_ACCOUNT(params.user), state.token);
    axios(config).then(response =>{
      let account = response.data
      setUser({
        ...userAccount,
        ...account
      })
    }).catch(error =>{console.log(error)})
  }
  //simplification des roles
  const StringRoles = (roles)=>{
    if(roles === 'ROLE_ADMIN'){
      return 'Administrateur'
    }
    else if(roles === 'ROLE_EDITED'){
      return 'Editeur'
    }
    else if(roles === 'ROLE_ASSIST'){
      return 'Assistant'
    }
    else { return 'Utilisateur' }
  }
    useEffect(()=>{
        
        if(fetchDatas !== null){
         
          if(params.sessionId !== fetchDatas.data.useruuid || parseInt(params.id) !== fetchDatas.data.id){
            setNullAuth(true)
          }
          GetUpdateUser()
           
        }
    // eslint-disable-next-line 
    },[fetchDatas])

    //onchange
    const UpdateChange = e =>{
      setUser({
        ...user,
        [e.target.name] : e.target.value
      })
    }
    const UpdateChangeRoles = e =>{
      setUser({
        ...user,
        roles : [e.target.value]
      })
    }
   // console.log(user)
    //submitForm

    const submitForm = (e)=>{
      e.preventDefault()
      setSubLoading(true)
      dispatch(UpdateAccountFromAdmin(UPDATE_USER_ACCOUNT(user.id, user.useruuid), state.token, user, setResult))
      setSubLoading(false)
    }
    //notification
    const notifs = notif=>{
      if(notif === true){
        setResult(null)
        return ToastConfirm('Compte modifié avec succès')
      }
      else if(notif === false){
        setResult(null)
        return ToastError('Echec de la tentative de modification')
      }
      else{return null}
    }

  return !isAppLoading && (
    nullAuth ?(<div style={{'width':'60%', 'margin':'auto', 'marginTop':'60px', 'marginBottom':'260px'}} > {Alerte_Error('Action non autorisée ! ')} </div>) 
    :(<Fragment>
    <Breadcrumb title='Bibliothèque' submenu ='Update/User'/>

    <div className="content">
    <div className="container">
        <div className="row">
        <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
        {/*Sidebar */}
        <StickyBox offsetTop={20} offsetBottom={20}>
        <Sidebar/>
        </StickyBox>                
        {/*Sidebar */}
        </div>
        {/*application */}
        <div className="col-md-7 col-lg-8 col-xl-9">
        <div className="row app_items">


        {/*header content */}
          <div className="col-12 col-sm-4 col-md-6">
          <h4 className="htitle">MODIFICATION DU COMPTE DE L'UTILISATEUR</h4>
          </div>
          <div className="col-12 col-sm-8 col-md-6 text-sm-right">
            &nbsp;
          </div>
        {/*fin header content */}
        
        <form style={{'marginTop':'28px', 'borderTop':'1px solid#ededed', 'paddingTop':'20px'}}>
              <div className="row form-row">
                <div className="col-12 col-md-8">
                  <div className="form-group">
                  <label>Email du compte</label>
                  <input name='email' type="text" className="form-control"
                  value={user.email} onChange={UpdateChange}/>
                  </div>
                </div>
                <div className="col-12 col-md-8">
                  <div className="form-group">
                  <label>Rôle de l'utilisateur</label>
                  <select name="roles" className="form-control"  onChange={UpdateChangeRoles}>
                    {
                      user.roles === null ? (<option value='null'>Rôles</option>)
                      :(<>
                        <option value={user.roles[0]}>{StringRoles(user.roles[0])}</option>
                        <option value='ROLE_ADMIN'>Administrateur</option> 
                        <option value='ROLE_EDITED'>Editeur</option>
                        <option value='ROLE_ASSIST'>Assistant</option>
                        <option value='ROLE_USER'>Utilisateur</option>
                        </>) 
                    }
                    
                  </select>
                  </div>
                </div>
                <div className="col-12 col-md-8">
                  <div className="form-group">
                  <label>Status du compte</label>
                  <select name="etat" className="form-control"  onChange={UpdateChange}>
                    <option value={user.etat}>{user.etat}</option>
                    <option value="actif">Activer</option>
                    <option value="vérrouillé">vérrouiller</option>
                    <option value="suspendu">Suspendre</option>
                  </select>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-8">
                <div className="submit-section">
                {!subLoading ? <button type="submit" className="btn btn-primary btn-block btn-lg login-btn" onClick={submitForm}>Enregistrer</button> : BtnLoading('Modification en cours')} 
                </div>
              </div>
            </form>
        </div></div>
        {/*fin app */}
        </div>
    </div>
</div>
{notifs(result)}
</Fragment>	)
  )
}

export default UpdateUserAccount