import{
    UPDATE_ERROR,
    UPDATE_SUCCESS,
    DELETE_USER,
    CLEAR_NOTIF_STATE
    } from './Constants'

    const initialState = {
    update_error: false,
    update_success: false,
    delete_user: false
    }
    export const gestionUserAccounts = (state = initialState, action)=>{
    switch (action.type) {
        case UPDATE_ERROR:
            return {
                ...state,
                update_error: true
            }
        case UPDATE_SUCCESS:
            return{
                ...state,
                update_error: false,
                update_success: true
            }
        case DELETE_USER:
            return{
                ...state,
                update_error: false,
                update_success: false,
                delete_user: true
            }
        case CLEAR_NOTIF_STATE:
            return state 
        default:
            return state;
    }
}

