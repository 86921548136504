import{
ADD_C_LOADING,
ADD_C_ERROR, 
ADD_C_SUCCESS,
UPDATE_ERROR,
UPDATE_SUCCESS,
DELETE_COLLECTION,
CLEAR_NOTIF_STATE
} from './Constants'

export const Add_Collection_Loading = ()=>{
    return{
        type: ADD_C_LOADING
    }
}
export const delete_Collection = ()=>{
    return{
        type: DELETE_COLLECTION
    }
}

export const Add_Collection_Error = error=>{
    return{
        type: ADD_C_ERROR,
        payload: error
    }
}
export const Update_Collection_Error = error=>{
    return{
        type: UPDATE_ERROR,
        payload: error
    }
}
export const Add_Collection_Success = (success)=>{
    return{
        type: ADD_C_SUCCESS,
        payload: success
    }
}
export const Update_Collection_Success = ()=>{
    return{
        type: UPDATE_SUCCESS
    }
}
export const Clear_Collection_States = ()=>{
    return{
        type: CLEAR_NOTIF_STATE,
        //payload: state
    }
}