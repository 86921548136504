import React from 'react'
import usePublicAxiosToGet from '../../fetchDatasAxios/FetchPublicGet'
import { GET_PUBLIC_COLLECTIONS } from '../../auth/urlParameters'

const PublicSelectListeCol = (props) => {
    const {collection, setCollection} = props;
    const{isAppLoading, fetchDatas} = usePublicAxiosToGet(GET_PUBLIC_COLLECTIONS())
    
  return !isAppLoading ? (
    fetchDatas.status === 400 ?( 
        <select name='collection' defaultValue={collection} className="form-control select" disabled required>
        <option value={null}>Pas de données disponibles</option>
        </select> 
        ) : fetchDatas.status === 200 ? (
        <select onChange={(e)=>setCollection(e.target.value)} defaultValue={collection} name='collection' className="form-control select" required>
        <option>Filtrer par collection</option>
        { fetchDatas.data.map((items) =><option key={items.id} value={items.id}>{items.titre}</option> )}
        </select> 
        )
        : (<select name='collection' defaultValue={collection} className="form-control select" required>
        <option value={null}>Pas de données disponibles</option>
        </select>)
      ) : ''
}

export default React.memo(PublicSelectListeCol) 