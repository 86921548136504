import React, { Fragment } from 'react'
import useAxiosToGet from '../../fetchDatasAxios/FetchGet'
import { useSelector } from 'react-redux'
import { COUNT_USERS} from '../../auth/urlParameters'

const CountUsers = () => {
    //GET Count numerisation
    const session = useSelector(state => state.sessionAuth)
    const {isAppLoading, fetchDatas} = useAxiosToGet(COUNT_USERS(), session.token);
    
  return (
    <Fragment>
        <div className="col-md-12 col-lg-4">
        <div className="dashbord-app dash-widget dct-border-rht" style={{'borderRight':'none'}}>
        <div className="circle-bar">
        <i className="ri-team-line"></i>
        </div>
        <div className="dash-widget-info">
        <h6>Utilisateurs</h6>
        <h3>{isAppLoading === true ? '': fetchDatas.data.countUsers}</h3>
        </div>
        </div>
        </div>
    </Fragment>
  )
}

export default React.memo(CountUsers);