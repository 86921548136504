import React from 'react'
import useAxiosToGet from '../../fetchDatasAxios/FetchGet'
import { GET_ALL_COLLECTIONS } from '../../auth/urlParameters'

const SelectListeColl = (props) => {
    const {collection, token, setCollection, text} = props;
    const{isAppLoading, fetchDatas} = useAxiosToGet(GET_ALL_COLLECTIONS(), token)
    
  return !isAppLoading ? (
    fetchDatas.status === 400 ?( 
    <select name='collection' defaultValue={collection} className="form-control select" disabled required>
    <option value={null}>Vous devez obligatoirement définir une collection de la bibliothèque</option>
    </select> 
    ) : fetchDatas.status === 200 ? (
    <select onChange={(e)=>setCollection(e.target.value)} defaultValue={collection} name='collection' className="form-control select" required>
    <option>{text}</option>
    { fetchDatas.data.map((items) =><option key={items.id} value={items.id}>{items.titre}</option> )}
    </select> 
    )
    : (<select name='collection' defaultValue={collection} className="form-control select" required>
    <option value={null}>Selection de la collection</option>
    </select>)
    
    ) : ''
}

export default React.memo(SelectListeColl) 