import React, { Fragment} from 'react'
import StickyBox from 'react-sticky-box'
import Breadcrumb from '../Breadcrumb'
import Sidebar from '../Sidebar'
//import Biblio statistique
import CountNum from './CountNum'
import CountCollection from './CountCollection'
import CountUsers from './CountUsers'
import DocumentsList from '../../../categorie_documents/DocumentsList'

const Dashboard = () => {
    
return (
<Fragment>
<Breadcrumb title='Bibliothèque' submenu ='Dashboard'/>

<div className="content">
<div className="container">
<div className="row">
<div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
{/*Sidebar */}
<StickyBox offsetTop={20} offsetBottom={20}>
<Sidebar/>
</StickyBox>                
{/*Sidebar */}
</div>
{/*application */}
<div className="col-md-7 col-lg-8 col-xl-9">
<div className="row">

<div className="col-md-12">
    <div className='card dash-card'>
        <div className='card-body'>
            <div className='row'>
            {<CountNum/>}
            {<CountCollection/>}
            {<CountUsers/>}
            </div>
            </div>
        </div>

    </div>
    {/**debut des stats */}
   
        <h4>VOS ENTITES</h4>
        <div className="col-md-12">
        <DocumentsList/>
        </div>
  

    {/**fin stats */}
    
</div>
</div>
{/*fin app */}
</div>
</div>
</div>
</Fragment>	
)
}

export default Dashboard
