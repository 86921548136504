import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {LoginRigthBg} from '../imagepath';
import { BtnLoading } from '../ui/BtnUi';
import { Alerte_Error } from '../ui/AlertesUi';
import { DefaultSpinner } from '../ui/Spinner';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { GetUserToken } from '../backend/store/Accounts/Dispatchs';

const Login = (props) => {
const [isLogin , setIsLogin] = useState(null);
document.title = 'Account - Login'

const [userLogin, setUserLogin] = useState('')
const [userPwd, setUserPwd] = useState('') 
const Navigate = useNavigate()

const state = useSelector(state => state.sessionAuth)
const dispatch = useDispatch()

const LoginFormSubmit = e =>{
e.preventDefault();

const Login = {
username: userLogin,
password: userPwd
}
setUserPwd('');
//appel de Axios et dispatch des actions
dispatch(GetUserToken(Login, Navigate))

}

useEffect(()=>{

setTimeout(()=>{
setIsLogin(state.isAuthenticated)
},[1000])
},[isLogin, state.isAuthenticated])


return isLogin === null ? (DefaultSpinner('Chargement login datas ...')) : 
isLogin ? (Navigate('/accueil', {replace: true}))  : (
<Fragment>
<div className="content">
<div className="container-fluid">
<div className="row">
<div className="account-content">
<div className="row align-items-center justify-content-center account_block">

{/** block login*/}
<div className="col-md-12 col-lg-5 login-right">
<div className="login-header text-center">
<h3 style={{marginBottom: '10px'}}>Se connecter </h3>
<i className="ri-user-6-line"></i>
</div>
<div>

<form name='loginForm' onSubmit={LoginFormSubmit}>
<div className="form-group form-focus">

<input type="email" className="form-control" 
value={userLogin}
onChange={e => setUserLogin(e.target.value)} required autoComplete='true'/>
<label className="focus-label">Votre Email</label>
</div>

<div className="form-group form-focus">
<input type="password" className="form-control floating" 
value={userPwd}
onChange={e => setUserPwd(e.target.value)} required/>
<label className="focus-label">Votre mot de passe</label>
</div>

<div className="text-left">
<a className="forgot-link" href="/reset-password/app-check-email">Mot de passe oublié ?</a>
</div>

{ /** afficahe du button connexion et chargement */
!state.isLoading ? (<button className="btn btn-primary btn-block btn-lg login-btn" 
type="submit">
Se connecter
</button>):(BtnLoading('Connexion'))

} 


</form>

<div className="text-center dont-have">
Compte démo : 
<a style={{color:'#206cbd'}}href="/login"> 
users@syidocs.com
</a>
</div>

<div className="text-center dont-have">
Mot de passe : 
<a style={{color:'#206cbd'}}href="/login"> 
users@syidocs.com
</a>
</div>


<div className="login-or">
<span className="or-line" />
<span className="span-or">ou</span>
</div>

<div className="row form-row social-login">
<div className="btn btn-facebook btn-block">
Le contenu du site est à titre démonstratif.
 </div>
</div>


<div className="text-center dont-have">
Vous n'avez pas de compte ?  
<a style={{color:'#ea0f4d'}}href="/signup"> S'inscrire</a>
</div>
</div>
<div className='loginError'>
{/** messages erreur login */}
{state.loginError && Alerte_Error(' Echec de la tentative de connexion, verifiez vos identifiants ')}
{/** //messages erreur login */}
</div>
</div>
{/** imaage droite */}
<div className="col-md-7 col-lg-6 login">
<img src={LoginRigthBg} className="img-fluid" alt="Login" />	
</div>

</div>
</div>
</div>
</div>
</div>
</Fragment>
)
}

export default Login
