
//constantes de connexion login

export const SIGIN_SUCCESS = 'LOGIN_SUCCESS'
export const SIGIN_ERROR = 'SIGIN_ERROR'
export const LOGOUT_USER = 'LOGOUT_USER'
//account user
export const LOADING_SIGNUP = 'LOADING_SIGNUP'
export const ACCOUNT_REGISTER_SUCCESS = 'ACCOUNT_REGISTER_SUCCESS'
export const ACCOUNT_IS_EXISTS = 'ACCOUNT_IS_EXISTS'
export const ACCOUNT_ERROR_REGISTER = 'ACCOUNT_ERROR_REGISTER'
export const ACCOUNT_EXPIRED_TOKEN = 'ACCOUNT_EXPIRED_TOKEN'
export const ACCOUNT_REFRESH_SESSION = 'ACCOUNT_REFRESH_SESSION'

