
import { ResetLoading, CheckValideMail, CheckError, ConfirmResetLoading,
    ConfirmResetFailed, ConfirmResetSuccess } from './Actions'
import axios from 'axios'
import {Api} from '../../auth/Configs'


export const AppResetUserPwd_checkMail = (user) =>{
    return dispatch =>{
        dispatch(ResetLoading())

        //Appel
        
        axios.post(Api+'/accounts/reset/password', user).then(res =>{
            if(res.status === 200 ){
                dispatch(CheckValideMail(res.data))
               
            }})
        .catch(er =>{
            //console.log(er)
            er.message === 'Network Error' ? dispatch(CheckError('Tentative de connxion échouée !!!')) : dispatch(CheckError(er.response.data.message)) 
        })
        
    }
}

//function verif get url data

export const Url_Verif = (sessionid, token) =>{
        let retValue = true
    
        const Reset = JSON.parse(localStorage.getItem('responseCheckpwdReset'))
        if(sessionid !== Reset?.responseCheckpwdReset.sessionId || token !== Reset?.responseCheckpwdReset.token){
            retValue = false
        }
    
    return retValue
}

export const AppResetUserPwd_ConfirmUpdate = (userID, resetToken, pwd) =>{
      return dispatch =>{
        dispatch(ConfirmResetLoading())
        
        if(pwd){
            axios.post(Api+'/accounts/sessionId='+userID+'/reset-pwd/'+resetToken, pwd).then(res =>{
                
               if(res.status === 200 ){
                    dispatch(ConfirmResetSuccess(res.data.message))
                }
               
                
            })
            .catch(er =>{
                er.message === 'Network Error' ? dispatch(CheckError('Tentative de connxion échouée !!!')) : dispatch(ConfirmResetFailed(er.response.data.message))
            })
            
        }
        
    }
}


export const AppChangeUserPwd = (userID, resetToken, pwd) =>{
    return dispatch =>{
      dispatch(ConfirmResetLoading())
      
      if(pwd){
          axios.post(Api+'/accounts/r2/sessionId='+userID+'/reset-pwd/'+resetToken, pwd).then(res =>{
              
             if(res.status === 200 ){
                  dispatch(ConfirmResetSuccess(res.data.message))
              }
             
              
          })
          .catch(er =>{
              er.message === 'Network Error' ? dispatch(CheckError('Tentative de connxion échouée !!!')) : dispatch(ConfirmResetFailed(er.response.data.message))
          })
          
      }
      
  }
}

