import React, { Fragment, useEffect, useState } from 'react'
import StickyBox from 'react-sticky-box'
import Breadcrumb from '../Breadcrumb'
import Sidebar from '../Sidebar'
//fetch
import { useSelector, useDispatch } from 'react-redux'
import { AxiosGetDatas } from '../../fetchDatasAxios/FetchGetDatas'
import { AppsGenres_Add, AppsGenres_Delete, AppsGenres_Update } from '../../store/Genres/Dispatchs'
import { GET_ALL_GENRES, GENRES_ADDS, GENRES_DELETE, GENRES_UPDATE } from '../../auth/urlParameters'

import { Button, Modal, ModalHeader, ModalBody, ModalFooter,
FormGroup, Input, Form } from 'reactstrap'
import TabListeGenre from './TabListeGenre'
import { SimpleSpinner } from '../../../ui/Spinner'
import {Alerte_Warning, ToastError, ToastConfirm} from '../../../ui/AlertesUi'
import axios from 'axios'
//clear state
import { Clear_Genre_States } from '../../store/Genres/Actions'


const Genres = () => {
  //STATE DU STORE
  const {session, stateGenres} = useSelector(state => ({
    session: state.sessionAuth,
    stateGenres: state.genres
  }));

  const dispatch = useDispatch();

  const initialState = [];
  const[isAppLoading, setIsAppLoading] = useState(true)
  const[genre, setGenre] = useState(initialState);
  const[notDatas, setNoDatas] = useState(false);
  const[btnLoad, setBtnLoad] = useState(false)
  const [modal, setModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [updateModal, setUpdateModal] = useState(false)
  //Update
  const [updateId, setUpdateId] = useState(null)
  const [updateGenre, setUpdateGenre] = useState('')
  //Delete
  const [deleteId, setDeleteId] = useState(null)
  //genre
  const[confirmAlerte, setConfirmAlerte] = useState(false)
  const[errorAlerte, setErrorAlerte] = useState(false)
  const[titreGenre, setTitreGenre] = useState('')

  //fonction Calback de reccupération data genre
  const GenresGet = () => AxiosGetDatas(GET_ALL_GENRES(), session.token);

   useEffect(()=>{
    const ListeGet = GenresGet();
    axios(ListeGet).then(res =>{
    setGenre(res.data);
    setNoDatas(false);
    }
    ).catch(error =>{
      setGenre(error.response.data);
      setNoDatas(true);
      
    })

    setIsAppLoading(false)
     // eslint-disable-next-line
   },[stateGenres.add_G_success, stateGenres.delete_genre, stateGenres.update_G_success])
  
   //close modal
   const ModalClose = () => setModal(false);
   const toggle = () => setModal(!modal);
   const DeleteToggle = (id) => {
    setDeleteModal(!deleteModal)
    setDeleteId(id)
   }
   //update genres
   const UpdateToggle = (id, titre) => {
    setUpdateModal(!updateModal)
    setUpdateId(id)
    setUpdateGenre(titre)
   }

   //Btn
   const closeBtn = (
    <button className="close" onClick={ModalClose} type="button">
      &times;
    </button>
  )
  //Update close
  const UpdateCloseBtn = (
    <button className="close" onClick={()=>setUpdateModal(false)} type="button">
      &times;
    </button>
  )
  //delet close
  const DeleteCloseBtn = (
    <button className="close" onClick={()=>setDeleteModal(false)} type="button">
      &times;
    </button>
  )
  //Alert success
  const AlertSuccess = (alerte) =>{
    if(confirmAlerte === false){
      ToastConfirm(alerte)
      setConfirmAlerte(true)
    }
  }
  //Error Alerte
  const AlertError = alerte =>{
    if(errorAlerte === false){
      ToastError(alerte)
      setErrorAlerte(true)
    }
  }
  
 //dispatch genre
 const addGenre = (e)=>{
  e.preventDefault();
  dispatch(Clear_Genre_States())
  setConfirmAlerte(false)
  setErrorAlerte(false)
  var data = {
    titre: titreGenre
  }
  dispatch(AppsGenres_Add(GENRES_ADDS(), session.token, data));
  setTitreGenre('')
  setModal(false)
 }
 //Delete
 
 const deleteGenre = () =>{
  dispatch(Clear_Genre_States())
  setConfirmAlerte(false)
  setErrorAlerte(false)
  setBtnLoad(true)
  dispatch(AppsGenres_Delete(GENRES_DELETE(deleteId), session.token))
  setBtnLoad(false)
  setDeleteModal(false)

 }
 //Update
 const updateGenreOn = (e) =>{
  e.preventDefault()
  setBtnLoad(true)
  dispatch(Clear_Genre_States())
  setConfirmAlerte(false)
  setErrorAlerte(false)
  var genreUpdate = {
    titre: updateGenre
  }
  //console.log(genreUpdate)
  dispatch(AppsGenres_Update(GENRES_UPDATE(updateId), session.token, genreUpdate))
  setBtnLoad(false)
  setUpdateModal(false)
 }

  return (
<Fragment>
<Breadcrumb title='Bibliothèque' submenu ='Genres'/>
    <div className="content">
    <div className="container">
    <div className="row">
    <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
    {/*Sidebar */}
    <StickyBox offsetTop={20} offsetBottom={20}>
    <Sidebar/>
    </StickyBox>                
    {/*Sidebar */}
    </div>
    {/*application */}
    <div className="col-md-7 col-lg-8 col-xl-9">
    <div className="row app_items">
    
         {/*header content */}
        <div className="col-12 col-sm-4 col-md-6">
            <h4 className="htitle">LISTE DES GENRES LITTERAIRES</h4>
        </div>
        <div className="col-12 col-sm-8 col-md-6 text-sm-right">
            <button className="btn btn-primary submit-btn" onClick={toggle}>
            <i className="ri-add-circle-fill"/> Ajouter un genre littéraire
            </button>                
        </div>
    
        {/*fin header content */}     
        
 {/*Content Tab */}    
    <div className="row contentTab">
      <div className="col-12 col-md-12"> 
        <div className="appointment-tab">
         <div className="tab-content">

            <div className="tab-pane show active" id="upcoming-appointments">
            <div className="card card-table mb-0">
            <div className="card-body"> 
            <div className="table-responsive">
              {/*Content Tab liste */}
              {!isAppLoading && (
                notDatas ? Alerte_Warning(genre.message) : <TabListeGenre genres={genre} DeleteToggle={DeleteToggle} UpdateToggle={UpdateToggle}/>
                )
              }
              {/*Content Tab liste */}
                  
            </div>
            </div>
            </div>
            </div>
         </div>
        </div>
       </div>
      </div>
{/* Fin Content Tab */} 
   
    </div></div>
    {/*fin app */}
    {/*Content Modal Add */}
    <div>
    <Modal isOpen={modal} toggle={toggle} fullscreen>
      <ModalHeader toggle={toggle} close={closeBtn}>
        Ajout de genre littéraire
      </ModalHeader>
      <Form>
      <ModalBody>
      <FormGroup>
    <Input onChange={(e)=> setTitreGenre(e.target.value)}
      name="titreGenre"
      placeholder="Titre"
      type="texte" required/>
      </FormGroup>
      </ModalBody>
      <ModalFooter>
        {stateGenres.isColl_Loading ? ( SimpleSpinner() ):
        <div className='modalf_width'>
        <Button color="primary" onClick={addGenre}>
          Ajouter
        </Button>
        <Button color="secondary" onClick={ModalClose}>
          Annuler
        </Button> 
        </div>}
        </ModalFooter>
        </Form>
      </Modal>
      {/*Delete modal*/}
      <Modal isOpen={deleteModal} toggle={DeleteToggle} className="modal-close">
        <ModalHeader toggle={DeleteToggle} close={DeleteCloseBtn}>&nbsp; Suppression</ModalHeader>
        <ModalBody>
        <i className="ri-close-circle-line"></i> Voulez-vous vraiment supprimer cet élément ? 
        </ModalBody>
        <ModalFooter>
        <div className='modalf_width'>
          {btnLoad ? SimpleSpinner():
            <>
            <Button color="primary" onClick={(e)=>deleteGenre()}> Confirmer la suppression</Button>
            <Button color="secondary" onClick={()=>setDeleteModal(false)}>Annuler</Button>
            </>
          }
        </div>
        </ModalFooter>
      </Modal>

      {/*Update modal*/}
      <Modal isOpen={updateModal} toggle={UpdateToggle} fullscreen>
      <ModalHeader toggle={UpdateToggle} close={UpdateCloseBtn}>
      Modifier la genre
      </ModalHeader>
      <Form>
      <ModalBody>
      <FormGroup>
    <Input onChange={(e)=> setUpdateGenre(e.target.value)}
      name="titreGenre"
      placeholder="Titre de la genre"
      type="texte" value={updateGenre} required/>
      </FormGroup>
      </ModalBody>
      <ModalFooter>
        { btnLoad ? ( SimpleSpinner() ):
        <div className='modalf_width'>
        <Button color="primary" onClick={updateGenreOn}>
          Modifier
        </Button>
        <Button color="secondary" onClick={()=>setUpdateModal(false)}>
          Annuler
        </Button> 
        </div>}
        </ModalFooter>
        </Form>
      </Modal>
    </div>
    {/*Fin Content Modal */}
    </div>
</div>
</div>
{/*Alertes*/}
{stateGenres.add_G_success && AlertSuccess('Genre enregistré')}
{stateGenres.delete_genre && AlertSuccess('Genre supprimé !')}
{stateGenres.update_G_success && AlertSuccess('Mise à jour éffectuée !')}
{stateGenres.add_G_error && AlertError(stateGenres.genre_err_msg)}
{stateGenres.update_G_error && AlertError('Echec de la mise à jour !')}
</Fragment>
  )
}

export default Genres