
import { 
    UPDATE_USER_PROFILE,
    UP_USER_LOADING, 
    UPDATE_ERROR,
    GET_USER_PROFILE
} from './Constants';

const initialState = {
    isUploading: false,
    updateSucces: false,
    updateError: false,
    callback: '',
    nom: '',
    prenoms:'',
    contact:'',
    profession:'',
    residence:'',
    genre:'',
    date_naissance:'',
    fileUrl: null
}

const getFile = file =>{
let userprofile = file;
if(file === null){
userprofile = '/digitalressources/user.png'
}
return {
    fileUrl: userprofile
}
}

export const UserDataSettings = (state = initialState, action) =>{
    switch (action.type) {
    case UP_USER_LOADING:
        return{
            ...state,
            isUploading: true
        }
    case UPDATE_ERROR:
        return{
            ...state,
            isUploading: false,
            updateError: true,
            callback: action.payload
        }
    case UPDATE_USER_PROFILE:
        return {
            ...state,
            isUploading: false,
            updateSucces: true
        }
        case GET_USER_PROFILE:
            //const file = getFile(action.payload.fileUrl)
            return {
                ...state,
                nom: action.payload.nom,
                prenoms: action.payload.prenoms,
                contact: action.payload.contact,
                profession: action.payload.profession,
                fileUrl: action.payload.fileUrl
            }
    //Defaul value
    default:
        return state
    
    }
}