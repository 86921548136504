import React, { Fragment } from 'react';
import TopBar from './header/TopBar';


const PlanDocs = () => {
document.title='Votre bibliothèque numérique interne - plan de classement'
return (
<Fragment>
<TopBar/>


<div className="col-8 col-md-8 section-heading text-center" style={{margin:'auto'}}>

<ul className="list-group">
  <li className="list-group-item list-group-item-primary">
  Généralités - Sciences et connaissance, informatique, information, 
  documentation, bibliothéconomie. institutions, publications
  
  </li>
  <li className="list-group-item">
   Prolégomènes, fondements de la connaissance et de la culture
  </li>
  <li className="list-group-item">
   Bibliographie(s), catalogues
  </li>
  <li className="list-group-item">
  Bibliothéconomie. Lecture.
  </li>
  <li className="list-group-item">
  Ouvrages généraux de référence (en tant que sujet). 
  </li>
  <li className="list-group-item">
  Publications en série, périodiques, revues 
  </li>
  <li className="list-group-item">
  Organisations en général (inclus associations et musées). Publications de collectivités
  </li>
  <li className="list-group-item">
   Journaux. Presse. Journalisme.
</li>
<li className="list-group-item">
Polygraphies. Œuvres collectives
</li>
<li className="list-group-item">
 Manuscrits. Livres rares et précieux
</li>
</ul>

{/**fin UL */}

<ul className="list-group">
  <li className="list-group-item list-group-item-primary">
  Philosophie et psychologie
</li>
<li className="list-group-item">
Métaphysique
</li>
<li className="list-group-item">
Philosophie de l’esprit. Métaphysique de la vie spirituelle
</li>
<li className="list-group-item">
Systèmes et approches philosophiques
</li>
<li className="list-group-item">
Psychologie, sciences cognitives, psychopathologie
</li>
<li className="list-group-item">
Logique. Épistémologie. Théorie de la connaissance. Méthodologie de la logique
</li>
<li className="list-group-item">
Philosophie morale. Éthique. Philosophie pratique
</li>

</ul>
{/**fin UL */}

<ul className="list-group">
  <li className="list-group-item list-group-item-primary">
  Religion, théologie
  </li>
  <li className="list-group-item">
  Indices auxiliaires spéciaux à la classe religion
</li>
<li className="list-group-item">
Religions préhistoriques et primitives
</li>
<li className="list-group-item">
Religions originaires d’Extrême-Orient
</li>
  <li className="list-group-item">
   Religions originaire du sous-continent indien. Hindouisme au sens large
  </li>
  <li className="list-group-item">
  Bouddhisme
  </li>
  <li className="list-group-item">
  Religions de l’Antiquité. Religions et cultes mineurs
  </li>
  <li className="list-group-item">
  Judaïsme
  </li>
  <li className="list-group-item">
  Christianisme. Église et dénominations chrétiennes
</li>
<li className="list-group-item">
Islam
</li>
<li className="list-group-item">
Mouvements spirituels modernes
</li>

</ul>
{/**fin UL */}

<ul className="list-group">
  <li className="list-group-item list-group-item-primary">
  Sciences sociales, Statistique. Économie. Commerce. Droit. Gouvernement. 
  Affaires militaires. Assistance sociale. Assurances. Éducation. 
  </li>
  <li className="list-group-item">
  Politique. Science politique
  </li>
  <li className="list-group-item">
  Économie. Science économique
  </li>
  <li className="list-group-item">
  Droit. Jurisprudence. Législation
  </li>
  <li className="list-group-item">
  Administration publique. Gouvernement. Affaires militaires
  </li>
  <li className="list-group-item">
  Assistance sociale. Prévoyance sociale. Protection des besoins vitaux matériels et mentaux
</li>

</ul>
{/**fin UL */}

<ul className="list-group">
  <li className="list-group-item list-group-item-primary">
  Sciences pures (Mathématiques, sciences exactes et naturelles)[
  </li>
  <li className="list-group-item">
  Mathématiques
  </li>
  <li className="list-group-item">
  Astronomie. Astrophysique. Recherche spatiale. Géodésie
  </li>
  <li className="list-group-item">
  Physique
  </li>
  <li className="list-group-item">
  Chimie. Minéralogie. Cristallographie
</li>
<li className="list-group-item">
Science de la Terre. Géologie
</li>
<li className="list-group-item">
Paléontologie. 
</li>
  <li className="list-group-item">
  Biologie. Sciences biologiques en général
  </li>
  <li className="list-group-item">
  Botanique
  </li>
  <li className="list-group-item">
  Zoologie
</li>

<li className="list-group-item">

</li>
</ul>
{/**fin UL */}

<ul className="list-group">
  <li className="list-group-item list-group-item-primary">
  Sciences appliquées. Médecine. Technologie
</li>
<li className="list-group-item">
Biotechnologie
</li>
<li className="list-group-item">
Sciences médicales. Médecine. Hygiène. Pharmacie
</li>
<li className="list-group-item">
Ingénierie. Technologie en général
</li>
<li className="list-group-item">
Agriculture, sciences et techniques relatives à l’agriculture. 
</li>
<li className="list-group-item">
Économie domestique. Sciences ménagères
</li>
<li className="list-group-item">
Industries du transport et de la communication. 
Comptabilité. Gestion d’entreprise. Relations publiques.
</li>
<li className="list-group-item">
Technologie de la chimie. Industries chimiques et apparentées
</li>
<li className="list-group-item">
Industries, commerces et artisanats divers
</li>
<li className="list-group-item">
Industries, artisanats et commerces de produits finis ou assemblés
</li>
<li className="list-group-item">
Industrie de la construction. Matériaux. Méthodes et procédés
</li>
</ul>


<ul className="list-group">
  <li className="list-group-item list-group-item-primary">
  Arts. Divertissements. Sports
</li>
<li className="list-group-item">
Aménagement du territoire. Urbanisme. Architecture paysagère. Paysages, parcs, jardins
</li>
<li className="list-group-item">
Architecture
</li>
<li className="list-group-item">
Arts plastiques
</li>
<li className="list-group-item">
Dessin. Dessins artistique. Design. Arts appliqués et métiers d’art
</li>
<li className="list-group-item">
Peinture
</li>
<li className="list-group-item">
Arts graphiques. Gravures
</li>
<li className="list-group-item">
Photographie et procédés connexes
</li>
<li className="list-group-item">
Musique
</li>
<li className="list-group-item">
Divertissement. Distractions. Jeux. Sports
</li>
</ul>

<ul className="list-group">
  <li className="list-group-item list-group-item-primary">
  Langue. Linguistique. Littérature
</li>
<li className="list-group-item">
Questions générales concernant la linguistique et la littérature. 
</li>
<li className="list-group-item">
Linguistique et langues
</li>
<li className="list-group-item">
Littérature
</li>

</ul>

<ul className="list-group">
  <li className="list-group-item list-group-item-primary">
  Géographie. Biographie. Histoire
</li>
<li className="list-group-item">
Archéologie. Préhistoire. Vestiges culturels. Études d’une zone
</li>
<li className="list-group-item">
Géographie. Exploration de la Terre et de pays particuliers. Géographie régionale
</li>
<li className="list-group-item">
Histoire
</li>
<li className="list-group-item">
Histoire générale
</li>

</ul>

</div>

</Fragment>
)
}

export default PlanDocs