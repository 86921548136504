import React, { useEffect, useState } from 'react'

const ItemsPagination = (props) => {
    const{count, page, limite, onNextClick, text} = props
    const [currentPage, setCurrentPage] = useState(page);
    const[maxPage, setMaxPage] = useState()
    //const {isAppLoading, fetchDatas} = useAxiosToGet(COUNT_ENUM_DOCS(), token);
    
    useEffect(()=>{
        if(count !== 0){
           /* for ( let i = 1; i <= Math.ceil( count / limite); i++){
                   pages.push(i);
               }*/
            setMaxPage(Math.ceil( count / limite));
        }
      
    }, [count])
   
    
  return  (
    <div className='items_pagination'>
        
        {currentPage <= maxPage ? (
            <ul className="pagination pagination-sm mb-0">
            <li className="page-item disabled">
                <a className="page-link" href="#">Page </a> 
            </li>
            <li className ='page-item active'>
               <a className="page-link" href="#">{currentPage}</a>
            </li>
            <li className="page-item disabled">
                <a className="page-link" href="#">de {maxPage} </a> 
            </li>
            <li className= {currentPage > 1 ? "page-item" : "page-item disabled"}>
                <a className="page-link" href={onNextClick(parseInt(page) - 1)}>Précédent</a>
            </li>
           
            <li className={currentPage < maxPage ? "page-item" : "page-item disabled"} style={{'marginRight': '20px'}}>
                <a className="page-link" href={onNextClick(parseInt(page)+1)}>Suivant</a>
            </li>
            
                     {count} {text}
            
            </ul>
        ): ( <ul className="pagination pagination-sm mb-0"> 
            <li className="page-item disabled">
                <a className="page-link" href="#">Précédent</a>
            </li>
            <li className="page-item disabled">
                <a className="page-link" href="#">Suivant</a>
            </li>
            
        </ul>)}

    </div>
  )
}

export default ItemsPagination