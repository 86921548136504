import React from 'react'
import { Link } from 'react-router-dom'

const Breadcrumb = (props) => {
    const {title, submenu} = props;
  return (
    <div className="breadcrumb-bar">
        <div className="container-fluid"><div className="row align-items-center"><div className="col-md-12 col-12">
        <nav aria-label="breadcrumb" className="page-breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/apps">Apps</Link></li>
                <li className="breadcrumb-item active" aria-current="page">{title}</li>
            </ol>
        </nav>
            <h2 className="breadcrumb-title">{submenu}</h2>
        </div>
        </div>
        </div>
    </div>
  )
}

export default Breadcrumb