
export const AxiosPostDatas = (Url, Token, Datas) => {
    const config = {
    method: 'POST',
    url: Url,
    headers: { 
    'Authorization': 'Bearer '+Token
    },
    data: Datas
    }

return config;
            
}