import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const GroupeItems = props => {

const {Img_C1, Img_C2, Img_C3, Img_C4, Img_C5} = props;

  return (
    <Fragment>
            {/* Books Collections */}	
            <section className="collection-section">
               
                <div className="container">
                    <div className="row">
                        <div className="collection-row">
                            <div className="collections">
                                <div className="collection-img">
                                    <Link to="/search/entitys/1/null/null/document"><img src={Img_C1} alt="" className="img-fluid w-100" /></Link>
                                </div>
                                <div className="collection-body text-center">
                                    <Link className='lien' to="/search/entitys/1/null/null/document"><h2>Documents</h2></Link>
                                    <button className="btn btn-secondary">
                                    <Link style={{color:"white"}} to="/search/entitys/1/null/null/document">Accès</Link></button>
                                </div>
                            </div>
                        </div>
                        <div className="collection-row">
                            <div className="collections">
                                <div className="collection-img">
                                    <Link to="/search/entitys/1/null/null/livre"><img src={Img_C2} alt="" className="img-fluid w-100" /></Link>
                                </div>
                                <div className="collection-body text-center">
                                    <Link className='lien' to="/search/entitys/1/null/null/livre"><h2>Livres</h2></Link>
                                    <button className="btn btn-secondary">
                                    <Link style={{color:"white"}} to="/search/entitys/1/null/null/livre">Accès</Link></button>
                                </div>
                            </div>
                        </div>
                        <div className="collection-row">
                            <div className="collections">
                                <div className="collection-img">
                                    <Link to="/search/entitys/1/1/null/null"><img src={Img_C3} alt="" className="img-fluid w-100" /></Link>
                                </div>
                                <div className="collection-body text-center">
                                    <Link className='lien' to="/search/entitys/1/1/null/null"><h2>Informatique</h2></Link>
                                    <button className="btn btn-secondary">
                                    <Link style={{color:"white"}} to="/search/entitys/1/1/null/null">Accès </Link></button>
                                </div>
                            </div>
                        </div>
                        <div className="collection-row">
                            <div className="collections">
                                <div className="collection-img">
                                    <Link to="/search/entitys/1/5/null/null"><img src={Img_C4} alt="" className="img-fluid w-100" /></Link>
                                </div>
                                <div className="collection-body text-center">
                                    <Link className='lien' to="/search/entitys/1/5/null/null"><h2>Etudes et productions</h2></Link>
                                    <button className="btn btn-secondary">
                                    <Link style={{color:"white"}} to="/search/entitys/1/5/null/null">Accès</Link></button>
                                </div>
                            </div>
                        </div>
                        <div className="collection-row">
                            <div className="collections">
                                <div className="collection-img">
                                    <Link to="/search/entitys/1/2/null/null"><img src={Img_C5} alt="" className="img-fluid w-100" /></Link>
                                </div>
                                <div className="collection-body text-center">
                                    <Link className='lien' to="/search/entitys/1/2/null/null"><h2>Technologies </h2></Link>
                                    <button className="btn btn-secondary">
                                    <Link style={{color:"white"}} to="/search/entitys/1/2/null/null">Accès</Link></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* /Books Collections */}
   

    </Fragment>
  )
}

export default GroupeItems;