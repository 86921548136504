
//custome hook global pour faire des requetes Get
import { useState, useEffect } from "react";
import axios from "axios";

const usePublicAxiosToGet = (urlApi)=> {
const [fetchDatas, setFetchDatas] = useState(null)
const [isAppLoading, setIsAppLoading] = useState(true)
     
    useEffect(()=>{
     const config = {
        methods: 'GET',
        url: urlApi
    }
    
    axios(config).then(res =>{ 
        setFetchDatas(res) 
        setIsAppLoading(false)})
    .catch(er =>{
        console.log(er)
        setFetchDatas(er.response)
        setIsAppLoading(false)
    })
    
    },[urlApi])

    return { isAppLoading, fetchDatas  };
}

export default usePublicAxiosToGet