import React from 'react'
import usePublicAxiosToGet from '../../fetchDatasAxios/FetchPublicGet'
import { GET_PUBLIC_CATS } from '../../auth/urlParameters'

const PublicSelectListeCat = (props) => {
    const {classification, setClassification} = props;
    const{isAppLoading, fetchDatas} = usePublicAxiosToGet(GET_PUBLIC_CATS())
    
  return !isAppLoading ? (
    fetchDatas.status === 400 ?( 
        <select name='classification' defaultValue={classification} className="form-control select" disabled required>
        <option value={null}>Pas de données disponibles</option>
        </select> 
        ) : fetchDatas.status === 200 ? (
        <select onChange={(e)=>setClassification(e.target.value)} defaultValue={classification} name='classification' className="form-control select" required>
        <option>Filtrer par classification</option>
        { fetchDatas.data.map((items) =><option key={items.id} value={items.id}>{items.nom}</option> )}
        </select> 
        )
        : (<select name='classification' defaultValue={classification} className="form-control select" required>
        <option value={null}>Pas de données disponibles</option>
        </select>)
      ) : ''
}

export default React.memo(PublicSelectListeCat) 