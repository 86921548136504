import React, { Fragment } from 'react'

const TabListeGenre = (props) => {
    const {genres, DeleteToggle, UpdateToggle} = props;
  return (
    <Fragment>
         <table className="table table-hover table-center mb-0">
            <thead>
                <tr className="table_tr_header">
                <th>N°</th>
                <th>Genres littéraires</th>
                <th></th>
                </tr>
            </thead>
            <tbody>
               
                {genres.map((items, index) =>{
                return(
                <tr key={items.id} className="table_tr_content">
                    <td> {index+1} </td>
                    <td> {items.titre} </td>
                    <td className="text-right">
                    <div className="table-action">
                    <button className="btn btn-sm bg-info-light mr-0" onClick={()=> UpdateToggle(items.id, items.titre)}>
                    <i className="far fa-eye" /> Modifier
                    </button>
                    <button className="btn btn-sm bg-danger-light" onClick={()=>DeleteToggle(items.id)}>
                    <i className="fas fa-times" /> Supprimer
                    </button>
                    </div>
                    </td>
                </tr>
                )
            })}
            
            </tbody>
        </table>
        
    </Fragment>
  )
}

export default TabListeGenre