import React, { Fragment, useEffect, useState} from 'react';
import StickyBox from 'react-sticky-box';
import { DefaultSpinner, OverlaySpinner } from '../../../ui/Spinner';
import {  ToastConfirm, ToastError } from '../../../ui/AlertesUi';

import Breadcrumb from '../../in/Breadcrumb';
import HomeSideBar from './HomeSideBar';

//Fetch Urls
import { USER_PROFILE_FETCH, POST_PROFILE } from '../../auth/urlParameters';

//Fetching
import { useSelector, useDispatch } from 'react-redux';
import useAxiosToGet from '../../fetchDatasAxios/FetchGet';
import { UpdateUserProfile } from '../../store/Profile/Dispatchs';

const UserSettings = () => {
    const dispatch = useDispatch()
    const { account, settings } = useSelector(state => ({
      account: state.sessionAuth,
      settings: state.userSettings
  }));

    const ArrayProfile = {
        nom: '',
        prenoms:'',
        contact:'',
        profession:'',
        residence:'',
        genre:'',
        date_naissance:''
    }
    const[profile, setProfile] = useState(ArrayProfile)
    const[openLoading, setOpenLoading] = useState(false)
    const [userFile, setUserFile] = useState(null)
    const[confirmAlerte, setConfirmAlerte] = useState(false)
    const[errorAlerte, setErrorAlerte] = useState(false)
    
    //get user profile from Fetch
    const {isAppLoading, fetchDatas} = useAxiosToGet(USER_PROFILE_FETCH(account.userId, account.sessionId), account.token);
    useEffect(()=>{
        
        if(fetchDatas !== null){
            
            let UserProfile = fetchDatas.data.profile
           
            setProfile({
                ...profile,
                ...UserProfile
            })
           
        }
    // eslint-disable-next-line 
    },[fetchDatas])

     //Alert success
  const AlertSuccess = (alerte) =>{
    if(confirmAlerte === false){
      ToastConfirm(alerte)
      setConfirmAlerte(true)
    }
  }
  //Error Alerte
  const AlertError = alerte =>{
    if(errorAlerte === false){
      ToastError(alerte)
      setErrorAlerte(true)
    }
  }
  
    //console.log(profile)
    const handleProfileChange = e =>{
        setProfile({
            ...profile,
            [e.target.name]: e.target.value
        })
        
    }
  
    //dispatch update
    const UserUpdateProfile = e =>{
        e.preventDefault();
        setConfirmAlerte(false)
        setErrorAlerte(false)
       // moment(profile.date_naissance).format('DD/MM/YYYY')
        var val = document.getElementById('updateForm')
        const formdata = new FormData(val)
        formdata.append('file', userFile)
        
        //post user profile
        dispatch(UpdateUserProfile(POST_PROFILE(account.userId, account.sessionId), account.token, formdata))
    }

   // const {ModalShow, ModalHide} = ActiveOverlay(settings.isUploading)
  
    
  return isAppLoading ? DefaultSpinner('Chargement...') : (
    <Fragment>
        <Breadcrumb title='Profile'/>
        <div className="content">
        <div className="container">
        <div className="row">
        <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
        <StickyBox offsetTop={20} offsetBottom={20}>
        <HomeSideBar/>
        </StickyBox>                
        </div>
        {/*application */}
        <div className="col-md-7 col-lg-8 col-xl-9">
        <div className="row">

            {/* Basic Information */}
            
            <div className="card">
                        <div className="card-body">
                          <h4 className="card-title">Vos informations</h4>
                          <form id="updateForm" onSubmit={UserUpdateProfile}>
                          <div className="row form-row">
                            
                            <div className="col-md-12">
                              <div className="form-group">
                                <div className="change-avatar">
                                 
                                </div>
                              </div>
                            </div>
                            
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Email <span className="text-danger">*</span></label>
                                <input name='email' type="text" className="form-control" readOnly 
                                value={account.email}/>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Mot de passe <span className="text-danger">*</span></label>
                                <input type="password" className="form-control" readOnly value='mot de passe'/>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Nom <span className="text-danger">*</span></label>
                                <input type="text" name = 'nom' className="form-control" 
                                value={profile.nom}
                                onChange = {handleProfileChange}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Prenoms <span className="text-danger">*</span></label>
                                <input type="text" name='prenoms' className="form-control" 
                                value={profile.prenoms}
                                onChange = {handleProfileChange}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Contact</label>
                                <input type="text" name='contact' className="form-control" 
                                value={profile.contact || 'Tel:'}
                                onChange = {handleProfileChange}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Profession</label>
                                <input type="text" name='profession' className="form-control" 
                                value={profile.profession || ''}
                                onChange = {handleProfileChange}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Résidence</label>
                                <input type="text" name='residence' className="form-control" 
                                value={profile.residence || ''}
                                onChange = {handleProfileChange}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Genre</label>
                                <select name='genre' className="form-control select"
                                onChange = {handleProfileChange} >
                                <option>{profile.genre||'Choix'}</option>
                                  <option>Autres</option>
                                  <option>Homme</option>
                                  <option>Femme</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group mb-0">
                                <label>Année de naissance</label>
                                <input type="date" name='date_naissance' className="form-control" 
                                value={profile.date_naissance||''}
                                onChange = {handleProfileChange}
                                />
                              </div>
                            </div>
                           
                          </div>
                          {/* /Registrations */}
                            <div style={{marginTop:'22px'}} className="submit-section submit-btn-bottom">
                                <button type="submit" className="btn btn-primary submit-btn">Enregistrer les modifications</button>
                                <div className="col-md-10" style={{'marginTop':'20px'}}>
                                {settings.updateSucces && AlertSuccess('Mise à jour éffectuée !')}
                                {settings.updateError && AlertError(settings.callback)}
                               
                                </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      
                      {/* /Basic Information */}
                     
                <OverlaySpinner ModalShow={settings.isUploading} 
                    ModalHide={()=>setOpenLoading(false)} 
                    txt = {'Enregistrement...'}
                    openLoading={openLoading}/> 

             </div>
        </div>
        </div>
        </div>
        </div>
    </Fragment>
  )
}

export default UserSettings