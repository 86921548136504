import{
    ADD_C_LOADING,
    ADD_C_ERROR, 
    ADD_C_SUCCESS,
    UPDATE_ERROR,
    UPDATE_SUCCESS,
    DELETE_COLLECTION,
    CLEAR_NOTIF_STATE
    } from './Constants'

    const initialState = {
        isColl_Loading: false,
        add_c_error: false,
        add_c_success: false,
        update_c_error: false,
        update_c_success: false,
        collection_err_msg: '',
        collection_success_msg: '',
        delete_collection: false
    }

    export const CollectionsReducers = (state = initialState, action)=>{
    switch (action.type) {
        case ADD_C_LOADING:
            return {
                ...state,
                isColl_Loading: true
            }
        case ADD_C_SUCCESS:
            return {
                ...state,
                isColl_Loading: false,
                add_c_success: true,
                delete_collection: false,
                collection_success_msg: action.payload
            }
        case ADD_C_ERROR:
            return {
                ...state,
                isColl_Loading: false,
                add_c_error: true,
                collection_err_msg: action.payload
            }
        case DELETE_COLLECTION:
            return {
                ...state,
                delete_collection: true,
                add_c_success: false
            }
        case UPDATE_SUCCESS:
            return{
                ...state,
                update_c_success:true,
                update_c_error:false,
                delete_collection: false,
                add_c_success: false
            }
        case UPDATE_ERROR:
            return{
                ...state,
                update_c_error:true
            }
        case CLEAR_NOTIF_STATE:
            return {
                ...state,
                update_c_success:false,
                update_c_error:false,
                delete_collection: false,
                add_c_error: false,
                add_c_success: false
            }
        default:
            return state;
    }
}

