import {
    CLEAR_STATES,
    CREATE_ENTITY_SUCCESS,
    CREATE_ENTITY_ERROR, 
    DELETE_ENTITY_SUCCESS,
    UPDATE_ENTITY_SUCCESS, 
    UPDATE_ENTITY_ERROR,
    DELETE_TAGS_CATS,
    DELETE_ENTITY_ERROR} from './Constants'

const initialState = {
    create_success: false,
    create_error: false,
    success_message: '',
    error_message: '',
    delete_success: false,
    delete_error: false,
    delete_success_message: '',
    delete_error_message: '',
    update_success: false,
    update_error: false,
    update_success_message: '',
    update_error_message: '',
    delete_tags_cats:false
}

export const EntitysGlobalFunctions = (state = initialState, action)=>{
    switch (action.type) {
        case CLEAR_STATES:
            return {
                ...state,
                create_success: false,
                create_error: false,
                success_message: '',
                error_message: '',
                delete_tags_cats:false,
                update_success: false,
                update_error: false
            }
        case CREATE_ENTITY_SUCCESS:
            return {
                ...state,
                create_success: true,
                success_message: action.payload,
                create_error: false
            }
        case CREATE_ENTITY_ERROR:
            return {
                ...state,
                create_success: false,
                create_error: true,
                error_message: action.payload,
            }
        case DELETE_ENTITY_SUCCESS:
            return {
                ...state,
                delete_success: true,
                delete_error: false,
                delete_success_message: action.payload
            }
        case DELETE_ENTITY_ERROR:
            return {
                ...state,
                delete_success: false,
                delete_error: true,
                delete_error_message: action.payload
            }
        case UPDATE_ENTITY_SUCCESS:
            return {
                ...state,
                update_success: true,
                update_error: false,
                update_success_message: action.payload
            }
        case UPDATE_ENTITY_ERROR:
            return {
                ...state,
                update_success: false,
                update_error: true,
                update_error_message: action.payload
            }
        case DELETE_TAGS_CATS:
            return {
                ...state,
                update_success: false,
                delete_tags_cats: true
            }
        
        default:
            return state;
    }
}