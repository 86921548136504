
import { LogoutUser } from './store/Accounts/Actions';

export const AppsLogout = () => {
    return Dispatch =>{
        Dispatch(LogoutUser())
    }

}

