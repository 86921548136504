import React, { Fragment, useEffect, useState } from 'react'
import StickyBox from 'react-sticky-box'
import Breadcrumb from '../Breadcrumb'
import Sidebar from '../Sidebar'
//fetch
import { useSelector, useDispatch } from 'react-redux'
import { AxiosGetDatas } from '../../fetchDatasAxios/FetchGetDatas'
import { AppsCollections_Add, AppsCollections_Delete, AppsCollections_Update } from '../../store/Collections/Dispatchs'
import { GET_ALL_COLLECTIONS, COLLECTIONS_ADDS, COLLECTIONS_DELETE, COLLECTIONS_UPDATE } from '../../auth/urlParameters'

import { Button, Modal, ModalHeader, ModalBody, ModalFooter,
FormGroup, Input, Label, Form } from 'reactstrap'
import TabListe from './TabListe'
import { SimpleSpinner } from '../../../ui/Spinner'
import {Alerte_Warning, ToastError, ToastConfirm} from '../../../ui/AlertesUi'
import axios from 'axios'
//clear state
import { Clear_Collection_States } from '../../store/Collections/Actions'


const Collections = () => {
  //STATE DU STORE
  const {session, stateCollections} = useSelector(state => ({
    session: state.sessionAuth,
    stateCollections: state.collections
  }));

  const dispatch = useDispatch();

  const initialState = [];
  const[isAppLoading, setIsAppLoading] = useState(true)
  const[collection, setCollections] = useState(initialState);
  const[notDatas, setNoDatas] = useState(false);
  const[disable, setDisable] = useState(0)
  const[btnLoad, setBtnLoad] = useState(false)
  const [modal, setModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [updateModal, setUpdateModal] = useState(false)
  //Update
  const [updateId, setUpdateId] = useState(null)
  const [updateCollection, setUpdateCollection] = useState('')
  const [updateIdParent, setUpdateIdParent] = useState(null)
  const [updateCollectionParent, setUpdateCollectionParent] = useState('')
  //Delete
  const [deleteId, setDeleteId] = useState(null)
  //collection
  const[confirmAlerte, setConfirmAlerte] = useState(false)
  const[errorAlerte, setErrorAlerte] = useState(false)
  const[titreCollection, setTitreCollection] = useState('')
  const[idParent, setIdParent] = useState(null)

  //fonction Calback de reccupération data collection
  const CollectionsGet = () => AxiosGetDatas(GET_ALL_COLLECTIONS(), session.token);

   useEffect(()=>{
    const ListeGet = CollectionsGet();
    axios(ListeGet).then(res =>{
    setCollections(res.data);
    setNoDatas(false);
    }
    ).catch(error =>{
      setCollections(error.response.data);
      setNoDatas(true);
      setDisable(1);
    })

    setIsAppLoading(false)
     // eslint-disable-next-line
   },[stateCollections.add_c_success, stateCollections.delete_collection, stateCollections.update_c_success])
  
   //close modal
   const ModalClose = () => setModal(false);
   const toggle = () => setModal(!modal);
   const DeleteToggle = (id) => {
    setDeleteModal(!deleteModal)
    setDeleteId(id)
   }
   //update collections
   const UpdateToggle = (id, titre, idParent) => {
    setUpdateModal(!updateModal)
    setUpdateId(id)
    setUpdateCollection(titre)
    setUpdateIdParent(idParent)
   }
   //Filtre titre parent
   //console.log ('idparent = '+updateIdParent)
   useEffect(()=>{
    setUpdateCollectionParent(getParentTitre(collection, updateIdParent))
    // eslint-disable-next-line
   },[updateIdParent]);

   const getParentTitre = (col, idparent) =>{
   if(idparent !== null){
    for (let i = 0; i < col.length; i++){
      if(col[i].id === idparent){
         return col[i].titre
      }
     }
   }
   else{return 'Aucun'}
  
   }

   const HandleChangeIdParent = (e) =>{
    const target = e.target.value;
    target === 'NULL' ? setIdParent(null) : setIdParent(parseInt(e.target.value)) 
   }
   const HandleChangeUpdateIdParent = (e) =>{
    const target = e.target.value;
    target === 'NULL' ? setUpdateIdParent(null) : setUpdateIdParent(parseInt(e.target.value)) 
   }
   
   //Btn
   const closeBtn = (
    <button className="close" onClick={ModalClose} type="button">
      &times;
    </button>
  )
  //Update close
  const UpdateCloseBtn = (
    <button className="close" onClick={()=>setUpdateModal(false)} type="button">
      &times;
    </button>
  )
  //delet close
  const DeleteCloseBtn = (
    <button className="close" onClick={()=>setDeleteModal(false)} type="button">
      &times;
    </button>
  )
  //Alert success
  const AlertSuccess = (alerte) =>{
    if(confirmAlerte === false){
      ToastConfirm(alerte)
      setConfirmAlerte(true)
    }
  }
  //Error Alerte
  const AlertError = alerte =>{
    if(errorAlerte === false){
      ToastError(alerte)
      setErrorAlerte(true)
    }
  }
  
 //dispatch collection
 const addCollection = (e)=>{
  e.preventDefault();
  const data = {
    titre: titreCollection,
    parent_id: idParent
  }
  
  dispatch(Clear_Collection_States())
  setConfirmAlerte(false)
  setErrorAlerte(false)
  dispatch(AppsCollections_Add(COLLECTIONS_ADDS(), session.token, data));
  setTitreCollection('')
  setModal(false)
 }
 //Delete
 
 const deleteCollection = () =>{
  dispatch(Clear_Collection_States())
  setConfirmAlerte(false)
  setErrorAlerte(false)
  setBtnLoad(true)
  dispatch(AppsCollections_Delete(COLLECTIONS_DELETE(deleteId), session.token))
  setBtnLoad(false)
  setDeleteModal(false)

 }
 //Update
 const updateCollectionOn = (e) =>{
  e.preventDefault()
  
  setBtnLoad(true)
  dispatch(Clear_Collection_States())
  setConfirmAlerte(false)
  setErrorAlerte(false)
  var collectionUpdate = {
    titre: updateCollection,
    parent_id: updateIdParent
  }
  //console.log(collectionUpdate)
  dispatch(AppsCollections_Update(COLLECTIONS_UPDATE(updateId), session.token, collectionUpdate))
  setBtnLoad(false)
  setUpdateModal(false)
 }

  return (
<Fragment>
<Breadcrumb title='Bibliothèque' submenu ='Collections'/>
    <div className="content">
    <div className="container">
    <div className="row">
    <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
    {/*Sidebar */}
    <StickyBox offsetTop={20} offsetBottom={20}>
    <Sidebar/>
    </StickyBox>                
    {/*Sidebar */}
    </div>
    {/*application */}
    <div className="col-md-7 col-lg-8 col-xl-9">
    <div className="row app_items">
    
         {/*header content */}
        <div className="col-12 col-sm-4 col-md-6">
            <h4 className="htitle">LISTE DES COLLECTIONS</h4>
        </div>
        <div className="col-12 col-sm-8 col-md-6 text-sm-right">
            <button className="btn btn-primary submit-btn" onClick={toggle}>
            <i className="ri-add-circle-fill"/> Enregistrer une collection
            </button>                
        </div>
    
        {/*fin header content */}     
        
 {/*Content Tab */}    
    <div className="row contentTab">
      <div className="col-12 col-md-12"> 
        <div className="appointment-tab">
         <div className="tab-content">

            <div className="tab-pane show active" id="upcoming-appointments">
            <div className="card card-table mb-0">
            <div className="card-body"> 
            <div className="table-responsive">
              {/*Content Tab liste */}
              {!isAppLoading && (
                notDatas ? Alerte_Warning(collection.message) : <TabListe collections={collection} DeleteToggle={DeleteToggle} UpdateToggle={UpdateToggle}/>
                )
              }
              {/*Content Tab liste */}
                  
            </div>
            </div>
            </div>
            </div>
         </div>
        </div>
       </div>
      </div>
{/* Fin Content Tab */} 
   
    </div></div>
    {/*fin app */}
    {/*Content Modal Add */}
    <div>
    <Modal isOpen={modal} toggle={toggle} fullscreen>
      <ModalHeader toggle={toggle} close={closeBtn}>
        Enregistrement de collection
      </ModalHeader>
      <Form>
      <ModalBody>
      <FormGroup>
    <Input onChange={(e)=> setTitreCollection(e.target.value)}
      name="titreCollection"
      placeholder="Titre de la collection"
      type="texte" required/>
      </FormGroup>
      <FormGroup>
      <Label for="exampleSelect"> Collection parent </Label>
      <Input id="collectionSelect" name="select" type="select" disabled={disable}
      onChange={HandleChangeIdParent}>
      {
      notDatas ? <option value={"NULL"}>Aucun</option> :
      (<>
      <option value="NULL">Aucun</option> 
      {collection.map(items => <option key={items.id} value={items.id}>{items.titre}</option>)}
      </>)
      }
    </Input>
      </FormGroup>
      </ModalBody>
      <ModalFooter>
        {stateCollections.isColl_Loading ? ( SimpleSpinner() ):
        <div className='modalf_width'>
        <Button color="primary" onClick={addCollection}>
          Enregistrer
        </Button>
        <Button color="secondary" onClick={ModalClose}>
          Annuler
        </Button> 
        </div>}
        </ModalFooter>
        </Form>
      </Modal>
      {/*Delete modal*/}
      <Modal isOpen={deleteModal} toggle={DeleteToggle} className="modal-close">
        <ModalHeader toggle={DeleteToggle} close={DeleteCloseBtn}>&nbsp; Suppression</ModalHeader>
        <ModalBody>
        <i className="ri-close-circle-line"></i> Voulez-vous vraiment supprimer cet élément ? 
        </ModalBody>
        <ModalFooter>
        <div className='modalf_width'>
          {btnLoad ? SimpleSpinner():
            <>
            <Button color="primary" onClick={(e)=>deleteCollection()}> Confirmer la suppression</Button>
            <Button color="secondary" onClick={()=>setDeleteModal(false)}>Annuler</Button>
            </>
          }
        </div>
        </ModalFooter>
      </Modal>

      {/*Update modal*/}
      <Modal isOpen={updateModal} toggle={UpdateToggle} fullscreen>
      <ModalHeader toggle={UpdateToggle} close={UpdateCloseBtn}>
      Modifier la collection
      </ModalHeader>
      <Form>
      <ModalBody>
      <FormGroup>
    <Input onChange={(e)=> setUpdateCollection(e.target.value)}
      name="titreCollection"
      placeholder="Titre de la collection"
      type="texte" value={updateCollection} required/>
      </FormGroup>
      <FormGroup>
      <Label for="exampleSelect"> Collection parent </Label>
      <Input id="collectionSelect" name="select" type="select"
      onChange={HandleChangeUpdateIdParent}>

      <option value={updateIdParent}>{updateCollectionParent}</option>
      {
      notDatas ? <option value={"NULL"}>Aucun</option> :
      (<>
      <option value="NULL">Aucun</option> 
      {collection.map(items => <option key={items.id} value={items.id}>{items.titre}</option>)}
      </>)
      }
    </Input>
      </FormGroup>
      </ModalBody>
      <ModalFooter>
        { btnLoad ? ( SimpleSpinner() ):
        <div className='modalf_width'>
        <Button color="primary" onClick={updateCollectionOn}>
          Modifier
        </Button>
        <Button color="secondary" onClick={()=>setUpdateModal(false)}>
          Annuler
        </Button> 
        </div>}
        </ModalFooter>
        </Form>
      </Modal>
    </div>
    {/*Fin Content Modal */}
    </div>
</div>
</div>
{/*Alertes*/}
{stateCollections.add_c_success && AlertSuccess('Collection enregistrée')}
{stateCollections.delete_collection && AlertSuccess('Collection supprimée !')}
{stateCollections.update_c_success && AlertSuccess('Mise à jour éffectuée !')}
{stateCollections.add_c_error && AlertError(stateCollections.collection_err_msg)}
{stateCollections.update_c_error && AlertError('Echec de la mise à jour !')}
</Fragment>
  )
}

export default Collections