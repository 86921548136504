import React, { Fragment, useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import StickyBox from 'react-sticky-box';

import ItemsPagination from '../../in/items/ItemsPagination';
import PublicSelectListeCol from '../../in/collections/PublicSelectListe';
import PublicSelectListeCat from '../../in/classifications/PublicSelectListe';
import { SimpleSpinner } from '../../../ui/Spinner';
import { AxiosGetPublicDatas } from '../../fetchDatasAxios/FetchGetDatas';
import axios from 'axios';
import { useParams } from 'react-router-dom'

import { APP_SEARCH } from '../../auth/urlParameters';
import SearchItemsListe from './SearchItemsListe';
import SearchTags from './SearchTags';

const SearchApps = () => {

  const params = useParams();

  const [searchResults, setSearchResults] = useState([])
  const [tags, setTags] = useState([])
  const [count, setCount] = useState(0)

  const initialState ={
    search_text: params.search_text,
    page : params.page,
    col: params.collections,
    type: params.type
  }

  const [date, setDate] = useState(null)
  const [type, setType] = useState(initialState.type)
  const [textFilter, setTextFilter] = useState(initialState.search_text)

  const [collection, setCollection] = useState(initialState.col);
  const [classification, setClassification] = useState(null);

  const[isAppLoading, setIsAppLoading] = useState(true)
  const [NoSearchResults, setNoSearchResults] = useState(false)

  //Obtenir les entités avec page et filtres
  const CreateQuery = () => AxiosGetPublicDatas(APP_SEARCH(initialState.page, textFilter, collection, type, classification, date));
  //get datas from typsens
  useEffect(()=>{
    
    let config = CreateQuery();

    axios(config).then(response =>{
     //console.log(response.data)
     if(response.data.found === 0){
      setNoSearchResults(true)
    }
      setCount(response.data.found)
      setSearchResults(response.data.results)
      setTags(response.data.facetCounts[0].counts)
     
    }).catch(error =>{
      setSearchResults([])
      setNoSearchResults(true)
    })
    
    setIsAppLoading(false)
    //demontage 
    return function(){
      config = CreateQuery();
      setNoSearchResults(false)
    }
  // eslint-disable-next-line 
  }, [textFilter, type, collection, classification ])


  const onNextClick = (page, col, type, search_text)=>{
    return `/search/entitys/${page}/${col}/${type}/${search_text}`
  }
  
   //Handlechange filtre
   const HandleChangeFilter = (e) => {
     setTextFilter(e.target.value);
   }
   
   //console.log(date)

  return (
    <Fragment>
      <div className="breadcrumb-bar">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-3 col-12">
            <nav aria-label="breadcrumb" className="page-breadcrumb">
            <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/">Apps</Link></li>
            <li className="breadcrumb-item active" aria-current="page">RECHERCHE AVANCEE</li>
            </ol>
            </nav>
            
           </div>

          {/** Search */}
          <div className="col-md-6 col-12">
            <div className="col">
            
            <div id="search" className="search form-icon right">
                <input onChange={HandleChangeFilter} type="text" className="form-control form-control-icon" id="iconInput" placeholder="Recherche dans la base de données documentaire..."/>
                <i className="ri-search-2-line"></i>
            </div>
            </div>
           

          </div>

          </div>
        </div>
    </div>

    <div className="content">
    <div className="container">
        <div className="row">
        <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
        {/*Sidebar */}
        <StickyBox offsetTop={20} offsetBottom={20}>
        {/* Search Filter */}
        <div className="card search-filter">
                      <div className="card-header">
                    <h4 className="card-title mb-0">FILTRES</h4>
                  </div>
                  <div className="card-body">
                    {/*<div className="filter-widget">
                      <div>
                        <input type="date" onChange={e=>setDate(e.target.value)} className="form-control datetimepicker" placeholder="Select Date" />
                      </div>			
                    </div> */}
                    <div className="filter-widget">
                      <h4>SELECTIONS</h4>
                      <div className="col">
                          <div className="form-group">
                          {<PublicSelectListeCol collection={collection} setCollection={setCollection} />}
                          </div>
                          </div>
                          <div className="col">
                          <div className="form-group">
                          {<PublicSelectListeCat classification={classification} setClassification={setClassification} />}
                          </div>
                          </div>

                      <h4 className="card-title mb-0">SUJETS</h4>  
                    </div>
                    <div className="card-header">
                    <div>
                       <SearchTags Tags={tags} setChange={setTextFilter}/>
                      </div>
                      
                  </div>
                   	
                  </div>
                </div>
                {/* /Search Filter */}
    </StickyBox>                
    {/*Sidebar */}
    </div>
    {/*application */}
    <div className="col-md-7 col-lg-8 col-xl-9">
    <div className="row app_items">

    {/*header content */}
      <div className="col-12 col-sm-4 col-md-6">
      <h4 className="htitle">RESULTAT DE RECHERCHE</h4>
      </div>
      <div className="col-12 col-sm-8 col-md-6 text-sm-right">
                         
      </div>
    {/*fin header content */}

           {/*Content Tab */}    
    <div className="row contentTab">
      <div className="col-12 col-md-12"> 
        <div className="appointment-tab">
         <div className="tab-content">

          
         <div className='col-12 entity_bottom_header'>
         {<ItemsPagination count={count} page={params.page} limite={200} onNextClick={onNextClick} text='Items'/>}
         
        </div>

    {/* / Tab */}

    <div className='search_items row'>   
          {!!isAppLoading ? (
            <div className='col-12 item simpleSpinner'>
            {SimpleSpinner()}
            <span>Chargement...</span>
            </div>
          ): NoSearchResults ? (
            <div className='col-12 item simpleSpinner'>
            <div className="alert alert-info" role="alert">
              Aucune donnée disponible...
            </div></div>
            ):( searchResults.map((items, index) => <SearchItemsListe Entity = {items} key={index}/> ) )
          
          }

    </div>
    
    {/* // fin Tab */}

</div>
</div>
</div>
</div>

</div>
</div>
{/*fin app */}
</div>
</div>
</div>


    </Fragment>
  )
}

export default SearchApps