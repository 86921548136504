import React from 'react'
import { Link } from 'react-router-dom'
import usePublicAxiosToGet from '../../fetchDatasAxios/FetchPublicGet'
import { GET_PUBLIC_COLLECTIONS } from '../../auth/urlParameters'

const DropdownListe = () => {
    //const state = useSelector(state => state.sessionAuth)
    const{isAppLoading, fetchDatas} = usePublicAxiosToGet(GET_PUBLIC_COLLECTIONS())
    
  return !isAppLoading ? (
    fetchDatas.status === 400 ?( 
        <Link className='valeur' to="/"> LISTE DES CATEGORIES </Link>  
    ) : fetchDatas.status === 200 ? (
    
     fetchDatas.data.map((items) => <Link key={items.id} className='valeur' 
     to={`/search/entitys/1/${items.id}/null/null`}> {items.titre} </Link> )
    
    )
    : (<Link className='valeur' to="/"> LISTE DES CATEGORIES </Link>)
    
    ) : ''
}

export default React.memo(DropdownListe) 