import { useState, useEffect} from 'react'
import { Outlet} from 'react-router-dom'
import { useSelector, useDispatch} from 'react-redux'
import useAxiosToGet from './fetchDatasAxios/FetchGet'
import { Apps_User_Profile } from './store/Profile/Actions'
import axios from 'axios'
import{DefaultSpinner} from '../ui/Spinner'

import { SERVER_HOST } from './auth/Configs'
import { toast } from 'react-toastify';

import '../../assets/css/customAdmin.css'
const Apps = () => {
    const state = useSelector(state => state.sessionAuth)
    const dispatch = useDispatch()
    
    const Api = SERVER_HOST
    const [current_T_isExpire, setCurrent_T_isExpire] = useState(false)
    const {isAppLoading, fetchDatas} = useAxiosToGet(`${Api}/user/token_t`, state.token)

    const AuthExpireTk = ()=>{
        sessionStorage.removeItem('currentUser')
        const connectLnk = ()=> {
        return (<div>Vous devez vous reconnecter. La session a expiré...
        <a href='/login/?sessionExpired'> SE RECONNECTER</a></div>)
    }
        return( 
            <div style={{ 'marginTop': '5px', 'marginBottom': '30%'}} className="row justify-content-center">
            <div className="col-lg-12" style={{'display': 'none'}}>
            {toast.error(connectLnk, {
                position: "top-center",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                })}
                </div>
                </div>
            )
    }

    useEffect(()=>{
        if(state.token === null){
            setCurrent_T_isExpire(true)
        }
        if(fetchDatas !== null){
            
            if(fetchDatas.status === 401 ){
                setCurrent_T_isExpire(true)
            } 
                //PROFILE DATA
            if(fetchDatas.status === 200){
            let options = {
            methods: 'GET', url: `${Api}/user/${state.userId}/${state.sessionId}`,
            headers:{
                'Authorization': 'Bearer '+state.token
            }
        }
        axios(options).then(response =>{ dispatch(Apps_User_Profile(response.data.profile))}).catch(er=>{console.log('')});
        }
             
        }
        return ()=>{console.log('object')}
        // eslint-disable-next-line
    }, [fetchDatas, state.token])
    

        return  isAppLoading ? DefaultSpinner('Chargement...') : current_T_isExpire ?
                AuthExpireTk() : <Outlet/>
            

}

export default Apps;
