import React from 'react'
import useAxiosToGet from '../../fetchDatasAxios/FetchGet'
import { GET_ALL_CAT } from '../../auth/urlParameters'

const SelectListeCat = (props) => {
    const {classification, token, setClassification, text} = props;
    const{isAppLoading, fetchDatas} = useAxiosToGet(GET_ALL_CAT(), token)
    
  return !isAppLoading ? (
    fetchDatas.status === 400 ?( 
    <select name='classification' defaultValue={classification} className="form-control select" disabled required>
    <option value={null}>Vous devez obligatoirement définir la classification</option>
    </select> 
    ) : fetchDatas.status === 200 ? (
    <select name='classification' defaultValue={classification} className="form-control select" required onChange={(e)=>setClassification(e.target.value)}>
    <option>{text}</option>
    { fetchDatas.data.map((cat) => <option key={cat.id} value={cat.id}>{cat.nom}</option> )}
    </select> 
    )
    : (<select name='classification' defaultValue={classification} className="form-control select" required>
    <option value={null}>Selection de la classification</option>
    </select>)
    
    ) : ''
}

export default React.memo(SelectListeCat) 