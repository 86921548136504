import React, { Fragment } from 'react'

const Contacts = () => {
  return (
    <Fragment>
        <div className="content" style={{'height':'680px'}}>
        <div className="container-fluid">
        <div className="row">
        <div className="account-content">
        <div className="col-8 col-md-8 section-heading text-center" style={{margin:'auto'}}>
              <ul>
              <li className="list-group-item">
                SYIDOCS est un produit de focus INT, immatriculation RCCM N°OUA2021A7709 - Maison de l'entreprise du Burkina Faso
              </li>
              <li className="list-group-item list-group-item-primary">
                Support technique : support@syidocs.com
                <br/> Contact commercial : commercial@fcs-int.com
              </li>
              </ul>
        </div>
        </div>
        </div>
        </div>
        </div>
    </Fragment>
  )
}

export default Contacts