import React, { Fragment } from 'react';
import { AccountIcon } from '../../../imagepath';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const UsersLites = (props) => {
    
    const {user, DeleteToggle} = props;
    const state = useSelector(state => state.sessionAuth)
    const styleUser =   {
        'border':'1px solid#ededed',
        'marginRight':'5px',
        'marginLeft':'10px',
        'marginTop':'10px',
        'marginBottom':'12px',
        'borderRadius':'6px',
        'padding':'10px'
    }
    const navigate= useNavigate();
    const userRoles = (roles)=>{
        if(roles.includes('ROLE_ADMIN')){
            return 'Administrateur';
          }
          else if (!roles.includes('ROLE_ADMIN') && roles.includes('ROLE_EDITED')){
            return 'Editeur';
          }
          else if (!roles.includes('ROLE_EDITED') && roles.includes('ROLE_ASSIST')){
            return 'Assistant(e)';
          }
          else{
            return 'Utilisateur';
          }
    }
     
    const navUpdate = (user) => navigate(`/apps/in/users/${state.userId}/${state.sessionId}/${user}`)
    
  return (
    <Fragment>
        <div className="col-md-12">
        <div className='row'>
        {
            user.map((account) =>{
                const Roles = userRoles(account.roles)
                
                return(
                    <div className="col-md-12 col-lg-5"
                    style={ styleUser } key={account.id}>
                    <div className="appointment-list">
                   
                    <div className="profile-info-widget">
                    <img src={AccountIcon} alt="User" />
                    <div className="profile-det-info">
                    <h3>{account.profile.nom+ ' '+account.profile.prenoms}</h3>
                    <div className="receiver-details">
                   <h5> <i className="ri-shield-user-fill"></i> rôles : {Roles} </h5>
                   <h5> <i className="ri-check-line"></i> status : {account.etat}</h5>
                    </div>
                    </div>
                    </div>
                    <div className="appointment-action">
                    <button className="btn btn-sm bg-info-light" data-target="#appt_details" onClick={()=>navUpdate(account.id)}>
                    <i className="ri-edit-line"></i> Editer
                    </button>
                    {Roles === 'Administrateur' ? ( 
                    <button className="btn btn-sm bg-danger-light" disabled="1" onClick={()=>DeleteToggle(account)}>
                    <i className="fas fa-times" /> Supprimer
                    </button>): ( <button className="btn btn-sm bg-danger-light" onClick={()=>DeleteToggle(account)}>
                    <i className="fas fa-times" /> Supprimer
                    </button>)
                    }
                    </div>
                    </div>

                    </div>
                )
            })

        }
    </div></div>
    </Fragment>
  )
}

export default UsersLites