import React, { useRef, useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo-blanc.png';
import './header.css';

import { OverlaySpinner } from '../ui/Spinner';
import {AppsLogout}  from '../backend/AppsLogout';
import { useNavigate } from 'react-router-dom';
import { useDispatch , useSelector} from 'react-redux';
const HeaderAppHome = (props) => {
    //const pathname = window.location.pathname.split('/')[1]
    
  const mbMenu = useRef(null);
  const custmeStyle = {
        display:'none',  
        backgroundColor:'#ea0f4d',
       }
 
  const [linkStyle, setLinkStyle] = useState(custmeStyle);

  const LeftSidbar = ()=>{
    document.documentElement.setAttribute('class', 'menu-opened');
    mbMenu.current.classList.add("opened");
    setLinkStyle({
      ...linkStyle,
      display:'block'
    })
  }
  const menuClose = ()=>{
    document.documentElement.removeAttribute('class', 'menu-opened');
    mbMenu.current.classList.remove("opened");
    setLinkStyle({
      ...linkStyle,
      display:'none'
    })
  }
const state = useSelector(state => state.sessionAuth)
const Dispatch = useDispatch()
const Navigate = useNavigate()
const [ModalShow, setModalShow] = useState(false)

const ModalHide = () => setModalShow(false)

const DispatchLogout = () =>{
    setModalShow(true)
    setTimeout(()=>{
        Dispatch(AppsLogout())
        ModalHide();
        Navigate('/login', {replace: true})
    },[3000])
    
}

 
  return (
    <Fragment>
    <header className=" header">
      <div ref={mbMenu} onClick={menuClose} className="sidebar-overlay"></div>
        <nav className="navbar navbar-expand-lg header-nav">
          <div className="navbar-header">
            <button className='btn-sidebar-menu' id="mobile_btn" onClick={LeftSidbar}>
              <span className="bar-icon">
                <span />
                <span />
                <span />
              </span>
            </button>
            <a href="/" className="navbar-brand logo">
              <img src={logo} className="img-fluid" alt="Logo" /> 
            </a>
          </div>
          <div className="main-menu-wrapper" style={{paddingRight:'20px'}}>
            <div className="menu-header">
              <a href="/" className="menu-logo">
                <img src={logo} className="img-fluid" alt="Logo" />
              </a>
              <button className='btn-sidebar-menu menu-close' 
              id="menu_close" onClick={menuClose}>
                <i className="fas fa-times" />
              </button>
            </div>
                <ul className="main-nav bg-trans">
                <li className='class'><a href="/">ACCUEIL</a></li>
                <li className='class'><a href ="/">BIBLIOTHEQUE NUMERIQUE</a></li>
                <li className='class'><Link to="/apropos">A PROPOS</Link></li>
                <li className='class'><Link to="/contacts">CONTACTS</Link></li>
                <li className="loginlink" style={linkStyle}><Link to="/logout">SE DECONNECTER</Link></li>
                </ul>
          </div>
          <ul className="nav admin header-navbar-rht align-items-center">
            {/* user account */}
            <li className="nav-item dropdown has-arrow logged-item">
              <a href="/" className="dropdown-toggle nav-link" data-toggle="dropdown" aria-expanded="false"><span className="user-img"><i className="ri-user-6-line"></i></span></a>
              <div className="dropdown-menu dropdown-menu-right">
                <div className="user-header">
                  <div className="avatar avatar-sm"><i className="ri-user-6-line"></i></div>
                  <div className="user-text">
                    <h6>{state.email}</h6>
                    
                  </div>
                </div>
                <Link className="dropdown-item" to={`/apps/in/dashboard/${state.sessionId}`}>Administration</Link>
                <Link className="dropdown-item" to="/apps/home/user">Profile</Link>
                <button onClick={DispatchLogout} className="dropdown-item btn btn-primary" data-toggle="modal" data-dismiss="modal" data-target="#logout">Se déconnecter</button>
                
              </div>
            </li>
  
          </ul>
        </nav>
      </header>
        <OverlaySpinner ModalShow={ModalShow} ModalHide={ModalHide} txt = {'Déconnexion...'}/>
      </Fragment>
  )
}

export default HeaderAppHome