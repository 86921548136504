import React, { useEffect, useState } from 'react'
import { APP_GET_SEARCH_RESS } from '../../auth/urlParameters';
import usePublicAxiosToGet from '../../fetchDatasAxios/FetchPublicGet';
import { SERVER_FILES } from '../../auth/Configs';
const SearchItemsListe = (props) => {
  const {Entity} = props;
  const [fichiers, setFichiers] = useState(null)
  const [cover, setCover] = useState('')

  const{isAppLoading, fetchDatas} = usePublicAxiosToGet(APP_GET_SEARCH_RESS(Entity.document.uuid));
  useEffect(()=>{
    if(fetchDatas !== null){
      setFichiers(fetchDatas.data[0].fichiers)
      setCover(fetchDatas.data[0].cover_image)
    }
  }, [fetchDatas])
  return (
                                
    <div className="listdocs col-lg-6">
        <div className="itemdocs d-flex">
        <div className="itemdocs-img">
        <a href={`/biblio/entitys/r/${Entity.document.uuid}/${Entity.document.id}/${Entity.document.type}/${fichiers}`}>
          { cover === null  ? <i className="ri-file-pdf-fill"></i> : <img src = {`${SERVER_FILES}/digitalressources/${cover}`} alt="cover"/>}
        </a>
        </div>
        <div className="itemdocs-content">
        <a href={`/biblio/entitys/r/${Entity.document.uuid}/${Entity.document.id}/${Entity.document.type}/${fichiers}`}>
            <h2> {Entity.document.titre} </h2>
        </a>
        <span> {/*'Date'*/} </span>
        </div>
        </div>
    </div>

  )
}

export default React.memo(SearchItemsListe) 