import{
    ADD_Cat_LOADING,
    ADD_Cat_ERROR, 
    ADD_Cat_SUCCESS,
    UPDATE_ERROR_Cat,
    UPDATE_SUCCESS_Cat,
    DELETE_Cat,
    CLEAR_NOTIF_STATE
    } from './Constants'

    const initialState = {
        isCat_Loading: false,
        add_Cat_error: false,
        add_Cat_success: false,
        update_Cat_error: false,
        update_Cat_success: false,
        Cat_err_msg: '',
        Cat_success_msg: '',
        delete_Cat: false
    }

    export const ClassificationsReducers = (state = initialState, action)=>{
    switch (action.type) {
        case ADD_Cat_LOADING:
            return {
                ...state,
                isCat_Loading: true
            }
        case ADD_Cat_SUCCESS:
            return {
                ...state,
                isCat_Loading: false,
                add_Cat_success: true,
                delete_Cat: false,
                Cat_success_msg: action.payload
            }
        case ADD_Cat_ERROR:
            return {
                ...state,
                isCat_Loading: false,
                add_Cat_error: true,
                Cat_err_msg: action.payload
            }
        case DELETE_Cat:
            return {
                ...state,
                delete_Cat: true,
                add_Cat_success: false
            }
        case UPDATE_SUCCESS_Cat:
            return{
                ...state,
                update_Cat_success:true,
                update_Cat_error:false,
                delete_Cat: false,
                add_Cat_success: false
            }
        case UPDATE_ERROR_Cat:
            return{
                ...state,
                update_Cat_error:true
            }
        case CLEAR_NOTIF_STATE:
            return {
                ...state,
                update_Cat_success:false,
                update_Cat_error:false,
                delete_Cat: false,
                add_Cat_error: false,
                add_Cat_success: false
            }
        default:
            return state;
    }
}

