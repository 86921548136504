import React from 'react';


const Footer = () => {
  var dt = new Date().getFullYear()
  return (
    <footer className="footer">

    {/* Footer Bottom */}
    <div className="footer-bottom">
      <div className="container">
        {/* Copyright */}
        <div className="copyright">
          <div className="row">
            <div className="col-md-5 col-12">
              <div className="copyright-text text-left">
               
              </div>
            </div>
            <div className="col-md-5 col-12">
            <div className="col-sm-6">
                {dt} © syidocs.com
            </div>
            </div>
            
          </div>
        </div>
        {/* /Copyright */}
      </div>
    </div>
    {/* /Footer Bottom */}
  </footer>

  )
}

export default Footer