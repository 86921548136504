import {
    Loading,
    LoadingSignup,
    UserLogin,
    UserLoginError,
    Account_Error_Register,
    Account_Register_Exists,
    Account_Register_Success

} from './Actions';

import {SERVER_HOST} from '../../auth/Configs';
import axios from 'axios';

const Api = SERVER_HOST;

export const GetUserToken = (user, navigate)=>{
   
    return dispatch =>{

        dispatch(Loading())
        //get user token
        axios.post(`${Api}/accounts/login`, user).then( response=> {

           const valideToken = response.data.token ;

            if(valideToken){

            //get current user
            let configs = {
                methods: 'GET',
                url: Api+'/user/datas',
                headers:{
                    'Authorization': 'Bearer '+valideToken
                }
            }

            axios(configs).then(response =>{

                if(response.status === 200){

                    const currentUser = { token: valideToken, res: response.data }
                    //console.log(currentUser)
                    dispatch(UserLogin(currentUser))
                    //redirection page admin
                    navigate('/', {replace: true});

                }
            }).catch(err =>{
                dispatch(UserLoginError(err))
            });
        
        }//end valid token

        })
        .catch(error=>{
            dispatch(UserLoginError(error))
        });
    }

 //end dispachs   
};

//User Account register

    export const User_Account_Register = (AccountInfos, navigate) =>{
        return dispatch => {

            dispatch(LoadingSignup())

            axios.post(Api+'/accounts/signup_in', AccountInfos)
            .then(response =>{
                //console.log(response.data.message)
               if(response.status === 200 && response.data.status === 400){

                dispatch(Account_Register_Exists(response.data.message))

               }
               
               if(response.status === 201){
                dispatch(Account_Register_Success());
                setTimeout(()=>{
                    navigate('/login', {replace: true})
                },[15000])
               }
               
            })
            .catch(err =>{
                console.log(err)
                dispatch(Account_Error_Register())
               
            })
        }
    }



