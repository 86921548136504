import React from 'react'

export const Banner = () => {
  return (
      
   <section className="banner-section align-items-center">
        <div className="container">
        <div className="banner-top">
            <div className="banner-content">
            
            <h2>Un outil web conçu pour gérer vos données 
            documentaires en un seul endroit</h2>
            <p>

            Syidocs vous permet de mettre en place une base de données 
            organisée, de ressources documentaires, qui permet de stocker, gérer et accéder 
            à divers types de documents et d'informations. 
            Cette ressource peut être libre d'accès ou protégée.


            </p>
            
            </div>
        </div>
        </div>
    </section>

  )
}
