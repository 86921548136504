import {
     CLEAR_STATES,
     CREATE_ENTITY_SUCCESS,
     CREATE_ENTITY_ERROR , DELETE_ENTITY_SUCCESS, 
     DELETE_ENTITY_ERROR, UPDATE_ENTITY_SUCCESS, 
     UPDATE_ENTITY_ERROR, DELETE_TAGS_CATS
    } from './Constants'

export const createEntitySuccess = (success) =>{
    return{
        type: CREATE_ENTITY_SUCCESS,
        payload: success
    }
}

export const createEntityError = (error) =>{
    return{
        type: CREATE_ENTITY_ERROR,
        payload: error
    }
}

export const deleteSuccess = (success) =>{
    return{
        type: DELETE_ENTITY_SUCCESS,
        payload: success
    }
}

export const deleteError = (error) =>{
    return{
        type: DELETE_ENTITY_ERROR,
        payload: error
    }
}

export const updateSuccess = (success) =>{
    return{
        type: UPDATE_ENTITY_SUCCESS,
        payload: success
    }
}

export const updateError = (error) =>{
    return{
        type: UPDATE_ENTITY_ERROR,
        payload: error
    }
}

export const clearEntityStates = () =>{
    return{
        type: CLEAR_STATES
    }
}

export const deleteTagsCats = () =>{
    return{
        type: DELETE_TAGS_CATS
    }
}

