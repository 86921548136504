
import {LOADING} from '../StoreConstants';
import { SIGIN_SUCCESS, 
         SIGIN_ERROR,
         LOGOUT_USER,
         ACCOUNT_REGISTER_SUCCESS,
         ACCOUNT_IS_EXISTS,
         ACCOUNT_ERROR_REGISTER,
         LOADING_SIGNUP,
         ACCOUNT_REFRESH_SESSION,
         ACCOUNT_EXPIRED_TOKEN
        } from './Constants';

//login
export const UserLogin = LoginData =>{
    return{
        type: SIGIN_SUCCESS,
        payload: LoginData

    }
}

export const Loading = ()=>{
    return{
        type: LOADING
    }
}

export const UserLoginError = (error)=>{
    return{
        type: SIGIN_ERROR
    }
}

//User Account register
export const LoadingSignup = ()=>{
    return{
        type: LOADING_SIGNUP
    }
}
export const Account_Register_Success = () =>{
    return {
        type: ACCOUNT_REGISTER_SUCCESS
    }
}

export const Account_Error_Register = () =>{
    return {
        type: ACCOUNT_ERROR_REGISTER
    }
}

export const Account_Register_Exists = message =>{
    return {
        type: ACCOUNT_IS_EXISTS,
        payload: message
    }
}

//action de expiration du token

export const Account_Invalid_Token = ()=>{
    return{
        type: ACCOUNT_EXPIRED_TOKEN
    }
}

export const Refresh_User_Session = (session) =>{
    return{
        type: ACCOUNT_REFRESH_SESSION,
        payload: session
    }
}

export const LogoutUser = () =>{
    return{
        type: LOGOUT_USER
    }
}

