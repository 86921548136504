
export const AxiosDeleteDatas = (Url, Token) => {
    const config = {
    method: 'DELETE',
    url: Url,
    headers: { 
    'Authorization': 'Bearer '+Token
    }
    }
    return config;
            
}