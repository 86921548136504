import{
    delete_User,
    Update_User_Error,
    Update_User_Success
} from './Actions'
import axios from "axios";
import { AxiosDeleteDatas } from '../../fetchDatasAxios/FetchDeleteDatas';
import { AxiosPutDatas } from '../../fetchDatasAxios/FetchPutDatas';

export const Delete_User_Account = (url, token)=>{
    const sup = AxiosDeleteDatas(url, token);

return dispatch =>{
    axios(sup).then(response =>{ console.log(response)
        dispatch(delete_User())
    }).catch(err=>{console.log(err)})
}
}

//Update Genres
export const Update_User_Account = (url, token, user)=>{
    const update = AxiosPutDatas(url, token, user);

return dispatch =>{
    axios(update).then(response =>{
       dispatch(Update_User_Success())
    }).catch(err=>{console.log(err) 
        dispatch(Update_User_Error(err.response.data.message))})
}
}

//modification Account user from admin
export const UpdateAccountFromAdmin = (url, token, account, setResult) => {
    const config = AxiosPutDatas(url, token, account);
    //console.log(account)
   return dispatch =>{
        axios(config).then(response =>{
           setResult(true)
         }).catch(err=>{
            setResult(false)
        })
    }
}
