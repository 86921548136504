import React, { Fragment, useEffect, useState} from 'react'
import { useSelector } from 'react-redux';
import StickyBox from 'react-sticky-box';
import { Link } from 'react-router-dom';
import { SimpleSpinner } from '../../../ui/Spinner';
import { Alerte_Error, Alerte_Restriction } from '../../../ui/AlertesUi';
import { AxiosGetPublicDatas } from '../../fetchDatasAxios/FetchGetDatas';
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios';

import { Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/search/lib/styles/index.css';

import ItemBoxFile from './ItemBoxFile';

import { APP_GET_BIBLIO_ENTITY, APP_READ_ENTITY } from '../../auth/urlParameters';


const ItemAccess = () => {
    const {session} = useSelector(state => ({
        session: state.sessionAuth
      }));
  const navigate = useNavigate()
  const params = useParams();

  const initialState = {
    titre: "",
    resume: "",
    langue: "",
    type: "",
    collections: {
        id: null,
        titre: ""
    },
    created_at: "",
    public: null
}

  const [Entity, setEntity] = useState(initialState)
  const[isAppLoading, setIsAppLoading] = useState(true)
  const [NoEntity, setNoEntity] = useState(false)
  const [textFilter, setTextFilter] = useState('')
  const [userLogged, setUserLogged] = useState(null)
  const [encryptedContent, setEncryptedContent] = useState(null);

  //const searchPluginInstance = searchPlugin();
  //const { Search } = searchPluginInstance;
  //const [readyToSearch, setReadyToSearch] = useState(false);

  const GetQuery = () => AxiosGetPublicDatas(APP_GET_BIBLIO_ENTITY (params.itemId, params.itemRef));
  useEffect(()=>{
    let get = GetQuery();
    axios(get).then(response =>{
        //console.log(response.data)
         setEntity(response.data)
       }).catch(error =>{ setNoEntity(true) })
       setIsAppLoading(false)
       return function(){
         get = GetQuery();
         setNoEntity(false)
       }
     // eslint-disable-next-line
  }, [])

   //Handlechange filtre
   const HandleChangeFilter = (e) => {
     setTextFilter(e.target.value);
   }

   const SearchFct = (e)=>{
    e.preventDefault()
    if(textFilter !== ''){
        navigate(`/search/entitys/1/null/null/${textFilter}`, {replace: false})
    }
    
   }

   useEffect(()=>{

    if(!session.isAuthenticated){
      setUserLogged(false)
    }
    
    else{

      const config = {
        methods: 'GET',
        url: APP_READ_ENTITY(params.itemRef, params.itemId, params.fichier),
        headers:{
            'Authorization': 'Bearer '+session.token
        }}
          axios(config).then(response =>{
           //console.log(response.data)
            setEncryptedContent(response.data.ressources)
            setUserLogged(true);
            
          }).catch(error =>{ 
            //console.log(error)
            setEncryptedContent(null)
          })


      }//fin else

   }, [session.isAuthenticated, userLogged])
   //console.log(params.fichier)

   //get datas
   const base64 = encryptedContent;
   const pdfContentType = 'application/pdf';
   const base64toBlob = (data) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    //const base64WithoutPrefix = data.substr(`data:${pdfContentType};base64,`.length);

    const bytes = atob(base64);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
        out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: pdfContentType });
    };

    const url = URL.createObjectURL(base64toBlob(base64));
    //console.log(url)

    //fonction recherche
   

  return (
    <Fragment>
        <div className="breadcrumb-bar">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-3 col-12">
            <nav aria-label="breadcrumb" className="page-breadcrumb">
            <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/">Apps</Link></li>
            <li className="breadcrumb-item active" aria-current="page">Document</li>
            </ol>
            </nav>
            
           </div>

          {/** Search */}
          
          <div className="col-md-5 col-12">
            <div className="col">

            <div id="search" className="search form-icon right">
                <input onChange={HandleChangeFilter} type="text" className="form-control form-control-icon" id="iconInput" placeholder="Recherche dans la base de données documentaire..."/>
                <i className="ri-search-2-line"></i>
            </div>
            </div>
          </div>
          <div className='col'>
                <button type='button' className='btn btn-primary' onClick={SearchFct} > Modifier</button>
            </div>
           
          </div>
        </div>
    </div>

<div className="content">
<div className="container">
    <div className="row">
    <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
    {/*Sidebar */}
    <StickyBox offsetTop={20} offsetBottom={20}>
    {/* Search Filter */}
    <div className="card search-filter">
                  <div className="card-header">
                    <h4 className="card-title mb-0">DETAILS DOCUMENTAIRE</h4>
                  </div>
                  <div className="card-body read-entity">
                    <div className="filter-widget">
                      <div>
                        <span style={{'color':'#17377c', 'fontWeight':'700'}}>
                            <em>Pour rechercher une information
                            directement à l'intérieur du document,
                            </em>
                           
                        </span>
                        
                      </div>	
                      <span>Cliquer sur l'icone de recherche</span>		
                    </div> 
                    <div className="filter-widget">
                    <span>Résumé</span>
                      <div className="col">
                          {Entity.resume}
                      </div>
                      <span>Collection</span>
                      <div className="col">
                        <Link to={`/search/entitys/1/${Entity.collections.id}/null/null`}>
                        {Entity.collections.titre}
                        </Link>
                     
                      </div>
                      <span>Type</span>
                      <div className="col">
                      {Entity.type}
                      </div>
                      <span>Langue</span>
                      <div className="col">
                      {Entity.langue}
                      </div>

                    </div>
                  </div>
                </div>
                {/* /Search Filter */}
    </StickyBox>                
    {/*Sidebar */}
    </div>
    {/*application */}
    <div className="col-md-7 col-lg-8 col-xl-9">
    <div className="row app_items">

    {/*header content */}
      <div className="col-12 col-sm-12 col-md-12">
      <h4 className="htitle">{Entity.titre}</h4>
      </div>
      <div className="col-12 col-sm-8 col-md-6 text-sm-right">
                         
      </div>
    {/*fin header content */}

           {/*Content Tab */}    
        <div className="row contentTab">
         <div className="col-12 col-md-12"> 
         <div className="appointment-tab">
         <div className="tab-content">
         <div className='search_items row' style={{'paddingLeft':'8px'}}>   
            {!!isAppLoading ? (
                <div className='col-12 item simpleSpinner'>
                {SimpleSpinner()}
                <span>Chargement en cours...</span>
                </div>
            ): NoEntity ? (Alerte_Error('CONTENU NON ACCESSIBLE, VERIFIEZ VOTRE CONNEXION')) :
            (

              userLogged === false ? (Alerte_Restriction(' :  Ce document n’est pas accessible directement, vous devez être connecté pour y accéder. Pour votre essai, utilisez les identifiants suivants: compte démo = users@syidocs.com ; mot de passe = users@syidocs.com ')
              ) : userLogged === true ? (

                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                     <ItemBoxFile fileUrl={url}/>
                   {/*<Viewer fileUrl={url} plugins={[searchPluginInstance]} />*/} 
                  </Worker>
               
              ) : 'Chargement en cours...'

            )
            
            }

        </div>
    
        {/* // fin Tab */}
    
   

        </div>
        </div>
        </div>
        </div>
        </div>
        </div>

        </div>
        </div>
        </div>


    </Fragment>
  )
}

export default ItemAccess