
export const AxiosGetDatas = (url, token) => {
         const config = {
            methods: 'GET',
            url: url,
            headers:{
                'Authorization': 'Bearer '+token
            }
        }
        return config
        
}

//public requete
export const AxiosGetPublicDatas = url => {
    const config = {
       methods: 'GET',
       url: url
   }
   return config
   
}