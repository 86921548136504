import React from 'react';
import { Link } from 'react-router-dom';
import DropdownListe from '../backend/in/collections/DropdownListe';
const TopBar = (props) => {

    const{HandleChangeFilter, SearchFct} = props;

  return (
    <div className='container-lg top'>
    <div className='row topBar text-center'>
        <div className='col-6 md-4'> 
            <Link className='valeur' to='/plan-documentaire'>
                <i className="ri-menu-unfold-line"></i> 
                Plan documentaire
            </Link>
        </div>
        <div className='col-6 md-4'>
            <ul className='ulsearch'>
              <li className="nav-item dropdown">
                  <Link to="#" className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                  <i className="ri-function-line"></i> COLLECTION
                  </Link>
                  <div className="submenu dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <DropdownListe/>
                  </div>
              </li>
            </ul>
        </div>
        <div id="blocksearch" className='col-md-12'>
            <div className="input-group mb-3">
            <div className="input-group-prepend">
                <button className="btn btn-outline-secondary dropdown-toggle" 
                type="button" data-toggle="dropdown" 
                 aria-haspopup="true" aria-expanded="false">
                    Options
                </button>
                <div className="dropdown-menu">
                    <Link to="#" className="dropdown-item">Documents</Link>
                    <Link to="#" className="dropdown-item">Livre Numérique</Link>
                </div>
            </div>
            <input onChange={HandleChangeFilter} type="text" className="form-control" 
            placeholder="Collection, Document, Fichier, Tag" 
            aria-label="Recherche" 
            aria-describedby="button-addon2" style={{fontFamily:'sans-serif'}}/>
            
            <div className="input-group-append">
                <button onClick={SearchFct} className="btn btn-outline-secondary" 
                type="button" id="button-addon2" style={{border:'none'}}>
                    <i style={{border:'none', color:'#fff'}} className="ri-search-2-line"></i>
                </button>
            </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default TopBar