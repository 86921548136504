import React, {Fragment, useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppResetUserPwd_checkMail } from '../backend/store/Resetpassword/Dispatchs'
import { BtnLoading } from '../ui/BtnUi'
import {Alerte_Error} from '../ui/AlertesUi'
import SuccessCard from '../ui/SuccessCard'

const CheckUserMail = () => {
    const state = useSelector(state => state.resetPassword)
    const dispatch = useDispatch()

    const [resetEmail, setResetEmail] = useState('')
    const [recharge, setRecharge] = useState(0)

    useEffect(()=>{
        setRecharge(state.validUser)
    },[state.validUser])
    
    const resetPwdsubmit = e =>{
        e.preventDefault()
        const verifMail = {
            usermailverif: resetEmail
        }
       // console.log(verifMail)
       dispatch(AppResetUserPwd_checkMail(verifMail))
        setResetEmail('')
    }
  return recharge === 0 ? (
    <Fragment>
    <div className="content">
      <div className="container-fluid">
      <div className="row">
      <div className="account-content">
        <div className="row align-items-center justify-content-center account_block">
        
           {/** block login*/}
           <div className="col-md-12 col-lg-5 login-right">
                <div className="login-header text-center">
                  <h3 style={{marginBottom: '10px'}}>
                    Cet assistant vous aide à réinitialiser votre mot de passe. Veuillez indiquer 
                    votre adresse e-mail de connexion
                  
                   </h3>
                 
                </div>
                <div>
                
                <form name='loginForm' onSubmit={resetPwdsubmit}>
                    <div className="form-group form-focus">
                        <input style={{background:'#fff', color:'#222'}} type="email" className="form-control" 
                        value={resetEmail}
                        onChange={e => setResetEmail(e.target.value)} required/>
                        <label className="focus-label">Votre mail de connexion</label>
                    </div>
                    {!state.isResetLoading ? ( <button className="btn btn-primary btn-block btn-lg login-btn" type="submit">
                            Suivant
                    </button>) 
                    : (BtnLoading('Vérification du compte'))
                    }
                   
                  
                </form>

                <div className="login-or">
                <span className="or-line" />
                <span className="span-or"><i className="ri-arrow-go-back-line"></i></span>
                </div>
                            
                <div className="text-center dont-have"> Revenir sur la page de connexion 
                    <a style={{color:'#ea0f4d'}} href="/login"> Login</a>
                </div>
               </div>
                <div className='loginError'>
                    
                        {state.reset_error && Alerte_Error(state.error_callback)}
                    {/** //messages erreur login */}
                </div>
                </div>
            
            
                    
        </div>
        </div>
      </div>
      </div>
    </div>
</Fragment>
  ) : (
    <div className="container" style={{'marginTop':'5%', 'marginBottom':'10%'}}>
    <SuccessCard H3={'SUCCES !'} Content={'Un E-mail pour la réinitialisation du mot de passe a été envoyé à votre adresse. Consultez votre méssagérie pour finaliser le processus. Au cas où vous ne l\'avez pas reçu, consultez votre dossier Spam ou Courrier indésirables.'}/>
    </div>
  )
}

export default CheckUserMail
