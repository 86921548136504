import React, {Fragment, useCallback, useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Url_Verif, AppChangeUserPwd } from '../backend/store/Resetpassword/Dispatchs'
import { BtnLoading } from '../ui/BtnUi'
import { DefaultSpinner } from '../ui/Spinner'
import { Alerte_Error, Alerte_Warning } from '../ui/AlertesUi'

const ProfileNewPassword = () => {

    const Params = useParams()
    const {resetUser, auth} = useSelector(state => ({
        resetUser: state.resetPassword,
        auth: state.sessionAuth
    }))
    const dispatch = useDispatch()
    const [load, setLoad] = useState(true)
    const [GetConfirmUpdate, setGetConfirmUpdate] = useState(false)
    const [pwdNonIdentique, setPwdNonIdentique] = useState(false)
    const [btndisabled, setBtnDisabled] = useState(false)
    const msg_err = 'Assurez-vous que les 2 mots de passe sont identiques. Le minimum requis est de 8 caractères'

        //callback fonction de verification du lien
        const ConfirmUpdate = useCallback(()=> {
           return Url_Verif(Params.sessionId, Params.resetToken)
        }, [Params.sessionId, Params.resetToken])

        useEffect(()=>{
            //const ValueOfConfirmUpdate = ConfirmUpdate()
            //console.log('ConfirmUpdate = ', ValueOfConfirmUpdate)
               /* if(ValueOfConfirmUpdate){
                setGetConfirmUpdate(true)
                }*/
                if(auth.isAuthenticated){
                    setGetConfirmUpdate(true)
                }
                    
            setTimeout(()=>{
                setLoad(false)
            },[500])
            
            return ()=>{
              ConfirmUpdate() 
           }

        }, [ConfirmUpdate, auth.isAuthenticated])
    
         //Fin callback fonction de verification du lien

    const [newPass, setNewPass] = useState('')
    const [confirmNewPass, setConfirmNewPass] = useState('')
    
    useEffect(()=>{
      if(newPass !== confirmNewPass){
          setBtnDisabled(true);
          setPwdNonIdentique(true)
      }else if(newPass.length < 8 && pwdNonIdentique){
          setBtnDisabled(true);
      }
      else if(btndisabled || pwdNonIdentique){
          setBtnDisabled(false)
          setPwdNonIdentique(false)
      }
      return () =>{
        console.log('')
        
      }
      // eslint-disable-next-line 
     }, [newPass, confirmNewPass])

    const UpdateSubmit = e => {
        e.preventDefault()
        const newPwd = {
            newpwd: newPass
        }
        dispatch(AppChangeUserPwd(Params.sessionId, Params.resetToken, newPwd))
        setNewPass('')
        setConfirmNewPass('')
        
    }
    
  return !!load ? (DefaultSpinner('Redirection')) : (
    GetConfirmUpdate ? (<Fragment>
        <div className="content">
          { resetUser.confirm_success && (

                <div className="row justify-content-center">
                <div className="col-lg-6">
                <div className="card success-card">
                <div className="card-body">
                <div className="success-cont confirmresetpwd">
                    <i className="fas fa-check"/>
                    <h3 style={{'color': '#20c997'}}>MODIFICATION DU MOT DE PASSE </h3>
                    <p>
                        <strong style={{'fontSize':'1.14em'}}>
                            {resetUser.success_callback}
                        </strong>
                    </p>
                   
                </div>
                </div>
                </div>
                </div>
            </div> )} 
          <div className="container-fluid">
          <div className="row">
          <div className="account-content">
            <div className="row align-items-center justify-content-center account_block">
            
               {/** block login*/}
               <div className="col-md-12 col-lg-5 login-right">
                    <div className="login-header text-center">
                      <h3 style={{marginBottom: '10px'}}>
                        Mettre à jour le mot de passe
                      </h3>
                     
                    </div>
                    <div>
                    
                    <form name='loginForm' onSubmit={UpdateSubmit}>
                        <div className="form-group form-focus">
                            <input style={{background:'#fff', color:'#000'}} type="password" className="form-control" 
                            value={newPass}
                            onChange={e => setNewPass(e.target.value)} required/>
                            <label className="focus-label">Nouveau mot de passe</label>
                        </div>
                        <div className="form-group form-focus">
                            <input style={{background:'#fff', color:'#000'}} type="password" className="form-control" 
                            value={confirmNewPass}
                            onChange={e => setConfirmNewPass(e.target.value)} required/>
                            <label className="focus-label">Confirmer nouveau mot de passe</label>
                        </div>
    
                        {!resetUser.confirm_loading ? ( <button className="btn btn-primary btn-block btn-lg login-btn" 
                        type="submit" disabled={btndisabled}>
                                Confirmer le nouvau mot de passe
                        </button>) 
                        : (BtnLoading('Update password'))
                        }
                       
                      
                    </form>
    
                    <div className="login-or">
                    <span className="or-line" />
                    <span className="span-or"><i className="ri-arrow-go-back-line"></i></span>
                    </div>
                                
                    <div className="text-center dont-have"> Annulation 
                        <a style={{color:'#ea0f4d'}} href="/login"> Login</a>
                    </div>
                   </div>
                    <div className='loginError'>
                       {pwdNonIdentique && Alerte_Warning(msg_err)} 
                       {resetUser.confirm_error && Alerte_Error(resetUser.error_callback)}    
                       
                    </div>
                    </div>
                
                
                        
            </div>
            </div>
          </div>
          </div>
        </div>
    </Fragment>)
    :(
        <div className="container" style={{'marginTop':'3%', 'marginBottom':'30%'}}>
                <div className="row justify-content-center">
                <div className="col-lg-8">
                {Alerte_Error('Interruption du processus, lien non valide')}
                </div>
                </div>
        </div>
        
    
    )
    
  )
}

export default ProfileNewPassword
