import React, { Fragment, useEffect, useState} from 'react'
import { SimpleSpinner } from '../ui/Spinner';
import { AxiosGetPublicDatas } from '../backend/fetchDatasAxios/FetchGetDatas';
import { APP_HOME_SEARCH } from '../backend/auth/urlParameters';
import axios from 'axios';
import SearchItemsListe from '../backend/home/search/SearchItemsListe';

const DocumentsList = () => {
    const [searchResults, setSearchResults] = useState([])
    const initialState ={
        search_text: '',
        page : 1,
        col: null,
        type: null
      }
    const[isAppLoading, setIsAppLoading] = useState(true)
    const [NoSearchResults, setNoSearchResults] = useState(false)
    
    
    //Obtenir les entités avec page et filtres
  const CreateQuery = () => AxiosGetPublicDatas(APP_HOME_SEARCH(initialState.page, null, null, null, null, null));
  //get datas from typsens
  useEffect(()=>{
    
    let config = CreateQuery();

    axios(config).then(response =>{
     
     if(response.data.found === 0){
      setNoSearchResults(true)
    }
      setSearchResults(response.data.results)
     
    }).catch(error =>{
       
      setSearchResults([])
      setNoSearchResults(true)
    })
    
    setIsAppLoading(false)
    //demontage 
    return function(){
      config = CreateQuery();
      setNoSearchResults(false)
    }
  // eslint-disable-next-line 
  }, [])


  return (
    <Fragment>
        {/* / Tab */}

    <div className='search_items row' style={{'margin':'20px 5% 20px 5%'}}>   
          {!!isAppLoading ? (
            <div className='col-12 item simpleSpinner'>
            {SimpleSpinner()}
            <span>Chargement...</span>
            </div>
          ): NoSearchResults ? (
            <div className='col-12 item simpleSpinner'>
            <div className="alert alert-info" role="alert">
              Aucune donnée disponible...
            </div></div>
            ):( searchResults.map((items, index) => <SearchItemsListe Entity = {items} key={index}/> ) )
          
          }

    </div>
    
    {/* // fin Tab */}
    </Fragment>
  )
}

export default DocumentsList;