
import { Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import './ui.css';
export const DefaultSpinner = (text)=>{


    return(<Fragment>
        <div className='defaultloading_spinner'>
        <button className="btn btn-primary" type="button" disabled>
        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
        
        </button>
        {text}
        </div>
</Fragment>
    )
    
}

export const OverlaySpinner = (props)=>{

    const {ModalShow, ModalHide, txt} = props

    return(
            <Fragment>
              <Modal aria-labelledby="contained-modal-title-vcenter" centered show={ModalShow} onHide={ModalHide}>
              <div className="d-flex justify-content-center OverlaySpinner">
                <button className="btn btn-primary" type="button" disabled>
                 <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    {txt}
                </button>
                </div>
            </Modal>
          </Fragment>
    )
}

export const SpinnerGetDatas = ()=>{
    return(<div class="d-flex justify-content-center">
            <div class="spinner-grow text-light" role="status">
            <span class="visually-hidden">...</span>
            </div>
            </div>
  )
}
export const SimpleSpinner = ()=>{
    return(
    <Fragment>
      <div className="d-flex justify-content-center simpleSpinner">
      <div className="spinner-border" role="status">
      <span className="sr-only">Loading...</span>
      </div></div>
    </Fragment>
  )
}
