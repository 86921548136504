import axios from "axios";
import{
    Apps_Update_Profile, 
    Apps_Update_Loading,
    Apps_Update_Error
} from './Actions'

export const UpdateUserProfile = (Url, token, profileDatas) =>{
    return dispatch =>{
        
        dispatch(Apps_Update_Loading())
        

        let config = {
            method: 'POST',
            url: Url,
            headers: { 
            'Authorization': 'Bearer '+token
            },
            data: profileDatas
        }

        axios( config ).then(response =>{
            if(response.status === 200){
                
                dispatch(Apps_Update_Profile(response.data.message))
            }
            
        }).catch(err =>{
            dispatch(Apps_Update_Error(err.message))
            console.log(err)
        })
     
    }
}