import React from 'react'
import useAxiosToGet from '../../fetchDatasAxios/FetchGet'
import { GET_ALL_GENRES } from '../../auth/urlParameters'

const SelectListeGenre = (props) => {
    const {genre_litteraire, token, setGenre, text} = props;
    const{isAppLoading, fetchDatas} = useAxiosToGet(GET_ALL_GENRES(), token)
   
  return !isAppLoading ? (
    fetchDatas.status === 400 ?( 
    <select name='genre_litteraire' defaultValue={genre_litteraire} className="form-control select" disabled required>
    <option>Vous devez obligatoirement définir un genre littéraire</option>
    </select> 
    ) : fetchDatas.status === 200 ? (
    <select onChange={(e)=>setGenre(e.target.value)} name='genre_litteraire' defaultValue={genre_litteraire} className="form-control select" required>
   <option>{text}</option>
   { fetchDatas.data.map((genre) =><option key={genre.id} value={genre.id}>{genre.titre}</option> )}
    </select> 
    )
    : (<select name='genre_litteraire' defaultValue={genre_litteraire} className="form-control select" required>
    <option>Selection du genre littéraire</option>
    </select>)
    
    ) : ''
}

export default React.memo(SelectListeGenre) 