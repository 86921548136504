import React from 'react'

const SearchTags = (props) => {

    const {Tags, setChange} = props
    //console.log(Tags)
  return (
    <>
    {
        
        Tags.map((tags, index) => <a href="#" key={index} onClick={() => setChange(tags.highlighted)} ><h6> {tags.highlighted} <span className="badge badge-secondary">{tags.count}</span></h6></a>)
        
    }

    </>
  )
}

export default SearchTags