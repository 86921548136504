import React, { Fragment, useEffect, useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import StickyBox from 'react-sticky-box'
import Breadcrumb from '../Breadcrumb'
import Sidebar from '../Sidebar'
import { TagsInput } from "react-tag-input-component"
import { useDropzone } from 'react-dropzone'
import { FormGroup, Label, Input } from 'reactstrap';

import { OverlaySpinner, DefaultSpinner } from '../../../ui/Spinner'
import { ToastError, ToastConfirm} from '../../../ui/AlertesUi'
import SelectListeCat from '../classifications/SelectListe'
import SelectListeColl from '../collections/SelectListe'
import SelectListeGenre from '../genres/SelectListe'

import axios from 'axios'
import { AxiosGetDatas } from '../../fetchDatasAxios/FetchGetDatas'

import { GET_ENTITYS_ITEMS, ENTITYS_UPDATE_ITEMS } from '../../auth/urlParameters'
import { UpdateEntitysItem } from '../../store/BiblioEntity/Dispatchs'

import DeleteItemsTagsCats from './DeleteTagsCats'

  const UpdateItems= () => {
  const {session, biblioEntity} = useSelector(state => ({
    session: state.sessionAuth,
    biblioEntity: state.biblioEntity
  }));

  const params = useParams();
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const initialState = {
    uuid: '',
    titre: '',
    langue: '',
    type: '',
    resume:'',
    is_public: false
  }

  const [entity, setEntity] = useState(initialState)
  const[openLoading, setOpenLoading] = useState(false)
  const [Show, setShow] = useState('')
  const [statePublic, setStatePublic] = useState(false)

  const[documents, setDocuments] = useState({
    id: null,
    type_docs: '',
    createur:''
    })
  const[livres, setLivres] = useState({
    id: null,
    auteur: '',
    biographie: '',
    courant_litteraire: '',
    annee_parution: ''
    })
  const [tags, setTags] = useState([])
  const [itemFile, setItemFile] = useState('')
  
  const [collection, setCollection] = useState({id: null, 'titre':''});
  const [classification, setClassification] = useState({'nom':''});
  const [genre, setGenre] = useState({'titre':''});

  const [collectionUp, setCollectionUp] = useState();
  const [classificationUp, setClassificationUp] = useState();
  const [genreUp, setGenreUp] = useState();
  
  const[desabled, setDesabled] = useState(true)
  const[confirmAlerte, setConfirmAlerte] = useState(false)
  const[confirmAlerteTags, setConfirmAlerteTags] = useState(false)
  const[errorAlerte, setErrorAlerte] = useState(false)
  const[isAppLoading, setIsAppLoading] = useState(true)
    //get entity from Fetch
    //const {isAppLoading, fetchDatas} = useAxiosToGet(GET_ENTITYS_ITEMS(params.uuid, params.id), session.token);
    
    const query = () => AxiosGetDatas(GET_ENTITYS_ITEMS(params.uuid, params.id), session.token)
    
    useEffect(()=>{
         
    let getelement = query();
    axios(getelement).then(response =>{

      const fetchDatas = response

      setEntity(fetchDatas.data)
      
      setCollection(fetchDatas.data.collections)
      setClassification(fetchDatas.data.categories[0])

      if(fetchDatas.data.documents.length !== 0){
        setDocuments(fetchDatas.data.documents[0])
      }
     
      if(fetchDatas.data.livres.length !== 0){
        setGenre(fetchDatas.data.livres[0].genre)
        setLivres(fetchDatas.data.livres[0])
      }
      
    }).catch(error =>{
      navigate('/', {replace:true})
    })
    
    setIsAppLoading(false)
    //demontage 
    return function(){
      getelement = query();
    }

      /*if(fetchDatas !== null){
        //console.log(fetchDatas.data)
        if(fetchDatas.status == 401 || fetchDatas.data.uuid !== params.uuid){
          navigate('/', {replace:true})
        }
        setEntity(fetchDatas.data)
        setCollection(fetchDatas.data.collections)
        setClassification(fetchDatas.data.categories[0])

        if(fetchDatas.data.documents.length !== 0){
          setDocuments(fetchDatas.data.documents[0])
        }
       
        if(fetchDatas.data.livres.length !== 0){
          setGenre(fetchDatas.data.livres[0].genre)
          setLivres(fetchDatas.data.livres[0])
        }
        
      }*/
       
    // eslint-disable-next-line 
    },[ biblioEntity.delete_tags_cats, biblioEntity.update_success])
    

  const handlChangeEntity = e =>{
    setEntity({
      ...entity,
      [e.target.name] : e.target.value
    })
    
  }

  const handlChangeDocs = e =>{
    setDocuments({
      ...documents,
      [e.target.name] : e.target.value
    })
  }

  const handlChangeLivres = e =>{
    setLivres({
      ...livres,
      [e.target.name] : e.target.value
    })
    
  }
  
//dropzone
const onDrop = (acceptedFiles) => {
  setItemFile(acceptedFiles[0]);
};

const {acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({ maxFiles:1, onDrop });
 
const acceptedFileItems = acceptedFiles.map(file => (
  <li key={file.path}>
    <div className="alert alert-info alert-dismissible fade show mt-4" role="alert">
    {file.path} - {file.size} bytes
    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">×</span>
    </button>
  </div>
    
  </li>
));
const fileRejectionItems = fileRejections.map(({ file, errors  }) => { 
  return (
    <li key={file.path}>
       <div className="alert alert-danger alert-dismissible fade show mt-4" role="alert">
       {file.path} - {file.size} bytes
         <ul>
           {errors.map(e => <li key={e.code}>{e.message}</li>)}
        </ul>
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>

    </li>
  ) 
 });
//clear data

    useEffect(()=>{
      if( entity.titre !== '' && entity.langue !== '' && collection !== null && classification !== null){
        setDesabled(false)
      }
      if(entity.type === ''){
        setDesabled(true)
      }

      if(collection.id){
        setCollectionUp(collection.id)
      }else{setCollectionUp(collection)}

      if(classification.id){
        setClassificationUp(classification.id)
      }else{setClassificationUp(classification)}

      if(genre.id){
        setGenreUp(genre.id)
      }else{setGenreUp(genre)}
     // eslint-disable-next-line 
    },[entity, classification, collection, genre])
    
    //console.log(documents)

    useEffect(()=>{
      setShow(ShowSelectEntity(entity.type));
      setStatePublic(entity.is_public);
    },[entity.type]) 

  const ShowSelectEntity = (select)=> {
    if(select === 'document'){ return (
  <Fragment>
      <h4 className="card-title"><strong>Documents: Champ de données supplémentaires </strong></h4>
      <div className="row form-row">

        <div className="col-md-6">
          <div className="form-group">
          <label>Type de document <span className="text-danger">*</span></label>
          <select defaultValue={documents.type_docs} onChange={handlChangeDocs} name='type_docs' className="form-control select" required>
          <option value={documents.type_docs}>{documents.type_docs}</option>
          <option value={'pdf'}>Document pdf</option>
          <option value={'image'}>Fichier image</option>
          </select>
          </div>
          </div>
          <div className="col-md-6">
          <div className="form-group">
          <label>Créateur du document (ou groupe d'auteur)</label>
          <input type="text" name="createur" defaultValue = {documents.createur||'Créateur'} onChange={handlChangeDocs} className="form-control" />
          </div>
          </div>

      </div>
</Fragment>
    )}
   
     else if(select === 'livre'){
      return(
      <Fragment>
      <h4 className="card-title"><strong>Livre: Champ de données supplémentaires</strong></h4>
      <div className="row form-row">
      <div className="col-md-6">
      <div className="form-group">
      <label>ISBN</label>
      <input type="text" name="isbn" defaultValue = {livres.isbn ||'Isbn'} onChange={handlChangeLivres} className="form-control" />
      </div>
      </div>
      <div className="col-md-6">
      <div className="form-group">
      <label>Editions</label>
      <input type="text" name="edition" defaultValue = {livres.edition||'Edition'} onChange={handlChangeLivres} className="form-control" />
      </div>
      </div>
      <div className="col-md-6">
      <div className="form-group">
      <label>Année de publication</label>
      <input type="text" name="annee_parution" defaultValue = {livres.annee_parution} onChange={handlChangeLivres} className="form-control" />
      </div>
      </div>

      <div className="col-md-6">
      <div className="form-group">
      <label>Auteur</label>
      <input type="text" name="auteur" className="form-control" defaultValue={livres.auteur||'Auteur'} onChange={handlChangeLivres}/>
      </div>
      </div>
      <div className="col-md-6">
      <div className="form-group">
      <label>Biographie de l'auteur</label>
      <input type="text" name="biographie" className="form-control" defaultValue={livres.biographie||'Bio'} onChange={handlChangeLivres}/>
      </div>
      </div>
      <div className="col-md-6">
      <div className="form-group">
      <label>Courant littéraire</label>
      <input type="text" name="courant_litteraire" className="form-control" defaultValue={livres.courant_litteraire||'Courant'} onChange={handlChangeLivres}/>
      </div>
      </div>
       {/* /choix type de donnee */}
       <div className="col-md-6 mb-4">
            <div className="form-group">
              <label>Genre littéraire <span className="text-danger">*</span></label>
              {<SelectListeGenre genre_litteraire={entity.genre_litteraire} token={session.token} setGenre={setGenre} text={genre.titre}/>}
            </div>
          </div>
      </div>

</Fragment>)}
else{ return null}}

   //Alert success
  const AlertSuccess = (alerte) =>{
    if(confirmAlerte === false){
      ToastConfirm(alerte)
      setConfirmAlerte(true)
    }
  }
  const AlertSuccessTags = (alerte) =>{
    if(confirmAlerteTags === false){
      ToastConfirm(alerte)
      setConfirmAlerteTags(true)
    }
  }
  //Error Alerte
  const AlertError = alerte =>{
    if(errorAlerte === false){
      ToastError(alerte)
      setErrorAlerte(true)
    }
  }
  
  //send items
  const ItemsFormSubmit = e => {
    e.preventDefault();
    setOpenLoading(true)
    setErrorAlerte(false)
    setConfirmAlerte(false)
    const formData = new FormData()
      
      formData.append('file', itemFile)
      formData.append('titre', entity.titre)
      formData.append('langue', entity.langue)
      formData.append('resume', entity.resume)
      //form append
      formData.append('type', entity.type)
      formData.append('document_type', documents.type_docs)
      formData.append('createur', documents.createur)
      formData.append('documents_id', documents.id)
       
      formData.append('genre', genreUp)
      formData.append('isbn', livres.isbn)
      formData.append('edition', livres.edition)
      formData.append('annee_parution', parseInt(livres.annee_parution))
      formData.append('auteur', livres.auteur)
      formData.append('biographie', livres.biographie)
      formData.append('courant_litteraire', livres.courant_litteraire)
      formData.append('livres_id', livres.id)
  
      formData.append('collection', collectionUp)
      formData.append('classification', classificationUp)
      formData.append('mots_cles', tags)
      formData.append('is_public', statePublic)
      formData.append('fichier_id', entity.ressourcesLibraries[0].id)

     dispatch(UpdateEntitysItem(ENTITYS_UPDATE_ITEMS(params.uuid, params.id), session.token, formData))

      setOpenLoading(false);
  
  }
 
  return !!isAppLoading ? DefaultSpinner('Chargement...'):(
    <Fragment>
    <Breadcrumb title='Bibliothèque' submenu ='Eléments de la bibliothèque'/>

    <div className="content">
    <div className="container">
        <div className="row">
        <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
        {/*Sidebar */}
        <StickyBox offsetTop={20} offsetBottom={20}>
        <Sidebar/>
        </StickyBox>                
        {/*Sidebar */}
        </div>
        {/*application */}
        <div className="col-md-7 col-lg-8 col-xl-9">
        <div className="row app_items">


        {/*header content */}
          <div className="col-12 col-sm-4 col-md-6">
          <h4 className="htitle">MISE A JOUR DE L'ENTITE DANS LA BIBLIOTHEQUE</h4>
          </div>
          <div className="col-12 col-sm-8 col-md-6 text-center">
              {/*is_valid == false && Alerte_Error('Veuillez férifier les enregistrements')*/ }             
          </div>
        {/*fin header content */}
            {/*Content Tab */}    
                <div className="row contentTab">
                
                <div className="col-12 col-md-12"> 
                <form id='entityFormSubmit' onSubmit={ItemsFormSubmit}>
                   {/* Basic Information */}
                   <div className="card">
                        <div className="card-body">
                          <h4 className="card-title">Information de l'entité à ajouter</h4>
                          <div className="row form-row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Code requête </label>
                                <input name="uuid" value={entity.uuid} onChange = {handlChangeEntity} type="text" className="form-control" readOnly />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                &nbsp;
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <label>Titre <span className="text-danger">*</span></label>
                                <input name="titre" value={entity.titre} onChange = {handlChangeEntity} type="text" className="form-control" />
                              </div>
                            </div>
                           
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Langue <span className="text-danger">*</span></label>
                                <select name="langue" defaultValue={entity.langue} onChange = {handlChangeEntity} className="form-control select">
                                  <option value={entity.langue}>{entity.langue}</option>
                                  <option>Français</option>
                                  <option>Anglais</option>
                                  
                                </select>
                              </div>
                            </div>
                           
                            <div className="col-md-12">
                            <h4 className="card-title">Résumé de la publication</h4>
                            <div className="form-group mb-0">
                            <textarea className="form-control" rows={5} defaultValue={entity.resume} onChange = {handlChangeEntity} name="resume"/>
                          </div>
                          </div>
                          </div>
                        </div>
                      </div>
                      {/* /Basic Information */}

                      <div className="alert alert-dark alert-dismissible fade show mb-3" role="alert">
                        METADONNEES   
                      </div>

                      {/* /Show datas*/}
                      <div className="card">
                        <div className="card-body">
                           {/* /choix type de donnee */}
                           <div className="col-md-6 mb-4">
                              <div className="form-group">
                                <label>Le type de l'entité <span className="text-danger">*</span></label>
                                <select name="type" value={entity.type} onChange = {handlChangeEntity} className="form-control select">
                                  <option value={entity.type}>{entity.type}</option>
                                  <option value='document'>Documents</option>
                                  <option value='livre'>Livres</option>
                                </select>
                              </div>
                            </div>
                          {Show}
                        </div>
                        </div>
                      
                      {/* fin shoow datas */}
                       {/* classification*/}
                      
                       <div className="card">
                        <div className="card-body">
                        <h4 className="card-title"><strong>Organisation documentaire et plan de classement </strong></h4>
                        <div className="row form-row">
                           {/* /tags cats liste*/}
                           <div className="col-md-12 mb-12">
                            <hr></hr>
                              <div className="form-group">
                                <span className='tags'>Liste des classification de l'entité - Cliquer sur le croix pour supprimer un tags de la liste</span>
                                <br/>
                                {<DeleteItemsTagsCats classifications={entity.categories} entity={entity.id} token={session.token} setConfirmAlerteTags={setConfirmAlerteTags} />}
                              </div>
                            </div>
                             {/* /choix cats*/}
                           <div className="col-md-6 mb-4">
                              <div className="form-group">
                                <label>Classification <span className="text-danger">*</span></label>
                                {<SelectListeCat classification={classification} token={session.token} setClassification={setClassification} text={classification.nom}/>}
                              </div>
                            </div>
                            <div className="col-md-6 mb-4">
                              <div className="form-group">
                                <label>Collections <span className="text-danger">*</span></label>
                                {<SelectListeColl token={session.token} setCollection={setCollection} text={collection.titre}/>}
                              </div>
                            </div>

                              <div className="col-md-12 mb-4">
                              <div className="form-group">
                                <label>Mots clés </label>
                                <TagsInput value={tags} onChange={setTags}
                                name="mots clés" placeHolder="Entrer un mot"/>
                                <small className="form-text text-muted">Remarque : Tapez et appuyez sur Entrée pour ajouter de nouveaux mots clés</small>
                              </div>
                              <FormGroup switch>
                              <Input type="switch" checked={statePublic} onChange={() => { setStatePublic(!statePublic)}}/>
                              <Label check>Accès public</Label>
                              </FormGroup>
                            </div>
                            {/** Fichier */}
                            <h4>TELEVERSER LE FICHIER</h4>
                            <section className="container file">
                            <div {...getRootProps({ className: 'dropzone' })}>
                              <input {...getInputProps()} />
                              <span className='dropzone_span'>
                              <p>ATTENTION : Si vous déposez un autre fichier ici, l'ancien document sur le serveur sera écrasé !!!</p>
                              
                              </span>
                            </div>
                           
                            <ul className='dropzone_success'>{acceptedFileItems}</ul>
                              
                            <aside>
                             
                              <ul className='dropzone_error'>{fileRejectionItems}</ul>
                            </aside>
                            </section>
                            </div> 
                          </div>
                         </div>
                          {/* /Registrations */}
                      <div style={{marginTop:'22px'}} className="submit-section submit-btn-bottom">
                        <button type="submit" className="btn btn-primary submit-btn" disabled={desabled}>
                          Enregistrer
                        </button>
                        
                        <div className="col-12 col-sm-8 col-md-6" style={{'margin':'auto'}}>
                            {/*is_valid == false && Alerte_Error('Veuillez férifier les enregistrements')*/ }             
                        </div>
                     </div>
                    </form>
                  </div>

                     
            </div>
            <OverlaySpinner ModalShow={openLoading} 
                ModalHide={()=>setOpenLoading(false)} 
                txt = {'Enregistrement...'}
            openLoading={openLoading}/>
            {/* Fin Content Tab */} 
            {biblioEntity.update_error && AlertError(biblioEntity.update_error_message)}
            {biblioEntity.update_success && AlertSuccess(biblioEntity.update_success_message)}
            {biblioEntity.delete_tags_cats && AlertSuccessTags('Tags supprimé ... ')}
          </div>
        </div>
        {/*fin app */}
        </div>
    </div>
</div>
</Fragment>	
  )
}

export default UpdateItems