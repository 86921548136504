import{
UPDATE_ERROR,
UPDATE_SUCCESS,
DELETE_USER,
CLEAR_NOTIF_STATE
} from './Constants'

export const delete_User = ()=>{
    return{
        type: DELETE_USER
    }
}

export const Update_User_Error = error=>{
    return{
        type: UPDATE_ERROR,
        payload: error
    }
}

export const Update_User_Success = ()=>{
    return{
        type: UPDATE_SUCCESS
    }
}
export const Clear_States = ()=>{
    return{
        type: CLEAR_NOTIF_STATE,
        //payload: state
    }
}