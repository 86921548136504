import{
    Add_Cat_Loading, 
    Add_Cat_Error,
    Add_Cat_Success,
    delete_Cat,
    Update_Cat_Error, 
    Update_Cat_Success
} from './Actions'
import axios from "axios";
import { AxiosPostDatas } from '../../fetchDatasAxios/FetchPostDatas';
import { AxiosDeleteDatas } from '../../fetchDatasAxios/FetchDeleteDatas';
import { AxiosPutDatas } from '../../fetchDatasAxios/FetchPutDatas';

export const AppsCats_Add = (url, token, collection)=>{
    const post = AxiosPostDatas(url, token, collection);

    return dispatch =>{
        dispatch(Add_Cat_Loading())
        axios(post).then(response =>{
            if(response.status === 201){
                dispatch(Add_Cat_Success(response.data.message))
            }
            
        }).catch(err =>{
            dispatch(Add_Cat_Error(err.response.data.message))
        })
    }
}
export const AppsCats_Delete = (url, token)=>{
    const sup = AxiosDeleteDatas(url, token);

return dispatch =>{
    axios(sup).then(response =>{
        dispatch(delete_Cat())
    }).catch(err=>{console.log('')})
}
}

//Update Cats
export const AppsCats_Update = (url, token, cat)=>{
    const update = AxiosPutDatas(url, token, cat);

return dispatch =>{
    axios(update).then(response =>{
       dispatch(Update_Cat_Success())
    }).catch(err=>{console.log(err) 
        dispatch(Update_Cat_Error(err.response.data.message))})
}
}