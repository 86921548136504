import React, { Fragment, useEffect, useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import StickyBox from 'react-sticky-box'
import Breadcrumb from '../Breadcrumb'
import Sidebar from '../Sidebar'
import { TagsInput } from "react-tag-input-component"
import { FormGroup, Label, Input } from 'reactstrap';
import { useDropzone } from 'react-dropzone'

import { OverlaySpinner } from '../../../ui/Spinner'
import { ToastError, ToastConfirm} from '../../../ui/AlertesUi'
import SelectListeCat from '../classifications/SelectListe'
import SelectListeColl from '../collections/SelectListe'
import SelectListeGenre from '../genres/SelectListe'

import { CREATE_BIBLIO_ENTITYS } from '../../auth/urlParameters'
import { AppsBiblioEntity_Add } from '../../store/BiblioEntity/Dispatchs'


  const AddItemEntity= () => {
  const {session, biblioEntity} = useSelector(state => ({
    session: state.sessionAuth,
    biblioEntity: state.biblioEntity
  }));

  const dispatch = useDispatch()

  const initialState = {
    uuid: uuidv4(),
    titre: '',
    langue: '',
    entity_type: '',
    document_type: null,
    resume:'',
    annee_publication: 2023,
    isbn: '',
    edition: '',
    auteur: '',
    biographie: '',
    courant_litteraire: '',
    createur: ''
  }
  const [entity, setEntity] = useState(initialState)
  const[openLoading, setOpenLoading] = useState(false)
  const [Show, setShow] = useState('')
  const [tags, setTags] = useState(['Mots clés'])
  const [itemFile, setItemFile] = useState('')
  const [statePublic, setStatePublic] = useState(false)
  
  const [collection, setCollection] = useState(null);
  const [classification, setClassification] = useState(null);
  const [genre, setGenre] = useState(null);
  
  const[desabled, setDesabled] = useState(true)
  const[confirmAlerte, setConfirmAlerte] = useState(false)
  const[errorAlerte, setErrorAlerte] = useState(false)
  const [ModalShow, setModalShow] = useState(false)
  const ModalHide = () => setModalShow(false)

  const handlChangeData = e =>{
    setEntity({
      ...entity,
      [e.target.name] : e.target.value
    })
    
  }

  const ResetForm = ()=> {
    setEntity(initialState)
    setTags(['Mots clés'])
    setItemFile('')
  }
  
//dropzone
const onDrop = (acceptedFiles) => {
  setItemFile(acceptedFiles[0]);
};

const {acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({ maxFiles:1, onDrop });
 
const acceptedFileItems = acceptedFiles.map(file => (
  <li key={file.path}>
    <div className="alert alert-info alert-dismissible fade show mt-4" role="alert">
    {file.path} - {file.size} bytes
    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">×</span>
    </button>
  </div>
    
  </li>
));
const fileRejectionItems = fileRejections.map(({ file, errors  }) => { 
  return (
    <li key={file.path}>
       <div className="alert alert-danger alert-dismissible fade show mt-4" role="alert">
       {file.path} - {file.size} bytes
         <ul>
           {errors.map(e => <li key={e.code}>{e.message}</li>)}
        </ul>
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>

    </li>
  ) 
 });
//clear data

    useEffect(()=>{
      if( entity.titre !== '' && entity.langue !== '' && collection !== null && classification !== null){
        setDesabled(false)
      }
      if(entity.entity_type === '' || !itemFile){
        setDesabled(true)
      }
      
    },[entity, classification, collection, genre, itemFile])
    
     /*const showDate = () =>{
        return(<DatePicker className="form-control" 
        selected = {annee_publication}
        onChange={(date) => setDateParution(date)}
        showYearPicker
        dateFormat="yyyy"
        yearItemNumber={9}/>)
      }*/
      
    useEffect(()=>{
      setShow(ShowSelectEntity(entity.entity_type));
      
    },[entity.entity_type]) 

  const ShowSelectEntity = (select)=> {
    if(select === 'document'){ return (
  <Fragment>
      <h4 className="card-title"><strong>Documents: Champ de données supplémentaires </strong></h4>
      <div className="row form-row">

        <div className="col-md-6">
          <div className="form-group">
          <label>Type de document <span className="text-danger">*</span></label>
          <select defaultValue={entity.document_type} onChange={handlChangeData} name='document_type' className="form-control select" required>
          <option value={entity.document_type}>Select type docs</option>
          <option value={'pdf'}>Document pdf</option>
          <option value={'image'}>Fichier image</option>
          </select>
          </div>
          </div>
          <div className="col-md-6">
          <div className="form-group">
          <label>Créateur du document (ou groupe d'auteur)</label>
          <input type="text" name="createur" defaultValue = {entity.createur} onChange={handlChangeData} className="form-control" />
          </div>
          </div>

      </div>
</Fragment>
    )}
   
     else if(select === 'livre'){
      return(
      <Fragment>
      <h4 className="card-title"><strong>Livre: Champ de données supplémentaires</strong></h4>
      <div className="row form-row">
      <div className="col-md-6">
      <div className="form-group">
      <label>ISBN</label>
      <input type="text" name="isbn" defaultValue = {entity.isbn ||'Isbn'} onChange={handlChangeData} className="form-control" />
      </div>
      </div>
      <div className="col-md-6">
      <div className="form-group">
      <label>Editions</label>
      <input type="text" name="edition" defaultValue = {entity.edition||'Edition'} onChange={handlChangeData} className="form-control" />
      </div>
      </div>
      <div className="col-md-6">
      <div className="form-group">
      <label>Année de publication</label>
      <input type="number" name="annee_publication" defaultValue = {entity.annee_publication} onChange={handlChangeData} className="form-control" />

      </div>
      </div>

      <div className="col-md-6">
      <div className="form-group">
      <label>Auteur</label>
      <input type="text" name="auteur" className="form-control" defaultValue={entity.auteur||'Auteur'} onChange={handlChangeData}/>
      </div>
      </div>
      <div className="col-md-6">
      <div className="form-group">
      <label>Biographie de l'auteur</label>
      <input type="text" name="biographie" className="form-control" defaultValue={entity.biographie||'Bio'} onChange={handlChangeData}/>
      </div>
      </div>
      <div className="col-md-6">
      <div className="form-group">
      <label>Courant littéraire</label>
      <input type="text" name="courant_litteraire" className="form-control" defaultValue={entity.courant_litteraire||'Courant'} onChange={handlChangeData}/>
      </div>
      </div>
       {/* /choix type de donnee */}
       <div className="col-md-6 mb-4">
            <div className="form-group">
              <label>Genre littéraire <span className="text-danger">*</span></label>
              {<SelectListeGenre genre_litteraire={entity.genre_litteraire} token={session.token} setGenre={setGenre} text='Choix du genre littéraire' />}
            </div>
          </div>
      </div>

</Fragment>)}
else{ return null}}

   //Alert success
  const AlertSuccess = (alerte) =>{
    if(confirmAlerte === false){
      ToastConfirm(alerte)
      setConfirmAlerte(true)
    }
  }
  //Error Alerte
  const AlertError = alerte =>{
    if(errorAlerte === false){
      ToastError(alerte)
      setErrorAlerte(true)
    }
  }
  

  //send items
  const ItemsFormSubmit = e => {
    e.preventDefault();
    setOpenLoading(true)
    
    const formData = new FormData()
    //( entity.titre !== '' && entity.langue !== '' && collection !== null && classification !== null)
      
      formData.append('file', itemFile)
      formData.append('uuid', entity.uuid)
      formData.append('titre', entity.titre)
      formData.append('langue', entity.langue)
      formData.append('resume', entity.resume)

     
      //form append
      formData.append('type', entity.entity_type)
      formData.append('document_type', entity.document_type)
      formData.append('createur', entity.createur)
       
      formData.append('genre', genre)
      formData.append('isbn', entity.isbn)
      formData.append('edition', entity.edition)
      formData.append('annee_publication', parseInt(entity.annee_publication))
      formData.append('auteur', entity.auteur)
      formData.append('biographie', entity.biographie)
      formData.append('courant_litteraire', entity.courant_litteraire)
  
      formData.append('collection', collection)
      formData.append('classification', classification)
      formData.append('mots_cles', tags)
      formData.append('is_public', statePublic)

      //console.log(entity)
      dispatch(AppsBiblioEntity_Add(CREATE_BIBLIO_ENTITYS(), session.token, formData, setModalShow,  ModalHide))

      setOpenLoading(false);

      setErrorAlerte(false)
      setConfirmAlerte(false)
      ResetForm()
    
   // Afficher les données de formData
   /* for (let pair of formData.entries()) {
      console.log(pair[0], pair[1]);
    }*/
  
  }
  
  return (
    <Fragment>
    <Breadcrumb title='Bibliothèque' submenu ='Eléments de la bibliothèque'/>

    <div className="content">
    <div className="container">
        <div className="row">
        <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
        {/*Sidebar */}
        <StickyBox offsetTop={20} offsetBottom={20}>
        <Sidebar/>
        </StickyBox>                
        {/*Sidebar */}
        </div>
        {/*application */}
        <div className="col-md-7 col-lg-8 col-xl-9">
        <div className="row app_items">


        {/*header content */}
          <div className="col-12 col-sm-4 col-md-6">
          <h4 className="htitle">ENREGISTRER UNE ENTITE DANS LA BIBLIOTHEQUE</h4>
          </div>
          <div className="col-12 col-sm-8 col-md-6 text-center">
              {/*is_valid == false && Alerte_Error('Veuillez férifier les enregistrements')*/ }             
          </div>
        {/*fin header content */}
            {/*Content Tab */}    
                <div className="row contentTab">
                
                <div className="col-12 col-md-12"> 
                <form id='entityFormSubmit' onSubmit={ItemsFormSubmit}>
                   {/* Basic Information */}
                   <div className="card">
                        <div className="card-body">
                          <h4 className="card-title">Information de l'entité à ajouter</h4>
                          <div className="row form-row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Code requête </label>
                                <input name="uuid" value={entity.uuid} onChange = {handlChangeData} type="text" className="form-control" readOnly />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                &nbsp;
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <label>Titre <span className="text-danger">*</span></label>
                                <input name="titre" value={entity.titre} onChange = {handlChangeData} type="text" className="form-control" />
                              </div>
                            </div>
                           
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Langue <span className="text-danger">*</span></label>
                                <select name="langue" value={entity.langue} onChange = {handlChangeData} className="form-control select">
                                  <option value=''>Select</option>
                                  <option>Français</option>
                                  <option>Anglais</option>
                                  
                                </select>
                              </div>
                            </div>
                           
                            <div className="col-md-12">
                            <h4 className="card-title">Résumé de la publication</h4>
                            <div className="form-group mb-0">
                            <textarea className="form-control" rows={5} defaultValue={entity.resume} onChange = {handlChangeData} name="resume"/>
                          </div>
                          </div>
                          </div>
                        </div>
                      </div>
                      {/* /Basic Information */}

                      <div className="alert alert-dark alert-dismissible fade show mb-3" role="alert">
                        METADONNEES   
                      </div>

                      {/* /Show datas*/}
                      <div className="card">
                        <div className="card-body">
                           {/* /choix type de donnee */}
                           <div className="col-md-6 mb-4">
                              <div className="form-group">
                                <label>Le type de l'entité <span className="text-danger">*</span></label>
                                <select name="entity_type" value={entity.entity_type} onChange = {handlChangeData} className="form-control select">
                                  <option value=''>Select</option>
                                  <option value='document'>Documents</option>
                                  <option value='livre'>Livres</option>
                                </select>
                              </div>
                            </div>
                          {Show}
                        </div>
                        </div>
                      
                      {/* fin shoow datas */}
                       {/* classification*/}
                      
                       <div className="card">
                        <div className="card-body">
                        <h4 className="card-title"><strong>Organisation documentaire et plan de classement </strong></h4>
                        <div className="row form-row">
                           {/* /choix type de donnee */}
                           <div className="col-md-6 mb-4">
                              <div className="form-group">
                                <label>Classification <span className="text-danger">*</span></label>
                                {<SelectListeCat classification={classification} token={session.token} setClassification={setClassification} text='Sélection de la classification'/>}
                              </div>
                            </div>
                            <div className="col-md-6 mb-4">
                              <div className="form-group">
                                <label>Collections <span className="text-danger">*</span></label>
                                {<SelectListeColl token={session.token} setCollection={setCollection} text='Choisir la collection'/>}
                              </div>
                            </div>

                              <div className="col-md-12 mb-4">
                              <div className="form-group">
                                <label>Mots clés </label>
                                <TagsInput value={tags} onChange={setTags}
                                name="mots clés" placeHolder="Entrer un mot"/>
                                <small className="form-text text-muted">Remarque : Tapez et appuyez sur Entrée pour ajouter de nouveaux mots clés</small>
                              </div>

                              <FormGroup switch>
                              <Input type="switch" defaultChecked={statePublic} onClick={() => { setStatePublic(!statePublic)}}/>
                              <Label check>Accès public</Label>
                              </FormGroup>

                            </div>
                            {/** Fichier */}
                            <h4>TELEVERSER LE FICHIER</h4>
                            <section className="container file">
                            <div {...getRootProps({ className: 'dropzone' })}>
                              <input {...getInputProps()} />
                              <span className='dropzone_span'>
                              <p>Glissez et déposez un fichier PDF ici, ou cliquez pour sélectionner un fichier.</p>
                              </span>
                            </div>
                           
                            <ul className='dropzone_success'>{acceptedFileItems}</ul>
                              
                            <aside>
                             
                              <ul className='dropzone_error'>{fileRejectionItems}</ul>
                            </aside>
                            </section>
                            </div> 
                          </div>
                         </div>
                          {/* /Registrations */}
                      <div style={{marginTop:'22px'}} className="submit-section submit-btn-bottom">
                        <button type="submit" className="btn btn-primary submit-btn" disabled={desabled}>Enregistrer</button>
                        <a href="#" onClick={ResetForm} className="btn btn-light submit-btn">Annuler</a>
                        <div className="col-12 col-sm-8 col-md-6" style={{'margin':'auto'}}>
                            {/*is_valid == false && Alerte_Error('Veuillez férifier les enregistrements')*/ }             
                        </div>
                     </div>
                    </form>
                  </div>

                     
            </div>
            <OverlaySpinner ModalShow={ModalShow} 
                ModalHide={ModalHide}
                txt = {'Enregistrement...'}/>
            {/* Fin Content Tab */} 
            {biblioEntity.create_error && AlertError(biblioEntity.error_message)}
            {biblioEntity.create_success && AlertSuccess(biblioEntity.success_message)}
          </div>
        </div>
        {/*fin app */}
        </div>
    </div>
</div>
</Fragment>	
  )
}

export default AddItemEntity