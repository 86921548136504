//gestions des menus utilisateurs

import { Fragment } from "react"

export const MenuAdmin = ()=> {
return(
<Fragment>
    <li className='menu-list'>
    <a href="/apps/in/bibliotheque/entity/page/1">
        <i className="ri-stack-line" />
        <span>Entités de la bibliothèque</span>
    </a>
    </li>
    <li className='menu-list'>
    <a href="/apps/in/collections">
        <i className="ri-folder-5-line" />
        <span>Collections</span>
    </a>
    </li>
    <li className='menu-list'>
    <a href="/apps/in/genres">
        <i className="ri-list-check-2" />
        <span>Genre littéraire</span>
    </a>
    </li>
    <li className='menu-list'>
    <a href="/apps/in/classifications">
    <i className="ri-list-ordered"></i>
        <span>Classifications</span>
    </a>
    </li>
    <li className='menu-list'>
    <a href="/apps/in/users-accounts/page/1">
        <i className="ri-account-circle-line" />
        <span>Compte gestion</span>
    </a>
    </li>

    </Fragment>)
}
export const MenuEdit = ()=> {
return(
<Fragment>
<li className='menu-list'>
    <a href="/apps/in/bibliotheque/entity/page/1">
        <i className="ri-stack-line" />
        <span>Entités de la bibliothèque</span>
    </a>
    </li>
    <li className='menu-list'>
    <a href="/apps/in/collections">
        <i className="ri-folder-5-line" />
        <span>Collections</span>
    </a>
    </li>
    <li className='menu-list'>
    <a href="/apps/in/genres">
        <i className="ri-list-check-2" />
        <span>Genre littéraire</span>
    </a>
    </li>
    <li className='menu-list'>
    <a href="/apps/in/classifications">
    <i className="ri-list-ordered"></i>
        <span>Classifications</span>
    </a>
    </li>
</Fragment>
    )
}
export const MenuAssist = ()=> {
return(<Fragment>
    <li className='menu-list'>
    <a href="/apps/in/bibliotheque/entity/page/1">
        <i className="ri-stack-line" />
        <span>Entités de la bibliothèque</span>
    </a>
    </li>
    
    </Fragment>
    )
}