import { combineReducers } from "redux";
//login reducer
import { UserAuthentication, AccountRegister } from "./Accounts/Reducers";
//reset password
import { ResetAccountPwd } from './Resetpassword/Reducers'
//user profile
import { UserDataSettings } from "./Profile/Reducers";
//Gestion User account
import { gestionUserAccounts } from "./Users/Reducers";
//collections
import { CollectionsReducers } from "./Collections/Reducers";
//Genres
import { genresReducers } from "./Genres/Reducers";
//Classifications
import { ClassificationsReducers } from "./Classifications/Reducers";
//Biblio Entity
import { EntitysGlobalFunctions } from "./BiblioEntity/Reducers";

const RootReducers = combineReducers({
    sessionAuth: UserAuthentication,
    accountRegister: AccountRegister,
    resetPassword: ResetAccountPwd,
    userSettings: UserDataSettings,
    collections: CollectionsReducers,
    genres: genresReducers,
    classifications: ClassificationsReducers,
    userAccount: gestionUserAccounts,
    biblioEntity: EntitysGlobalFunctions
})

export default RootReducers ;