import React, { Fragment, useEffect, useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import StickyBox from 'react-sticky-box'
import Breadcrumb from '../Breadcrumb'
import Sidebar from '../Sidebar'
import ItemsPagination from './ItemsPagination'
import { SimpleSpinner } from '../../../ui/Spinner'
import { ToastError, ToastConfirm} from '../../../ui/AlertesUi'

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import { AxiosGetDatas } from '../../fetchDatasAxios/FetchGetDatas'
import axios from 'axios'
import { GET_ALL_ENTITYS, ENTITY_DELETE } from '../../auth/urlParameters'
import { AppsEntitys_Delete } from '../../store/BiblioEntity/Dispatchs'

import SelectListeColl from '../collections/SelectListe'
import SelectListeCat from '../classifications/SelectListe'

const ListeItems= () => {
const {session, biblioEntity} = useSelector(state => ({
  session: state.sessionAuth,
  biblioEntity: state.biblioEntity
}));

const dispatch = useDispatch()

  const navigate = useNavigate()
  const params = useParams();

  const [Entity, setEntity] = useState([])
  const [count, setCount] = useState(0)

  const [date, setDate] = useState()
  const [langue, setLangue] = useState()
  const [type, setType] = useState()
  const [genre, setGenre] = useState(null)
  const [collection, setCollection] = useState(null);
  const [classification, setClassification] = useState(null);
  //modals
  const[btnLoad, setBtnLoad] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  //Delete
  const [deleteId, setDeleteId] = useState(null)
  const [deleteItem, setDeleteItem] = useState('')
  const [deleteUuid, setDeleteUuid] = useState(null)
  const [library, setLibrary] = useState(null)
  const [typeItem, setTypeItem] = useState(null)
  

  const[confirmAlerte, setConfirmAlerte] = useState(false)
  const[errorAlerte, setErrorAlerte] = useState(false)

  const[isAppLoading, setIsAppLoading] = useState(true)
  const [NoEntity, setNoEntity] = useState(false)
  const [resultSearch, setResultSearch] = useState([])
  const [textFilter, setTextFilter] = useState('')

  //Obtenir les entités avec page et filtres
  const CreateQuery = () => AxiosGetDatas(GET_ALL_ENTITYS(params.page, date, langue, type, collection, classification, genre), session.token);
  
  //fonction de filtrage de données
  const dataFilter = () => {
    const filter =  Entity.filter(entity => {
     const liste = []

     for(let i = 0; i< entity.motsCles.length; i++){
      liste.push(entity.motsCles[i].cles)
     }

     return  Object.values(entity).join(' ').toLowerCase().includes(textFilter.toLowerCase()) + 
       Object.values(entity.collections).join(' ').toLowerCase().includes(textFilter.toLowerCase()) +
       Object.values(entity.categories[0]).join(' ').toLowerCase().includes(textFilter.toLowerCase()) +
       Object.values(entity.motsCles[0]).join(' ').toLowerCase().includes(textFilter.toLowerCase()) + 
       Object.values(liste).join(' ').toLowerCase().includes(textFilter.toLowerCase()) 
    })
    setResultSearch(filter)
  }


  //use effect de filtre
  useEffect(()=>{
    if(textFilter !== ''){
      dataFilter()
      //setResultSearch(null)
    }else{
      setResultSearch(Entity)
    }
  }, [textFilter])
  //fin


  useEffect(()=>{
    
    let getelement = CreateQuery();
    axios(getelement).then(response =>{
      //console.log(response)
      setCount(response.data.count)
      setEntity(response.data.entitys)
      setResultSearch(response.data.entitys)
    }).catch(error =>{
      setEntity([])
      setResultSearch([])
      setNoEntity(true)
    })
    
    setIsAppLoading(false)
    //demontage 
    return function(){
      getelement = CreateQuery();
      setNoEntity(false)
    }
  // eslint-disable-next-line 
  }, [biblioEntity.delete_success, type, genre, collection, classification ])

const onNextClick = (page)=>{
   return '/apps/in/bibliotheque/entity/page/'+page;
 }
 
  //Handlechange filtre
  const HandleChangeFilter = (e) => {
    setTextFilter(e.target.value);
  }

   //Btn
   const DeleteCloseBtn = (
    <button className="close"  onClick={()=>setDeleteModal(false)} type="button">
      &times;
    </button>
  )
   //Alert success
   const AlertSuccess = (alerte) =>{
    if(confirmAlerte === false){
      ToastConfirm(alerte)
      setConfirmAlerte(true)
    }
  }
  //Error Alerte
  const AlertError = alerte =>{
    if(errorAlerte === false){
      ToastError(alerte)
      setErrorAlerte(true)
    }
  }

  const DeleteToggle = (id, item, uuid, library, type) => {
    setDeleteModal(!deleteModal)
    setDeleteId(id)
    setDeleteItem(item)
    setDeleteUuid(uuid)
    setLibrary(library)
    setTypeItem(type)
   }
   
   //fonction delet
   const entitysDelete = ()=>{
    setBtnLoad(true)
      setErrorAlerte(false)
      setConfirmAlerte(false)
      //dispacth
      dispatch( AppsEntitys_Delete( ENTITY_DELETE(deleteUuid, deleteId, library, typeItem), session.token))
      setDeleteModal(false)
      setBtnLoad(false)
      setDeleteUuid(null)
      setLibrary(null)
      setDeleteId(null)
   }

   //Update entity

   const navUpdate = (item_uuid, item_id) => navigate(`/apps/in/bibliotheque/entity/${item_uuid}/${item_id}`)
   //limite du titre
   function get_date (date) { 
    const dateStr = date;
    const dateObj = new Date(dateStr);

    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };

    const formattedDate = dateObj.toLocaleDateString('fr-FR', options);
    return formattedDate
    
  
  }

  return (
    <Fragment>
    <Breadcrumb title='Bibliothèque' submenu ='Eléments de la bibliothèque'/>

    <div className="content">
    <div className="container">
        <div className="row">
        <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
        {/*Sidebar */}
        <StickyBox offsetTop={20} offsetBottom={20}>
        <Sidebar/>
        </StickyBox>                
        {/*Sidebar */}
        </div>
        {/*application */}
        <div className="col-md-7 col-lg-8 col-xl-9">
        <div className="row app_items">


        {/*header content */}
          <div className="col-12 col-sm-4 col-md-6">
          <h4 className="htitle">LISTE DES ENTITES</h4>
            
          </div>
          <div className="col-12 col-sm-8 col-md-6 text-sm-right">
              <button className="btn btn-primary submit-btn" onClick={()=>navigate('/apps/in/bibliotheque/entity/add')}>
              <i className="ri-add-circle-fill"/> Enregistrer une entité
              </button>                
          </div>
       
        {/*fin header content */}

              
 {/*Content Tab */}    
    <div className="row contentTab">
      <div className="col-12 col-md-12"> 
        <div className="appointment-tab">
         <div className="tab-content">

          
         <div className='col-12 entity_bottom_header'>
         {<ItemsPagination count={count} page={params.page} limite={20} onNextClick={onNextClick} text='Items'/>}
         <div className="form-row">
         <div className=""> <span className="badge badge-pill badge-dark">Filtre</span> </div>
            <div className="col">
            <div className="form-group">
            {<SelectListeColl token={session.token} setCollection={setCollection} text='Collection'/>}
            </div>
            </div>
            <div className="col">
            <div className="form-group">
            {<SelectListeCat classification={classification} token={session.token} setClassification={setClassification} text='Classification'/>}
            </div>
            </div>
            <div className="col">
            <div className="form-group">
            <div className="form-icon right">
                <input onChange={HandleChangeFilter} type="text" className="form-control form-control-icon" id="iconInput" placeholder="filtre par texte"/>
                <i className="ri-search-2-line"></i>
            </div>
            </div>
            </div>
         </div>
        </div>

    {/* / Tab */}
    <div id="pat_appointments" className="tab-pane fade show active">
    <div className="card card-table mb-0">
      <div className="card-body">
        <div className="table-responsive">
          <table className="items table table-hover table-center mb-0">
            <thead>
              <tr>
                <th>Entités</th>
                <th>Collections</th>
                <th>Date publication</th>
                <th> </th>
                <th/>
              </tr>
            </thead>
            <tbody>
                  {!!isAppLoading ? (
                    <tr><td colSpan="4">
                    <div className='col-12 item simpleSpinner'>
                    {SimpleSpinner()}
                    <span>Chargement...</span>
                    </div></td></tr>) : (
                      NoEntity || resultSearch === null ? (
                        <tr><td colSpan="4">
                        <div className='col-12 item simpleSpinner'>
                        <div className="alert alert-info" role="alert">
                          Aucune donnée disponible...
                        </div>
                        </div></td></tr>
                        ) : (
                      
                          resultSearch.map((entity, index) => {
                        let type = '';
                        if(entity.type === 'document'){
                          type = entity.documents[0].id 
                        } else{
                          type = entity.livres[0].id
                        } 
                          return(
                                <tr key={index} className="table_tr_content">
                                    <td style={{'width':'38%'}}> 
                                        {entity.titre}
                                    </td>

                                    <td>{entity.collections.titre}</td>
                                    <td>{
                                    get_date(entity.created_at)

                                    }</td>
                                    
                                    <td className="text-right">
                                      <div className="table-action">
                                      <button className="btn btn-sm bg-info-light mr-0" onClick={()=>navUpdate(entity.uuid, entity.id)}>
                                      <i className="fas fa-edit" /> Editer
                                      </button>

                                        {/** gestion de la fonctionnalité de suppression */}
                                        {session.roles.includes('ROLE_ASSIST') ? (''): (<button className="btn btn-sm bg-danger-light" onClick={()=>DeleteToggle(entity.id, entity.titre, entity.uuid, entity.ressourcesLibraries[0].id, type)}>
                                            <i className="fas fa-times" /> Supp
                                          </button>)
                                        }
                                      
                                      </div>
                                    </td>
                                  </tr>
                                  )})
                      
                                  ))}

                        </tbody>
                    </table>
                  </div>
              </div>
            </div>
        </div>
    {/* // fin Tab */}

        </div>
      </div>
    </div>
</div>
{/* Fin Content Tab */} 
   
   {/*Delete modal*/}
   <Modal isOpen={deleteModal} toggle={DeleteToggle} className="modal-close">
        <ModalHeader toggle={DeleteToggle} close={DeleteCloseBtn}>&nbsp; Suppression de l'entité</ModalHeader>
        <ModalBody>
        <i className="ri-close-circle-line"></i> Voulez-vous vraiment supprimer cet élément : 
         <span className='delete_items_span'>" {deleteItem} "</span>  ?  
        </ModalBody>
        <ModalFooter>
        <div className='modalf_width'>
          {btnLoad ? SimpleSpinner():
            <>
            <Button color="primary" onClick={entitysDelete}> Confirmer la suppression de l'entité</Button>
            <Button color="secondary" onClick={()=>setDeleteModal(false)}>Annuler</Button>
            </>
          }
        </div>
        </ModalFooter>
      </Modal>

            {/* Fin Content Tab */} 
            {biblioEntity.delete_error && AlertError(biblioEntity.delete_error_message)}
            {biblioEntity.delete_success && AlertSuccess(biblioEntity.delete_success_message)}
 

</div>
</div>
{/*fin app */}
</div>
</div>
</div>
</Fragment>	
  )
}

export default ListeItems