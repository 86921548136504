import React, { Fragment, useEffect, useState } from 'react'
import StickyBox from 'react-sticky-box'
import Breadcrumb from '../Breadcrumb'
import Sidebar from '../Sidebar'
import UsersLites from './UsersLites'
//fetch
import { useSelector, useDispatch } from 'react-redux'
import { AxiosGetDatas } from '../../fetchDatasAxios/FetchGetDatas'
import useAxiosToGet from '../../fetchDatasAxios/FetchGet'

import { COUNT_USERS , GET_ALL_USERS, DELETE_USER_ACCOUNT } from '../../auth/urlParameters'
import { Delete_User_Account } from '../../store/Users/Dispatchs'
import { Clear_States } from '../../store/Users/Actions'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import { SimpleSpinner } from '../../../ui/Spinner'
import {Alerte_Warning, ToastConfirm} from '../../../ui/AlertesUi'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import ItemsPagination from '../items/ItemsPagination'
//clear state
const Users= () => {
  //STATE DU STORE
  const {session, userAccount} = useSelector(state => ({
    session: state.sessionAuth,
    userAccount: state.userAccount
  }))
  const parameters = useParams()
  const dispatch = useDispatch();

  const initialState = [];
  const[isLoading, setIsLoading] = useState(true)
  const[user, setUser] = useState(initialState);
  const[search, setSearch] = useState('');
  const[notDatas, setNoDatas] = useState(false);
  const[btnLoad, setBtnLoad] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteUser, setDeleteUser] = useState(null)
  //user
  const[confirmAlerte, setConfirmAlerte] = useState(false)
  const [count, setCount] = useState(0);
  
  //get enum des users
  const {isAppLoading, fetchDatas} = useAxiosToGet(COUNT_USERS(), session.token);
  //fonction Calback de reccupération data user
  const getCount = ()=>{
    if(fetchDatas !== null){
      setCount(fetchDatas.data.countUsers)
    }
    
   }
   
  const Params = () => AxiosGetDatas(GET_ALL_USERS(parameters.page, search), session.token);
  const get = Params()
   useEffect(()=>{
    //setNoDatas(false);
    axios(get).then(res =>{
      //console.log(res)
    setUser(res.data);
    setNoDatas(false);
    }
    ).catch(error =>{
      //console.log(error)
      setUser(error.response.data);
      setNoDatas(true);
      
    })
    setIsLoading(false)
     // eslint-disable-next-line
   },[userAccount.delete_user, search])

   const onNextClick = (page)=>{
    return '/apps/in/bibliotheque/entity/page/'+page;
  }
  
  useEffect(()=>{
    getCount()
  }, [isAppLoading])
  
   //console.log(user)
   //close modal
   const DeleteToggle = (user) => {
    setDeleteModal(!deleteModal)
    setDeleteUser(user)
   }
   //Btn
  //delet close
  const DeleteCloseBtn = (
    <button className="close" onClick={()=>setDeleteModal(false)} type="button">
      &times;
    </button>
  )
  //Alert success
  const AlertSuccess = (alerte) =>{
    if(confirmAlerte === false){
      ToastConfirm(alerte)
      setConfirmAlerte(true)
    }
  }
  //Error Alerte
  /*const AlertError = alerte =>{
    if(errorAlerte === false){
      ToastError(alerte)
      setErrorAlerte(true)
    }
  }*/
 //Delete
 
 const deleteUserAction = () =>{
  dispatch(Clear_States())
  setConfirmAlerte(false)
  //setErrorAlerte(false)
  setBtnLoad(true)
  dispatch(Delete_User_Account(DELETE_USER_ACCOUNT(deleteUser), session.token))
  setBtnLoad(false)
  setDeleteModal(false)

 }


  return (
<Fragment>
<Breadcrumb title='Bibliothèque' submenu ='Utilisateurs'/>
    <div className="content">
    <div className="container">
    <div className="row">
    <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
    {/*Sidebar */}
    <StickyBox offsetTop={20} offsetBottom={20}>
    <Sidebar/>
    </StickyBox>                
    {/*Sidebar */}
    </div>
    {/*application */}
    <div className="col-md-7 col-lg-8 col-xl-9">
    <div className="row app_items">
    
         {/*header content */}
        <div className="col-12 col-sm-4 col-md-6">
            <h4 className="htitle">LISTE DES UTILISATEURS 
            ( {count} )
             </h4>
        </div>
        <div className="col-12 col-sm-8 col-md-6 text-sm-right">
        <div className="form-group">
            <div className="form-icon right">
                <input onChange={(e)=>setSearch(e.target.value)} type="text" className="form-control form-control-icon" id="iconInput" placeholder="Recherche par E-mail"/>
                <i className="ri-search-2-line"></i>
            </div>
            </div>             
        </div>
    
        {/*fin header content */}     
        
 {/*Content Tab */}    
    <div className="row contentTab">
      <div className="col-12 col-md-12"> 
        <div className="appointment-tab">
         <div className="tab-content">
         {<ItemsPagination count={count} page={parameters.page} limite={100} onNextClick={onNextClick} text='Utilisateurs'/>}
      
            <div className="tab-pane show active" id="upcoming-appointments">
            <div className="card card-table mb-0">
            <div className="card-body"> 
            <div className="table-responsive">
              {/*Content Tab liste */}
              {!isLoading && (
                notDatas ? Alerte_Warning(user.message) : <UsersLites user={user} DeleteToggle={DeleteToggle}/>
                )
              }
              {/*Content Tab liste */}
                  
            </div>
            </div>
            </div>
            </div>
         </div>
        </div>
       </div>
      </div>
{/* Fin Content Tab */} 
   
    </div></div>
    {/*fin app */}
    {/*Content Modal Add */}
    <div>
  
      {/*Delete modal*/}
      <Modal isOpen={deleteModal} toggle={DeleteToggle} className="modal-close">
        <ModalHeader toggle={DeleteToggle} close={DeleteCloseBtn}>&nbsp; Suppression</ModalHeader>
        <ModalBody>
        <i className="ri-close-circle-line"></i> Voulez-vous vraiment supprimer cet élément ? 
        </ModalBody>
        <ModalFooter>
        <div className='modalf_width'>
          {btnLoad ? SimpleSpinner():
            <>
            <Button color="primary" onClick={(e)=>deleteUserAction()}> Confirmer la suppression</Button>
            <Button color="secondary" onClick={()=>setDeleteModal(false)}>Annuler</Button>
            </>
          }
        </div>
        </ModalFooter>
      </Modal>

      
    </div>
    {/*Fin Content Modal */}
    </div>
</div>
</div>
{/*Alertes*/}

{userAccount.delete_user && AlertSuccess('Utilisateur supprimé !')}

</Fragment>
  )
}

export default Users