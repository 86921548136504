import React, { Fragment, useEffect, useState } from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.js'
// Font Awesome
import './assets/plugins/fontawesome/css/fontawesome.min.css';
import './assets/plugins/fontawesome/css/all.min.css';
import 'remixicon/fonts/remixicon.css';
// Custom CSS
import './assets/css/style.css';
import './assets/css/fonts.css';
import './assets/css/custome.css';

//liste des pages
import { DefaultSpinner } from './components/ui/Spinner';
import useUserAuth from './components/backend/auth/RequireAuths'
import Header from './components/header/Header';
import HeaderAppHome from './components/header/HeaderAppHome';
import Footer from './components/footer/Footer';
import Accueil from './components/Accueil';
import Login from './components/accounts/Login';
import ResetPassword from './components/accounts/ResetPassword';
import CheckUserMail from './components/accounts/CheckUserMail';

//admin
import Apps from './components/backend';

import Signup from './components/accounts/Signup';
import FormSubmitResetPwd from './components/accounts/FormSubmitResetPwd';
import PrivateAdmin from './components/backend/in';
import Dashboard from './components/backend/in/dashboard';
import UserSettings from './components/backend/home/user/UserSettings';
import Collections from './components/backend/in/collections';
import Genres from './components/backend/in/genres';
import Classifications from './components/backend/in/classifications';
import Users from './components/backend/in/users';
import UpdateUserAccount from './components/backend/in/users/UpdateUserAccount';
import ListeItems from './components/backend/in/items';
import AddItemEntity from './components/backend/in/items/AddItems';
import Apropos from './components/Apropos';
import Contacts from './components/Contacts';
import UpdateItems from './components/backend/in/items/UpdateItems';
import ProfileNewPassword from './components/accounts/ProfileNewPassword';
import PlanDocs from './components/PlanDocs';
import SearchApps from './components/backend/home/search/Search';
import ItemAccess from './components/backend/home/itemAccess';

function App() {

    const [isLoading, setIsLoading] = useState(true)
    
    //phase de verification de la connexion
    const [authenticate, setAuthenticate] = useState(null)
    const isAuthenticated = useUserAuth();
    
    useEffect(()=>{
        setAuthenticate(isAuthenticated);
        if(authenticate !== null){
            setTimeout(()=>{
            setIsLoading(false)
        },[500])
            
    }
    return ()=> isAuthenticated
    
    },[isAuthenticated, authenticate])
   
  return !isLoading ? (
    
   <Fragment> 
    <div className='main-wrapper slide-nav'>
    {authenticate? <HeaderAppHome/> :  <Header/> }
    <Routes>
      <Route path='/' element={<Accueil/>} />
      <Route path='/accueil' element={<Accueil/>} />
      <Route path='/login' element={<Login/>} />
      <Route path='/signup' element={<Signup/>} />
      <Route path='/apropos' element={<Apropos/>} />
      <Route path='/plan-documentaire' element={<PlanDocs/>} />
      <Route path='/contacts' element={<Contacts/>} />
        <Route path='/reset-password' element={<ResetPassword/>}>
            <Route path='app-check-email' element={<CheckUserMail/>} />
            <Route path='user/sessionId=:sessionId/reset-pwd/:resetToken' element={<FormSubmitResetPwd/>} />
        </Route>
        <Route path='/search/entitys/:page/:collections/:type/:search_text' element={<SearchApps/>}/>
        <Route path='/biblio/entitys/r/:itemRef/:itemId/:type/:fichier' element={<ItemAccess/>}/>
        {/**contenue protege */}
        <Route path='/apps' element={<Apps/>}> 
            {/**administration */}

            <Route path='in' element={<PrivateAdmin/>}> 
                <Route path='dashboard/:sessionId' element={<Dashboard/>}/>
                <Route path='collections' element={<Collections/>}/>
                <Route path='genres' element={<Genres/>}/>
                <Route path='classifications' element={<Classifications/>}/>
                <Route path='users-accounts/page/:page' element={<Users/>}/>
                <Route path='users/:id/:sessionId/:user' element={<UpdateUserAccount/>}/>
                <Route path='bibliotheque/entity/page/:page' element={<ListeItems/>}/>
                <Route path='bibliotheque/entity/:uuid/:id' element={<UpdateItems/>}/>
                <Route path='bibliotheque/entity/add' element={<AddItemEntity/>}/>
            </Route> 

            {/**Fin pages administration */}

            <Route path='home' element={< Navigate to= '/' />}/>
            <Route path='reset/sessionId=:sessionId/reset-pwd/:resetToken' element={<ProfileNewPassword/>}/>
            <Route path='home/user' element={<UserSettings/>}/> 
            <Route path='' element={< Navigate to= '/' />}/> 
        </Route>
        {/**Fin protect */}
      <Route path='*' element={ < Navigate to= '/' />}/>
    </Routes>
    <Footer/>
    </div>

    <ToastContainer />
   </Fragment>
  )
  :(DefaultSpinner(''))
  
}

export default App;
