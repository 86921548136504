
import { LOADING } from "../StoreConstants";
//login reducer
import { SIGIN_SUCCESS, 
        SIGIN_ERROR,
        LOGOUT_USER,
        ACCOUNT_REGISTER_SUCCESS,
        ACCOUNT_IS_EXISTS,
        LOADING_SIGNUP,
        ACCOUNT_ERROR_REGISTER,
        ACCOUNT_EXPIRED_TOKEN,
        ACCOUNT_REFRESH_SESSION
    } from "./Constants";

const initialState = {
    isLoading: false,
    loginError: false,
    isAuthenticated: false,
    token: null,
    userId: null,
    email: '',
    sessionId: null,
    roles: []
}

const currentSetDatas = action=>{

    return {
            sessionId: action.payload.res.useruuid,
            email: action.payload.res.email,
            isAuthenticated: true,
            token: action.payload.token,
            userId: action.payload.res.id,
            roles: action.payload.res.roles
        }
}

export const UserAuthentication = (state = initialState, action ) =>{
    switch (action.type) {
        case LOADING:
            return {
                ...state,
                isLoading: true
            }

        case SIGIN_ERROR:
            return {
                ...state,
                loginError: true,
                isLoading: false
            }
            
        case SIGIN_SUCCESS:
            //creation de la session de User
            const current = currentSetDatas(action)
            //local session
            sessionStorage.setItem('currentUser', JSON.stringify(current));
            //persist data from user
            localStorage.setItem('UserAuth', JSON.stringify({
                token: action.payload.token,
                sessionId: action.payload.res.useruuid
            }));

            return {
                ...state,
                isLoading: false,
                ...current
            }

            case ACCOUNT_REFRESH_SESSION:

            return{
                ...state,
                sessionId: action.payload.sessionId,
                email: action.payload.email,
                isAuthenticated: true,
                token: action.payload.token,
                userId: action.payload.userId,
                roles: action.payload.roles
            }

            //expiration du jeton
            case ACCOUNT_EXPIRED_TOKEN:
               
            return {
                ...state,
                token: 'expired'
            }

            case LOGOUT_USER:
                localStorage.removeItem('UserAuth')
                sessionStorage.removeItem('currentUser')
            return {
                ...state,
                isAuthenticated: false
            }

        //Defaul value
        default:
            return state
            
    }
}

//creation de compte user

const signupState = {
    isLoadingSignup: false,
    signupError: false,
    isExists: false,
    signupSuccess: false,
    signupErrorMessage: ''
}

export const AccountRegister = (state = signupState, action) =>{
    switch (action.type) {
        case LOADING_SIGNUP:
            return {
                ...state,
                isLoadingSignup: true
            }
        case ACCOUNT_IS_EXISTS:
            return{
                ...state,
                isLoadingSignup:false,
                isExists: true,
                signupErrorMessage: action.payload
            }
        case ACCOUNT_ERROR_REGISTER:

            return{
                ...state,
                isLoadingSignup:false,
                signupError: true
            }

        case ACCOUNT_REGISTER_SUCCESS:

            return{
                ...state,
                isLoadingSignup:false,
                signupSuccess:true
            }
    
        default:
            return state;
    }
}



