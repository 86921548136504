
//custome hook global pour faire des requetes Get
import { useState, useEffect } from "react";
import axios from "axios";

const useAxiosToGet = (urlApi, token)=> {
const [fetchDatas, setFetchDatas] = useState(null)
const [isAppLoading, setIsAppLoading] = useState(true)
     
    useEffect(()=>{
     const config = {
        methods: 'GET',
        url: urlApi,
        headers:{
            'Authorization': 'Bearer '+token
        }
    }
    
    axios(config).then(res =>{ 
        setFetchDatas(res) 
        setIsAppLoading(false)})
    .catch(er =>{
        setFetchDatas(er.response)
        setIsAppLoading(false)
    })
    
    },[urlApi, token])

    return { isAppLoading, fetchDatas  };
}

export default useAxiosToGet