import {
    GET_USER_PROFILE, 
    UPDATE_USER_PROFILE, 
    NEW_USER_PWD, 
    UP_USER_LOADING, 
    UPDATE_ERROR
    } from './Constants'


export const Apps_Update_Loading = ()=>{
    return{
        type: UP_USER_LOADING
    }
}

export const Apps_User_Profile = (Profile)=>{
    return{
        type: GET_USER_PROFILE,
        payload: Profile
    }
}


export const Apps_Update_Profile = (callback)=>{
    return{
        type: UPDATE_USER_PROFILE,
        payload: callback
    }
}

export const Apps_Change_Password = ()=>{
    return{
        type: NEW_USER_PWD
    }
}

export const Apps_Update_Error = callback=>{
    return{
        type: UPDATE_ERROR,
        payload:callback
    }
}
