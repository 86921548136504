import React from 'react'

const SuccessCard = (props) => {
    const {H3, Content} = props


  return (
    <>
      
        {/* Success Card */}
        <div className="row justify-content-center">
         <div className="col-lg-6">
           <div className="card success-card">
            <div className="card-body">
            <div className="success-cont">
                <i className="fas fa-check" style={{'color': '#fff', 'background': '#20c997', 'border':'#20c997'}}/>
                <h3 style={{'color': '#20c997'}}>{H3}</h3>
                <p>
                    <strong style={{'fontSize':'1.14em'}}>
                        {Content}
                    </strong>
                </p>
                <a href ="/" className="btn btn-primary view-inv-btn">
                    RETOUR
                </a>
             </div>
            </div>
           </div>
          </div>
        </div>
        {/* /Success Card */}
    </>
  )
}

export default SuccessCard
