import {
    RESET_LOADING,
    CHECK_EMAIL_ERROR,
    CHECK_VALIDE,
    CONFIRM_RESET_FAILED,
    CONFIRM_RESET_LOADING,
    CONFIRM_RESET_SUCCESS
} from './Constantes'

const initialState = {
    isResetLoading : false,
    validUser: 0,
    reset_error: false,
    error_callback: '',
    success_callback: '',
    confirm_error: false,
    confirm_loading: false,
    confirm_success: false
}

const helperSetData = (action)=>{
    return{
        responseCheckpwdReset : {
            token: action.payload.resetToken.token,
            sessionId: action.payload.sessionid
        }
    }
}

export const ResetAccountPwd = (state = initialState, action) =>{
    switch (action.type) {
        case RESET_LOADING:
            return {
                ...state,
                isResetLoading: true
            }
        case CHECK_EMAIL_ERROR:
            return {
                ...state,
                isResetLoading: false,
                reset_error: true,
                error_callback: action.payload
            }
        case CHECK_VALIDE:
            let store = helperSetData(action)
            localStorage.setItem(
                'responseCheckpwdReset', 
                JSON.stringify(store)
            )
            return {
                ...state,
                isResetLoading: false,
                reset_error: false,
                validUser: 1
            }
        case CONFIRM_RESET_LOADING:
            return {
                ...state,
                confirm_loading: true
            }
        case CONFIRM_RESET_FAILED:
            return {
                ...state,
                confirm_loading: false,
                confirm_error: true,
                error_callback: action.payload
            }

        case CONFIRM_RESET_SUCCESS:
            localStorage.removeItem('responseCheckpwdReset')
            return{
                ...state,
                confirm_loading: false,
                confirm_error: false,
                confirm_success: true,
                success_callback: action.payload
            }
        //Defaul value
        default:
            return state
      
        }
}
