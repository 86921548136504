import React, { Fragment } from 'react'
import useAxiosToGet from '../../fetchDatasAxios/FetchGet'
import { useSelector } from 'react-redux'
import { COUNT_COLLECTIONS } from '../../auth/urlParameters'

const CountCollections = () => {
    //GET Count numerisation
    const session = useSelector(state => state.sessionAuth)
    const {isAppLoading, fetchDatas} = useAxiosToGet(COUNT_COLLECTIONS(), session.token);
    
  return (
    <Fragment>
        <div className="col-md-12 col-lg-4">
        <div className="dashbord-app dash-widget dct-border-rht">
        <div className="circle-bar">
        <i className="ri-folder-5-line"></i>
        </div>
        <div className="dash-widget-info">
        <h6>Collections</h6>
        <h3>{isAppLoading === true ? '': fetchDatas.data.countCollections}</h3>
        </div>
        </div>
        </div>
    </Fragment>
  )
}

export default React.memo(CountCollections);