
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension"

//importation de tous les reducers du apps
import RootReducers from "./StoreReducers";

const store = createStore(RootReducers, composeWithDevTools(applyMiddleware(thunk)) );

export default store;
